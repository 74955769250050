/**
 * Dependencies.
 */
import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

/**
 * Resources
 */
import './addressBlock.css';
import { currentLanguage } from '../../../../views';
import countriesEN from '../../assets/json/countriesEN.json';
import countriesFR from '../../assets/json/countriesFR.json';
import TextInput from '../textInput/textInput';
import CountrySelector from '../countriesSelector/countriesSelector';

/**
 * AddressBlockProps interface defines the structure of props
 * expected by the AddressBlock component.
 */
interface AddressBlockProps extends WithTranslation {
  divClassName: string;
  title?: string;
  initialMainAddress: string;
  initialAdditionalAddress: string;
  initialPostalCode: string;
  initialCity: string;
  initialCountry: string;
  onSaveMainAddress: (mainAddress: string) => void;
  onSaveAdditionalAddress: (additionalAddress: string) => void;
  onSavePostalCode: (postalCode: string) => void;
  onSaveCity: (city: string) => void;
  onSaveCountry: (country: string) => void;
  onlyFrance?: boolean;
}

/**
 * AddresBLockState defines all values
 * that can be constantly update by user as long as
 * he's on component parent page
 */
interface AddressBlockState {
  mainAddressValue: string;
  additionalAddressValue: string;
  postalCodeValue: string;
  cityValue: string;
  countryValue: string;
}

/**
 * AddressBlock component displays a form that gives
 * possibility to fill in a complete address
 */
class AddressBlock extends Component<AddressBlockProps, AddressBlockState> {
  private countries = [];

  /**
   * Execute some instructions onLoad of the component,
   * without waiting any update of any state
   */
  componentDidMount() {
    if (this.props.onlyFrance) {
      this.countries = [{ name: 'France', code: 'FR' }];
    } else {
      if (currentLanguage === 'fr') {
        this.countries = countriesFR.sort((a: any, b: any) => {
          return a.name.localeCompare(b.name);
        });
      } else {
        this.countries = countriesEN.sort((a: any, b: any) => {
          return a.name.localeCompare(b.name);
        });
      }
    }
  }

  constructor(props: AddressBlockProps) {
    super(props);
    this.state = {
      mainAddressValue: props.initialMainAddress,
      additionalAddressValue: props.initialAdditionalAddress,
      postalCodeValue: props.initialPostalCode,
      cityValue: props.initialCity,
      countryValue: props.initialCountry,
    };

    this.handleCountryChange = this.handleCountryChange.bind(this);
  }

  /**
   * Update value of countryValue state variable
   *
   * @param event the new countryValue
   */
  handleCountryChange(event: React.ChangeEvent<HTMLSelectElement>) {
    this.setState({ countryValue: event.target.value });
    this.props.onSaveCountry(event.target.value);
  }

  render() {
    /**
     * Extract the `t` function from `useTranslation` hook.
     */
    const { t } = this.props;

    return (
      <div className={this.props.divClassName}>
        <p>
          <b>{this.props.title}</b>
        </p>
        <div className='mainAddress'>
          <TextInput
            classname='mainAddressTextInput'
            placeholder={t('employeesPopupPageStrings.mainAddress')}
            initialValue={this.state.mainAddressValue}
            onSave={this.props.onSaveMainAddress}
          />
        </div>
        <div className='additionalAddressAndPostalCode'>
          <div className='additionalAddress'>
            <TextInput
              classname='additionalAddressTextInput'
              placeholder={t('employeesPopupPageStrings.additionalAddress')}
              initialValue={this.state.additionalAddressValue}
              onSave={this.props.onSaveAdditionalAddress}
            />
          </div>
          <div className='postalCode'>
            <TextInput
              classname='postalCodeTextInput'
              placeholder={t('employeesPopupPageStrings.postalCode')}
              initialValue={this.state.postalCodeValue}
              onSave={this.props.onSavePostalCode}
            />
          </div>
        </div>
        <div className='cityAndCountry'>
          <div className='city'>
            <TextInput
              classname='cityTextInput'
              placeholder={t('employeesPopupPageStrings.city')}
              initialValue={this.state.cityValue}
              onSave={this.props.onSaveCity}
            />
          </div>
          <CountrySelector
            divClassName='country'
            selectorClassName='countrySelector'
            selectorValue={this.state.countryValue}
            selectorOnChange={this.handleCountryChange}
            countriesList={this.countries}
          />
        </div>
      </div>
    );
  }
}

export default withTranslation()(AddressBlock);
