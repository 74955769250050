/**
 * Depenencies
 */
import React, { Component, ReactNode } from 'react';
import { Helmet } from 'react-helmet';
import { withTranslation, WithTranslation } from 'react-i18next';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { selectors } from 'store';

/**
 * Resources
 */
import '../index.css';
import Header from '../components/header/header';
import Footer from '../components/footer/footer';
import Content from '../components/content/content';
import InvoicesList from '../components/invoicesList/invoicesList';

/**
 * InvoicesPageProps interface defines the structure of props
 * expected by the InvoicePage component.
 */
interface InvoicesPageProps extends WithTranslation {
  user: any;
}

/**
 * InvoicesPage component displays a styled list of all
 * invoices related to a company.
 */
class InvoicesPage extends Component<InvoicesPageProps> {
  render(): ReactNode {
    /**
     * Extract the `t` function from `useTranslation` hook.
     */
    const { t, user } = this.props;

    const adminOrOperator: boolean = user.operator || user.admin;

    return (
      <div className='pages'>
        <Helmet>
          <title>
            {t('headerStrings.companyButtonStrings.invoices')}
            {t('helmetString')}
          </title>
        </Helmet>
        <Header />
        <Content
          title={t('headerStrings.companyButtonStrings.invoices')}
          homepage={false}
          history={
            adminOrOperator
              ? window.location.href.split('/').slice(0, -3).join('/') +
                '/companies'
              : window.location.href.split('/').slice(0, -3).join('/')
          }
        />
        <InvoicesList viewAll={true} />
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: selectors.auth.userSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(withTranslation()(InvoicesPage));
