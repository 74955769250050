/**
 * Dependencies.
 */
import axios from 'axios';

/**
 * Downloads all files from a list of Minio S3 buckets and
 * re assemble them into a zip file.
 *
 * This function sens a POST request to the specified API endpoint to initiate
 * the download of some files located in S3. It receives files paths list as a
 * parameter, put all files into a zip file, processes the response to create a
 * downloadable URL, and opens that URL in a new tab.
 *
 * @param pathsList Paths of all files to download from S3. This should
 * be the full path to the file in the S3 bucket.
 *
 * @throws Throws an error if the zip file download fails, which will be
 * logged to the console.
 *
 * @returns A promise that resolves when the download has
 * been initiated, or rejects if an error occurs.
 */
export async function downloadZipFromS3(pathsList: string[]): Promise<void> {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_DEV_API_URL}/api/v1/spw/downloadZipFromS3`,

      { filePaths: pathsList },
      { responseType: 'blob' }
    );

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'invoices.zip');
    document.body.appendChild(link);
    link.click();
    link.remove();
  } catch (error) {
    console.error('Error during zip download', error);
  }
}
