/**
 * Dependencies.
 */
import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

/**
 * Resources.
 */
import './dashboard.css';

/**
 * DashboardInformationProps interface defines the structure of props
 * expected by the DashboardInformation component.
 */
interface DashboardInformationProps extends WithTranslation {
  title: string;
  value: string;
  informationAction: () => void;
}

/**
 * DashboardInformation component displays a large div
 * with the number of related to its category.
 * This div is clickable and opens a mission list with a
 * filter defines by its category.
 */
class DashboardInformation extends Component<DashboardInformationProps> {
  render() {
    const { title, value, informationAction } = this.props;

    /**
     * Extract the `t` function from `useTranslation` hook.
     */
    const { t } = this.props;

    return (
      <div
        className='dashboardContainerInformation'
        onClick={informationAction}
      >
        <h1>{value}</h1>
        <button className='informationButton' onClick={informationAction}>
          {t(title)}
        </button>
      </div>
    );
  }
}

export default withTranslation()(DashboardInformation);
