import actionTypes from './actionTypes';
import { dataReqI } from 'interfaces';

export default {
  getAllTransporter() {
    return {
      type: actionTypes.GET_ALL_TRANSPORTER,
    };
  },
  hasSubscribedTo(data: dataReqI) {
    return {
      type: actionTypes.HAS_SUBSCRIBED_TO,
      payload: data,
    };
  },
  getAll() {
    return {
      type: actionTypes.GET_ALL,
    };
  },
  search(data: dataReqI) {
    return {
      type: actionTypes.SEARCH,
      payload: data,
    };
  },
  getPermissions(data: dataReqI) {
    return {
      type: actionTypes.GET_PERMISSIONS,
      payload: data,
    };
  },
  getOne(data: dataReqI) {
    return {
      type: actionTypes.GET_ONE,
      payload: data,
    };
  },
  create(data: dataReqI) {
    return {
      type: actionTypes.CREATE,
      payload: data,
    };
  },
  update(data: dataReqI) {
    return {
      type: actionTypes.UPDATE,
      payload: data,
    };
  },
  remove(data: dataReqI) {
    return {
      type: actionTypes.REMOVE,
      payload: data,
    };
  },

  reset(keys) {
    return {
      type: actionTypes.RESET,
      payload: {
        keys,
      },
    };
  },
  refreshError(keys) {
    return {
      type: actionTypes.REFRESH_ERROR,
      payload: {
        keys,
      },
    };
  },
};
