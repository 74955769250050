import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Divider, Tab, Loader, Dimmer, Grid, Message } from 'semantic-ui-react';
import { actions, selectors } from 'store';
import ButtonBack from 'components/ButtonBack/ButtonBack';
import SubscribedServices from './SubscribedServices';
import Invoice from './Invoices';
import General from './General';
import PaymentMethod from './PaymentMethod';
import Vats from './Vats';
import Users from './Users';
import Contacts from './Contacts';
import Segment from 'components/core/Segment/Segment';
import Wrapper from 'components/core/Wrapper/Wrapper';
import Drivers from './Drivers';
import Pricing from './Pricing';
import KeyInfos from './KeyInfo';
import TransitConfiguration from './TransitConfiguration';
import IntrastatSettings from './IntrastatSettings';
import VatCollectionBoxSettings from './VatCollectionboxSettings';
import TaxflowSettings from './TaxflowSettings';

const Index = ({ dispatch, match, company, history, user }) => {
  const isInternal = user.admin || user.operator || user.superOperator;
  const [subscribedSettings, setSubscribedSettings]: any = useState(null)

  useEffect(() => {
    dispatch(
      actions.company.getOne({
        params: { companyId: match.params.companyId },
      })
    );
    dispatch(actions.socket.subscribeUpdateOneCompany(match.params.companyId));
    return () => {
      dispatch(
        actions.socket.unsubscribeUpdateOneCompany(match.params.companyId)
      );
      dispatch(actions.company.reset(['company', 'error']));
    };
  }, [dispatch, match.params.companyId]);

  useEffect(() => {
    if(company && company.Subscriptions.length){
      let tab = {}
      company.Subscriptions.forEach((e) => {
        Object.assign(
          tab, {[e.Service.name.toLowerCase()]: true}
        )
      })
      console.log(tab)
      setSubscribedSettings(tab)
    }
  }, [company])

  return (
    <>
      {(company === null && (
        <Dimmer active>
          <Loader content='Loading' />
        </Dimmer>
      )) || (   
        <Wrapper height='100%' width='75%' overflow='auto' margin='auto'>   
            <Divider hidden />
            <Wrapper display='flex'>
              <ButtonBack history={history} />
            </Wrapper>
            <Divider hidden />
            {company !== null && (
              <>
                <Segment margin='1rem 0 2rem 0'>
                  <Grid columns={3} divided padded>
                    <Grid.Column>
                      <div>
                        <b>Name: </b>
                        {company.name}
                      </div>
                      <div>
                        <b>Unique number: </b>
                        {company.uniqueNumber}
                      </div>
                      <div>
                        <b>Country: </b>
                        {company.country}
                      </div>
                      <div>
                        <b>Email: </b>
                        {company.email}
                      </div>
                      <div>
                        <b>Phone number: </b>
                        {company.phoneNumber}
                      </div>
                    </Grid.Column>
                    <Grid.Column>
                      <div>
                        <b>Address: </b>
                        {company.Addresses.length > 0 &&
                          company.Addresses[0].address1}
                      </div>
                      <div>
                        <b>City: </b>
                        {company.Addresses.length > 0 &&
                          company.Addresses[0].city}
                      </div>
                      <div>
                        <b>Zip code: </b>
                        {company.Addresses.length > 0 &&
                          company.Addresses[0].zipCode}
                      </div>
                      <div>
                        <b>Country: </b>
                        {company.Addresses.length > 0 &&
                          company.Addresses[0].country}
                      </div>
                    </Grid.Column>
                    {company.holdingAgency && (
                      <Grid.Column>
                        <>
                          <div>
                            <b>ASD Agency: {company.holdingAgency.name}</b>
                          </div>
                          <div>
                            <b>Phone number: {company.holdingAgency.phoneNumber}</b>
                          </div>
                        </>
                      </Grid.Column>
                    )}
                    <Grid.Column>
                      <Message info>
                        <Message.Header>Partners rights</Message.Header>
                        <p>The list of documents in "Customs Declaration" has evolved: you have new lines to tick</p>
                      </Message>
                    </Grid.Column>
                  </Grid>
                </Segment>
                <Tab
                  panes={[
                    {
                      menuItem: 'Subscribed services',
                      render: () => (
                        <SubscribedServices
                          user={user}
                          company={company}
                          subscribedServices={company.Subscriptions}
                          history={history}
                        />
                      ),
                    },
                    {
                      menuItem: 'Registration numbers',
                      render: () => (<Vats company={company} user={user} />),
                    },
                    {
                      menuItem: 'Users',
                      render: () => (<Users company={company} user={user} />),
                    },
                    {
                      menuItem: 'Contacts',
                      render: () => (<Contacts company={company} user={user} />),
                    },
                    {
                      menuItem: 'Drivers',
                      render: () => (<Drivers company={company} user={user} />),
                    },
                    {
                      menuItem: 'General Settings',
                      render: () => (
                        <General companyData={company} user={user} />
                      ),
                    },
                    {
                      menuItem: 'Payment method',
                      render: () => (
                        <PaymentMethod company={company} user={user} />
                      ),
                    },
                    {
                      menuItem: 'Invoices',
                      render: () => <Invoice company={company} />,
                    },
                    ...(isInternal ? [
                      {
                        menuItem: 'Pricing',
                        render: () => <Pricing company={company} />,
                      },
                      {
                        menuItem: 'KeyInfos',
                        render: () => <KeyInfos company={company} user={user} />,
                      },
                      {
                        menuItem: 'Transit',
                        render: () => <TransitConfiguration company={company} user={user} />,
                      },
                  ] : []),               
                    ...(subscribedSettings ? [
                      subscribedSettings.taxflow &&
                      {
                        menuItem: 'Taxflow',
                        render: () => <TaxflowSettings company={company} user={user} subscribedSettings={subscribedSettings} />,
                      },
                      subscribedSettings.intrastat &&
                      {
                        menuItem: 'Intrastat',
                        render: () => <IntrastatSettings company={company} user={user} subscribedSettings={subscribedSettings} />,
                      },
                      subscribedSettings.collectionbox &&
                      {
                        menuItem: 'CollectionBox',
                        render: () => <VatCollectionBoxSettings company={company} user={user} subscribedSettings={subscribedSettings} />,
                      },
                    ] : []),                  
                  ]}
                />
              </>
            )}
        </Wrapper>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    company: selectors.company.companySelector(state),
    loadingHandlerCompany: selectors.company.loadingSelector(state),
    userPermissions: selectors.companyUser.userPermissionsSelector(state),
    message: selectors.message.messageSelector(state),
    error: selectors.message.errorSelector(state),
    success: selectors.message.successSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
