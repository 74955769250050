import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { actions, selectors } from 'store';

import {
  Divider,
  Grid,
  Header,
  Message,
  Segment,
  Table,
  Icon,
} from 'semantic-ui-react';
import Button from 'components/Button';
import Wrapper from 'components/core/Wrapper/Wrapper';
import Loader from 'components/Loader';
import EmptyTable from 'components/EmptyTable';
import LinkedButton from 'components/core/LinkedButton/LinkedButton';
import ModalAddIntranetReference from '../components/ModalAddIntranetReference/ModalAddIntranetReference';
import ModalEditCollectionbox from '../components/ModalEditCollectionbox/ModalEditCollectionbox';

import { longDate, shortDate } from 'services/helper/dateFormater';
import { ResponsiveWrapper } from './styles';
import { paths as pathToCompany } from 'routes/companies';
import { Link, useLocation} from 'react-router-dom';
import { FolderLink, FolderWrapper, Linked } from './styles';
import CollectionboxChat from '../components/CollectionboxChat/CollectionboxChat';
import ModalCancelCollectionbox from '../components/ModalCancelCollectionbox/ModalCancelCollectionbox';
import CollectionboxProgressBar from '../components/CollectionboxProgressBar/CollectionboxProgressBar';
import Documents from '../components/CollectionboxDocuments/Documents';
import ModalAddCollectionboxDocuments from '../components/ModalAddCollectionboxDocuments/ModalAddCollectionboxDocuments';
import ModalTagManager from '../components/ModalTagManager/ModalTagManager';
import ModalFilterTagManager from '../components/ModalFilterTagManager/ModalFilterTagManager';
import ModalSplitSingleFile from '../components/ModalSplitSingleFile/ModalSplitSingleFile';
import ModalCloseCollectionbox from '../components/ModalCloseCollectionbox/ModalCloseCollectionbox';


const Index = ({
  dispatch,
  match,
  history,
  collectionbox,
  collectionboxLoading,
  chat,
  chatLoading,
  service,
  readChatLoading,
  companySelected,
  user,
  deleteDocumentLoading,
  addDocumentsLoading,
  downloadLoading,
}) => {
  const collectionboxId = match.params.collectionboxId
  const userAdminOrOp = user.operator || user.admin;
  const location = useLocation();
  const isMontoring = (location.state && location.state.locPath.includes('monitoring')) || null
  const iso = match.params.iso
  const companyId = Number((userAdminOrOp || user.partnership) ? match.params.companyId : companySelected);
  const isOwner =  parseInt(match.params.companyId) === parseInt(companySelected);

  const [modalAddDocumentsStatus, setmodalAddDocumentsStatus] = useState({open: false, type: null});
  const [modalCancelCollectionbox, setmodalCancelCollectionbox] = useState(false);
  const [modalCloseCollectionbox, setmodalCloseCollectionbox] = useState(false);
  const [modalEditCollectionbox, setmodalEditCollectionbox] = useState(false);
  const [acceptedFiles, setAcceptedFiles]: any = useState(null);
  const [modalAddIntranetReference, setmodalAddIntranetReference] = useState(false);
  const [tagManagerModal, setTagManagerModal]: any = useState(null)
  const [tagFilterManagerModal, setTagFilterManagerModal]: any = useState(null)
  const [splitFileModal, setSplitFileModal]: any = useState(null)
  const [isFollowing, setIsFollowing] = useState(false);
  const [data, setData]: any = useState({type: null, data: []})
  const [docOpe, setOperators]: any = useState([]);
  const [permissions, setGetPermissions]: any = useState(null)


  useEffect(() => {

    dispatch(
      actions.collectionbox.getOneCollectionbox({
        params: { 
          companyId: companyId, 
          collectionboxId: collectionboxId
        },
      })
    );

    // pris en charge par readChat <= emit GET_CHAT
    // dispatch(
    //   actions.chat.getAllChats({
    //     params: { 
    //       companyId: companyId,  
    //       id: collectionboxId 
    //     },
    //   })
    // );

    dispatch(
      actions.collectionbox.readChat({
        params: { 
          companyId: companyId, 
          collectionboxId: collectionboxId 
        },
      })
    );

    dispatch(
      actions.socket.subscribeUpdateChat(
        companyId,
        collectionboxId
      )
     );

    dispatch(
      actions.socket.subscribeOneCollectionbox(
        companyId,
        collectionboxId
      )
    );
    
    return () => {
      dispatch(
        actions.socket.unsubscribeOneCollectionbox(
          companyId,
          collectionboxId
        )
      );
      dispatch(
        actions.socket.unsubscribeUpdateChat(
          companyId,
          collectionboxId
        )
      );
      dispatch(
        actions.collectionbox.reset([
          'oneCollectionbox',
          'readChat',
          'error',
        ])
      );
      dispatch(
        actions.chat.reset([
          'allMsgs',
          'error',
        ])
      );
    }
  }, [dispatch, companyId, collectionboxId]);


  useEffect(() => {
    if(collectionbox && user){
      console.log(collectionbox)
      const is = collectionbox.follower && collectionbox.follower.find((e) => e.id === user.id)
      is ? setIsFollowing(true) : setIsFollowing(false)

      if(user.companiesUserPerms){
          if(user.companiesUserPerms[companyId]){
            setGetPermissions(user.companiesUserPerms[companyId].find((e) => e.Permission_Id === 62) ? true : false)
          }
      }

      if(collectionbox.FiscalCollectionBoxDocuments){
        // tmp définir les documents opérateur
        setOperators(collectionbox.FiscalCollectionBoxDocuments.filter((e) => e.type === 10))
      }
    }

  }, [collectionbox, user, companyId])


  const follow = (action: boolean) => {
    dispatch(
      actions.collectionbox.followCollectionbox({
        params: {
          collectionboxId,
          companyId,
        },
        data: {
          action: action
        }
      })
    );
  };

  const updateStep = (step: number) => {
    dispatch(
      actions.collectionbox.updateStep({
        params: {
          collectionboxId,
          companyId,
        },
        data: { step },
      })
    );
  };


  const cancelCollectionbox = (values: { reason: string }) => {
    dispatch(
      actions.collectionbox.updateCollectionbox({
        params: {
          collectionboxId,
          companyId,
        },
        data: { 
          reason: values.reason,
        },
      })
    );
    setmodalCancelCollectionbox(false);
  };


  const handleEditCollectionbox = (values: {
    taxPeriod: string;
    type: number;
    country: string;
    clientReference: string;
  }) => {
  dispatch(
    actions.collectionbox.updateCollectionbox({
      params: {
        collectionboxId,
        companyId,
      },
      data: {
        taxPeriod: values.taxPeriod,
        type: values.type,
        country: values.country, 
        clientReference: values.clientReference,
      },
    }))
   setmodalEditCollectionbox(false);
  }

  
  const addIntranetReference = (intranetReference: string ) => {
    dispatch(actions.collectionbox.updateCollectionbox({
      params: {
        collectionboxId,
        companyId,
      },
      data: {
       intranetReference: intranetReference
      },
    }))
    setmodalAddIntranetReference(false);
  };


  const handleShowDocument = (documentId: number) => {
    dispatch(
      actions.collectionbox.downloadDocument({
        params: {
          collectionboxId,
          companyId,
          documentId
        },
      })
    );
  };

  const handleDeleteDocument = (documentId: number) => {
    dispatch(
      actions.collectionbox.deleteDocument({
        params: {
          collectionboxId,
          companyId,
          documentId
        },
      })
    );
  };

  const handleCheckDocument = (documentId: number, status: string) => {
    dispatch(
      actions.collectionbox.updateDocument({
        params: {
          companyId: companyId,
          collectionboxId: collectionboxId,
          documentId: documentId,
          validation: status
        },
      })
    );
  }

  const getAllFiles = (acceptedFiles: any[], index: number) => {
    let obj: any = []
    obj.push({id: index, docs: acceptedFiles})
    setAcceptedFiles(obj)
  }

  const handleAddDocuments = (values: {
    type: number;
    documents: any;
    documentCategories: Array<{
      id: number;
      number: string | null;
    }>;
    split: boolean;
  }) => {
    values.documents.forEach((e, i) => {
      values.documents[i].files = acceptedFiles[i].docs
    })

    dispatch(
      actions.collectionbox.addDocuments({
        params: {
          collectionboxId,
          companyId,
        },
        data: {
          type: modalAddDocumentsStatus.type,
          documents: values.documents,
          documentCategories: values.documentCategories,
          split: values.split || ''
        },
      })
    );

    setmodalAddDocumentsStatus(prevState => ({
      ...prevState,
      open: false,
      type: undefined
    }));

    return () => {
      dispatch(
        actions.collectionbox.reset([
          'docs',
          'error',
        ])
      );
    }
  };

  const handleSubmitMessage = (values: { userId: number; message: string }) => {
    dispatch(
      actions.chat.postChats({
        params: {
          id: collectionboxId,
          companyId,
        },
        data: {
          userId: user.id,
          message: values.message
        }
      })
    );
  };


  const closeCollectionbox = (response: boolean, collectionboxId: string) => {
    if(response){
      dispatch(
      actions.collectionbox.updateStep({
        params: {
          collectionboxId: collectionboxId,
          companyId: companyId,
        },
        data: { step: 4 },
      }))

        setmodalCloseCollectionbox(false)
    }else{
        setmodalCloseCollectionbox(false)
    }
  }

  const handleTagOperations = (values: {
    documentId: number, 
    tag: string,
    session: string,
    docName: string,
    docCategory: number,
    flowCat: number
  }) => {
    tagManagerModal && 
    dispatch(actions.collectionbox.handleTagOperations({
      params: {
        companyId: companyId,
        collectionboxId: collectionboxId,
        documentId: values.documentId 
      },
      data: {
        tag: values.tag,
        operation: 'move-to',
        session: tagManagerModal.session,
        docName: tagManagerModal.docName,
        docCategory: tagManagerModal.docCategory,
        flowCat: tagManagerModal.flowCat,
        period: collectionbox && collectionbox.periodString,      
      }
    }))
    setTagManagerModal(prevState => ({
      ...prevState,
      open: false,
      documentId: null,
      tag: null,
      docName: null,
      docCategory: null,
      flowCat: null,
      periodString: null
    }))
  }


  return (
    <Wrapper display='flex' justifyContent='center'>
      {tagFilterManagerModal && collectionbox && (
        <ModalFilterTagManager
          companyId={companyId}
          collectionboxId={collectionbox && collectionbox.id}
          declarationType={collectionbox.DeclarationHasCategory.FiscalCollectionBoxCategory.name}
          data={tagFilterManagerModal}
          handleTagOperations={handleTagOperations}
          handleClose={() => setTagFilterManagerModal(null)}
          dispatch={dispatch}
          userAdminOrOp={userAdminOrOp || isOwner}
      />   
      )} 
      {tagManagerModal && (
      <ModalTagManager
        companyId={companyId}
        open={tagManagerModal.open}
        data={tagManagerModal}
        handleTagOperations={handleTagOperations}
        handleClose={() => setTagManagerModal(null)}
        dispatch={dispatch}
      />   
      )}
      {splitFileModal && collectionbox && (
      <ModalSplitSingleFile
        companyId={companyId}
        collectionboxId={collectionboxId}
        CollectionboxFlow={collectionbox && collectionbox.flow}
        open={splitFileModal.open}
        data={splitFileModal}
        handleTagOperations={handleTagOperations}
        handleClose={() => setSplitFileModal(null)}
        dispatch={dispatch}
        userAdminOrOp={userAdminOrOp}
      />   
      )}
      {modalAddDocumentsStatus.open && (
        <ModalAddCollectionboxDocuments
          type={modalAddDocumentsStatus.type}
          open={modalAddDocumentsStatus.open}
          declarationType={collectionbox.FiscalCollectionBoxCategory_Id}
          handleClose={() => setmodalAddDocumentsStatus(prevState => ({
            ...prevState,
            open: false,
            type: undefined
          }))}
          handleAddDocuments={handleAddDocuments}
          dispatch={dispatch}
          getAllFiles={getAllFiles}
        />
      )}
      {modalCancelCollectionbox && (
        <ModalCancelCollectionbox
          open={modalCancelCollectionbox}
          handleClose={() => setmodalCancelCollectionbox(false)}
          handleCancelCollectionbox={cancelCollectionbox}
        />
      )}
      {modalCloseCollectionbox && (
        <ModalCloseCollectionbox
          collectionboxId={collectionboxId}
          declarationType={collectionbox.declarationType}
          open={modalCloseCollectionbox}
          handleClose={() => setmodalCloseCollectionbox(false)}
          handleCloseCollectionbox={closeCollectionbox}
        />
      )}
      {modalAddIntranetReference && (
        <ModalAddIntranetReference
          open={modalAddIntranetReference}
          handleClose={() => setmodalAddIntranetReference(false)}
          handleAddIntranetReference={addIntranetReference}
        />
      )}
           
      {modalEditCollectionbox && (
        <ModalEditCollectionbox
          open={modalEditCollectionbox}
          handleClose={() => setmodalEditCollectionbox(false)}
          handleEditCollectionbox={handleEditCollectionbox}
          dispatch={dispatch}
          companyId={collectionbox.Company_Id}
          actualData={{
            type: collectionbox.DeclarationHasCategory.FiscalCollectionBoxCategory.id || '',
            country: collectionbox.country || '',
            taxPeriod: collectionbox.taxPeriod || '',
            clientReference: collectionbox.clientReference || '', 
          }}
        />
      )}

      {(
        collectionboxLoading || 
        collectionbox === null ||
        service ||
        readChatLoading||  
        deleteDocumentLoading ||
        downloadLoading ||
        addDocumentsLoading
        ) && (<Loader />)
      } 
        <Wrapper height='100%' width='80%'>
          <Divider hidden />
          <Wrapper display='flex'>
          {userAdminOrOp && !isMontoring && !iso && (
            <LinkedButton 
              labelPosition='left'
              icon='angle left'
              size='medium'
              type='button'
              content='Back'
              path={`/fiscal/collectionbox/${companyId}`}
            />
          )}
          {!userAdminOrOp && !isMontoring && (
            <LinkedButton 
              labelPosition='left'
              icon='angle left'
              size='medium'
              type='button'
              content='Back'
              path={`/fiscal/collectionbox/${companyId}`}
            />)}  
          {isMontoring && !userAdminOrOp && (
              <LinkedButton 
              labelPosition='left'
              icon='angle left'
              size='medium'
              type='button'
              content='Back'
              path={`/fiscal/collectionbox-monitoring`}
            />
          )} 
          {userAdminOrOp && iso && (
            <LinkedButton 
              labelPosition='left'
              icon='angle left'
              size='medium'
              type='button'
              content='Back'
              path={`/fiscal/collectionbox/country/${iso}/${companyId}`}
            />
          )}
          {isMontoring && userAdminOrOp && (
              <LinkedButton 
              labelPosition='left'
              icon='angle left'
              size='medium'
              type='button'
              content='Back'
              path={`/fiscal/collectionbox-monitoring`}
            />
          )}
         
          </Wrapper>
            <Divider hidden />       
            <Wrapper
              display='flex'
              justifyContent='center'
              height='100%'
              width='100%'
            >
            <Wrapper width='80%'>
              {collectionbox && collectionbox.DeclarationHasCategory && (
                <Wrapper>
                  <p>{collectionbox.DeclarationHasCategory.FiscalCollectionBoxCategory.name} progress:</p>                 
                  <CollectionboxProgressBar
                    loading={collectionboxLoading}
                    maxStep={4}
                    actualStep={collectionbox.DeclarationHasCategory.step}
                    onClick={
                      !collectionboxLoading &&
                      userAdminOrOp &&
                      ((step) => {
                        step !==
                        collectionbox.DeclarationHasCategory.step &&
                          updateStep(step);
                      })
                    }
                  />
                </Wrapper>
              )}
             {(<>
              {collectionbox !== null && (
                 <>             
                <Segment>
                  <Grid columns={1} divided padded>
                    <Grid.Column>
                      {/* <Header>{collectionbox.declarationType.replace('_', ' ')}</Header> */}
                    </Grid.Column>
                  </Grid>
                 
                  <Grid columns={2} divided padded>
                    <Grid.Column>
                      <FolderWrapper className='folder_wrapper'>
                        <b>Company: </b>
                        <FolderLink>
                          <Link to={pathToCompany.companyDetails.replace(':companyId', companyId.toString())} className='folder_link_link'>{collectionbox.Company.name}</Link>
                        </FolderLink>
                      </FolderWrapper>
                      <div><b>Tax period: </b>{shortDate(new Date(collectionbox.taxPeriod))}</div>
                      <div><b>Filing country: </b>{collectionbox.country}</div> 
                      <div><b>Client: </b>{collectionbox.Contact_Name}</div>  
                       
                    </Grid.Column>
                    <Grid.Column>
                      <div>
                        <b>Id: </b>
                        {collectionbox.id}
                      </div>
                      <div>
                        <b>Created at: </b>
                        {longDate(new Date(collectionbox.createdAt))}
                      </div>
                      <div>
                        <b>Emitter: </b>
                        {collectionbox.emitter.fullname}
                      </div>
                      <div>
                        <b>Followers: </b>
                        {(collectionbox.follower && 
                            collectionbox.follower.length>0 &&  
                            collectionbox.follower.map((user, i) => (
                                  <li
                                    key={i}
                                  >{`${user.operator}`}</li>
                                ))
                          ) || 'none'}
                      </div>
                      {collectionbox.clientReference && (
                        <div>
                          <b>Client reference: </b>
                          {collectionbox.clientReference === '' ? '' : collectionbox.clientReference}
                        </div>
                      )}                   
                      {userAdminOrOp && (                                         
                        <FolderWrapper>
                          <b>Intranet reference: </b>
                          {collectionbox.intranetReference && (
                          <FolderLink>
                            <Linked href={`${process.env.REACT_APP_INTRANET_DOUANE_URL}${collectionbox.intranetReference}`} className='folder_link_link'>
                              {collectionbox.intranetReference}</Linked>  
                              <Icon
                                className='customs-link-folder'
                                name='folder open'
                                size='small'
                                color='black'                    
                              />                             
                          </FolderLink> 
                          )}                                          
                        </FolderWrapper>                                                                               
                      )}
                    </Grid.Column>
                  </Grid>
                  <Divider hidden />
                  {
                    (
                      collectionbox.state !== 'completed' &&
                      collectionbox.state !== 'cancelled'
                      ) && (
                      <Button
                        icon='edit'
                        type='button'
                        size='small'
                        color='blue'
                        content='Edit data'
                        margin='10px 0 0 0'
                        onClick={() => setmodalEditCollectionbox(true)}
                      />
                    )}
                  </Segment>
              
               
                <Divider hidden />
                {!collectionbox.errorMessage && (
                  <Grid centered>              
                    {(userAdminOrOp && collectionbox.state !== 'cancelled') && (
                      <Button
                        icon='plus'
                        type='button'
                        size='large'
                        color='blue'
                        content='add intranet reference'
                        margin='5px'
                        onClick={() => setmodalAddIntranetReference(true)}
                      />
                    )}                   
                    {userAdminOrOp && 
                    collectionbox.state !== 'cancelled' && (
                      <Button
                        icon='user md'
                        type='button'
                        size='large'
                        color='blue'
                        content={isFollowing ? 'Unfollow' : 'HandleFollow'}
                        margin='5px'
                        onClick={() => {
                          isFollowing ? follow(false) : follow(true)
                        }}
                      />
                    )}
                    {userAdminOrOp &&
                    collectionbox.state !== 'cancelled' &&
                    collectionbox.state !== 'completed' && (
                      <Button
                        icon='cancel'
                        type='button'
                        size='large'
                        color='red'
                        content={`Cancel repository`}
                        margin='5px'
                        onClick={() => setmodalCancelCollectionbox(true)}
                      />
                    )}
                    {userAdminOrOp && 
                    (collectionbox.state !== 'completed' && collectionbox.state !== 'cancelled') &&              
                    (<Button
                        type='button'
                        color='blue'
                        size='large'
                        margin='5px'
                        content={`Close collection box`}
                        onClick={
                          () => setmodalCloseCollectionbox(true)
                        }
                      />)}
                </Grid>
                )}
                {!collectionbox.errorMessage && (
                  <>
                    <Divider hidden />
                    <Divider />                               
                    <ResponsiveWrapper>               
                        <Wrapper margin='5px' width='100%'>                    
                         <Wrapper display='flex' justifyContent='space-between'>
                           <Header>Documents</Header>
                           {collectionbox.state !== 'cancelled' && (
                             <Button
                               icon='plus'
                               type='button'
                               color='blue'
                               disabled={collectionbox.state === 'completed'}
                               content='Add documents'
                               onClick={() => setmodalAddDocumentsStatus(prevState => ({
                                ...prevState,
                                open: true,
                                type: collectionbox.DeclarationHasCategory.DeclarationCategory_Id
                              }))}
                             />
                           )}
                         </Wrapper>                       
                         <Table striped columns='4' selectable>
                           <Table.Header>
                             <Table.Row>
                               <Table.HeaderCell>File name</Table.HeaderCell>
                               <Table.HeaderCell>Category</Table.HeaderCell>
                               <Table.HeaderCell>Date</Table.HeaderCell>
                               <Table.HeaderCell>Actions</Table.HeaderCell>
                             </Table.Row>
                           </Table.Header>
                           <Table.Body>
                           {
                           (
                            collectionbox.FiscalCollectionBoxDocuments && 
                            collectionbox.FiscalCollectionBoxDocuments.length === 0 && 
                            <EmptyTable colNumber={4} />
                            ) || collectionbox.FiscalCollectionBoxDocuments.map(
                              (document, index) => 
                              (<Documents
                                key={`e-${index}`}
                                userAdminOrOp={userAdminOrOp}
                                user={user}
                                isOwner={isOwner}                       
                                document={document}
                                declarationState={collectionbox.state === 'processing'}
                                index={index}
                                handleShowDocument={handleShowDocument}
                                handleDeleteDocument={handleDeleteDocument} 
                                openTagManager={() => {                                                     
                                 if(document.split && (userAdminOrOp || isOwner)){
                                    setSplitFileModal(prevState => ({
                                      ...prevState,
                                      open: true,
                                      type: collectionbox.DeclarationHasCategory.FiscalCollectionBoxCategory.type,
                                      session: document.session,
                                      documentId: document.id,
                                      docName: document.name,
                                      periodString: collectionbox.taxPeriod,
                                      docCategory: document.FiscalCollectionBoxDocumentCategory_Id,
                                      collectionboxId: document.FiscalCollectionBox_Id || null,
                                      collectionFlow: collectionbox.DeclarationHasCategory.FiscalCollectionBoxCategory.name
                                    }))                                  
                                  }else{
                                    setTagFilterManagerModal(prevState => ({
                                      ...prevState,
                                      open: true, 
                                      document: document,
                                      periodString: collectionbox.taxPeriod,
                                      companyName: collectionbox.Company.name || null,
                                      collectionboxId:  collectionbox.id || null,
                                    }))
                                  }          
                                }}
                              />
                              ))}
                           </Table.Body>
                         </Table>
                        </Wrapper>    
                  
                  
                      {docOpe && userAdminOrOp && (
                         <Wrapper margin='5px' width='100%'>                    
                         <Wrapper display='flex' justifyContent='space-between'>
                         <Header>Operator</Header>
                         {collectionbox.state !== 'cancelled' && (
                           <Button
                             icon='plus'
                             type='button'
                             color='blue'
                             disabled={collectionbox.state === 'completed'}
                             content='Add documents'
                             onClick={() =>  setmodalAddDocumentsStatus(prevState => ({
                               ...prevState,
                               open: true,
                               type: 2
                             }))}
                           />
                         )}
                       </Wrapper>                       
                       <Table striped columns='4' selectable>
                         <Table.Header>
                           <Table.Row>
                             <Table.HeaderCell>File name</Table.HeaderCell>
                             <Table.HeaderCell>Category</Table.HeaderCell>
                             <Table.HeaderCell>Date</Table.HeaderCell>
                             <Table.HeaderCell>Actions</Table.HeaderCell>
                           </Table.Row>
                         </Table.Header>
                         <Table.Body>
                         {(docOpe.length===0 && <EmptyTable colNumber={4} />) || 
                          docOpe.map((document, index) => 
                              (                                                      
                               <Documents
                                key={`o-${index}`}
                                userAdminOrOp={userAdminOrOp}
                                isOwner={isOwner}
                                user={user}                       
                                document={document}
                                declarationState={collectionbox.state !== 'completed'}
                                index={index}
                                handleShowDocument={handleShowDocument}
                                handleDeleteDocument={handleDeleteDocument} 
                                openTagManager={() => {                                                     
                                  if((document.status === 'archive' && userAdminOrOp && document.split === null) || (document.split === null && isOwner)){
                                    setTagFilterManagerModal(prevState => ({
                                      ...prevState,
                                      open: true, 
                                      document: document,
                                      flowCat: 0,
                                      periodString: collectionbox.periodString,
                                      companyName: collectionbox.Company.name || null,
                                      collectionboxId:  collectionbox.id || null,
                                    }))
                                  }}}
                                />
                              ))}
                         </Table.Body>
                       </Table>
                     </Wrapper>                  
                      )}                 
                    </ResponsiveWrapper>                          
                    </>
                    )}
                    {collectionbox.errorMessage && (
                      <Message error>
                        <Message.Header>Error</Message.Header>
                        <Divider hidden />
                        {collectionbox.errorMessage}
                      </Message>
                    )}
                </>
              )}
            </>
            )}
            </Wrapper>
            <Wrapper 
              width='40%' 
              minHeight='767px' 
              padding='0 0 0 20px'>
         
                <CollectionboxChat
                  messages={chat}
                  chatLoading={chatLoading}
                  isOwner={isOwner}
                  userRole={
                    user.operator
                      ? 'operator'
                      : !user.operator && !user.admin
                      ? 'customer'
                      : null
                  }
                  handleSubmitMessage={handleSubmitMessage}
                />
                     
              <Divider hidden/>
              <Divider hidden/>            
            </Wrapper>                
            </Wrapper>     
        </Wrapper>  
    </Wrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    user: selectors.auth.userSelector(state),
    collectionbox: selectors.collectionbox.oneCollectionboxSelector(state),
    readChat: selectors.collectionbox.readChatSelector(state), 
    chat: selectors.chat.getAllMsgSelector(state),
    contact: selectors.contact.getContactRightsSelector(state),
    companySelected: selectors.auth.companySelected(state),
    deleteDocumentLoading: selectors.collectionbox.deleteCollectionboxDocumentsSelector(state),
    addDocumentsLoading: selectors.collectionbox.addCollectionboxDocumentsLoadingSelector(state),
    addDocuments: selectors.collectionbox.addCollectionboxDocumentsSelector(state),
    downloadLoading: selectors.collectionbox.downloadCollectionboxLoadingSelector(state),
    categoriesDocuments: selectors.customsDeclaration.getAllCategoriesDocumentsSelector(state),
    categoriesDocListLoading: selectors.customsDeclaration.getAllCategoriesDocumentsLoadingSelector(state),
    collectionboxLoading: selectors.collectionbox.getOneCollectionboxLoadingSelector(state),
    readChatLoading: selectors.collectionbox.readChatLoadingSelector(state), 
    chatLoading: selectors.chat.getAllMsgLoadingSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
