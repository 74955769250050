import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { actions, selectors } from 'store';
import { Form, Divider, Checkbox, Header, Message } from 'semantic-ui-react';
import { Field, Formik, ErrorMessage } from 'formik';
import Wrapper from 'components/core/Wrapper/Wrapper';
import { Error } from 'components/Form';
import Button from 'components/Button';


const Index = ({
  intl,
  company,
  dispatch,
  countries,
  countriesLoading,
  euCountries,
}) => {
  const [selectedCountries, setSelectedCountries] = useState(null);

  useEffect(() => {
    dispatch(actions.companyTaxflowSettings.getAssignedCountries({
      params: { companyId: company.id }
    }));
    dispatch(actions.data.getEuCountries());
    dispatch(actions.socket.subscribeGetTaxflowAssignedCountry(company.id))

    return () => {
      dispatch(actions.socket.unsubscribeGetTaxflowAssignedCountry(company.id))
      dispatch(actions.companyTaxflowSettings.reset(['countries', 'error']));
      dispatch(actions.data.reset(['euCountries', 'error']));
    };
  }, [dispatch, company]);


  useEffect(() => {
    if(countries){
      setSelectedCountries({
        value: {
          Company_Id: company.id,
          Country_Id: countries.Country_Id, 
          name: countries.periodicity, 
          iso: countries.iso
        },
        text: intl.formatMessage({
          id: `countries.name.${countries.Country_Id}`,
        }),
        flag: countries.iso.toLowerCase()
      })
      }else{
      setSelectedCountries(null)
      }  
  }, [countries, company, intl]);


  const updateAssignedCountries = (values: any) => {
    console.log(values)
    dispatch(
      actions.companyTaxflowSettings.updateAssignedCountries({
        params: { companyId: company.id },
        data: {
          countries: values.countries
        },
      })
    );
  };


  return (
    <Wrapper
      display='flex'
      justifyContent='center'
      height='400px'
      padding='2rem 10rem 5rem'> 
    <Wrapper 
      padding='0' 
      display='flex' 
      flexDirection='column' 
      alignItems='center'
      width='300px'
    >
    {(selectedCountries && countries &&  (
      <>
    <Header>Country</Header>
    <Formik
      initialValues={{
        countries: selectedCountries.value || [],
      }}
      enableReinitialize={true}
      onSubmit={updateAssignedCountries}
    >
      {({
        errors,
        touched,
        handleSubmit,
        setFieldValue,
        setFieldTouched,
        values,
      }: any) => (<>
          <Form onSubmit={handleSubmit} loading={countriesLoading}>         
            <Field  
              label={`Country: ${countries.iso}`}            
              checked={values.countries.Country_Id}
              name='countries'
              component={Checkbox} 
              style={{ 
                minHeight: 250, 
                overflow: 'auto',   
                boxSizing: 'border-box',
                resize: 'none',                        
              }}       
              meta={{
                errors,
                touched,
                setFieldValue,
                setFieldTouched,
              }}
              onChange={(e, {value}) => {
                e && setFieldValue('countries', !values.countries)
              }}
            />       
            <ErrorMessage
              name='countries'
              render={(msg) => <Error msg={msg} />}
            />
            <Divider hidden />
            <Button type='submit' content='Submit' margin='0px' />
          </Form>
          <Divider hidden/>   
        </>
      )}
    </Formik>
    </>)) || (
      <Message info>Local data configutration is empty</Message>
    )}
    </Wrapper>
    </Wrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    euCountries: selectors.data.euCountriesSelector(state),
    euCountriesLoading: selectors.data.euCountriesLoadingSelector(state),
    countries: selectors.companyTaxflowSettings.getCountriesSelector(state),
    countriesLoading: selectors.companyTaxflowSettings.getCountriesLoadingSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
