// Dependencies
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-date-picker';

// Components
import Loader from 'components/Loader';
import CountrySelector from '../countriesSelector/countriesSelector';
import TextInput from '../textInput/textInput';

// Interfaces
import UpdateDirectorDetailsProperties from '../../interfaces/updateDirectorDetailsProperties';

// Resources
import { currentLanguage } from '../../../../views';
import saveLogo from '../../assets/images/logos/save.png';
import countriesEN from '../../assets/json/countriesEN.json';
import countriesFR from '../../assets/json/countriesFR.json';

// Stylesheets
import './forms.css';

/**
 * @name updateDirectorCompanyDetails.tsx
 * @implements updateFormsProperties.tsx
 *
 * @path /components/updateForms/updateDirectorCompanyDetails.tsx
 *
 * @description Defines content for update director company details popup in CompanyInformation component
 *
 * @param ref popup content reference
 * @returns UpdateDirectorCompanyDetails component
 */
const UpdateDirectorCompanyDetails: React.FC<
  UpdateDirectorDetailsProperties
> = ({
  ref,
  id,
  defaultLastName,
  defaultFirstName,
  defaultDateOfBirth,
  defaultPlaceOfBirth,
  defaultCountryOfBirth,
}) => {
  // Parameter that must be used before each translatable string to apply the good language version
  const { t } = useTranslation();

  const [lastName, setLastName] = useState<string>(defaultLastName);
  const [firstName, setFirstName] = useState<string>(defaultFirstName);
  const [placeOfBirth, setPlaceOfBirth] = useState<string>(defaultPlaceOfBirth);

  const handleLastNameChange = (value: string) => {
    setLastName(value);
  };

  const handleFirstNameChange = (value: string) => {
    setFirstName(value);
  };

  const handlePlaceOfBirthChange = (value: string) => {
    setPlaceOfBirth(value);
  };

  const [birthDate, setBirthDate] = useState<string>(defaultDateOfBirth);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isErrorHidden, setIsErrorHidden] = useState<boolean>(true);

  // Create a state variable to store the selected option
  const [selectedOptionCountry, setSelectedOptionCountry] = useState<
    string | null
  >(defaultCountryOfBirth);
  const [countries, setCountries] = useState<any[]>([]);

  // Attach an event handler to update the selected option when the user makes a selection
  const handleSelectChangeCountry = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const newSelectedOptionCountry = event.target.value;

    setSelectedOptionCountry(newSelectedOptionCountry);
  };

  // Fonction pour gérer la sélection de la date de début
  const handleBirthDateChange = (date: any) => {
    setBirthDate(date);
  };

  useEffect(() => {
    if (currentLanguage === 'fr') {
      setCountries(
        countriesFR.sort((a: any, b: any) => {
          return a.name.localeCompare(b.name);
        })
      );
    } else {
      setCountries(
        countriesEN.sort((a: any, b: any) => {
          return a.name.localeCompare(b.name);
        })
      );
    }
  }, [countries]);

  const updateDirectorDetails = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API_URL}/api/v1/spw/updateDirector/directorDetails`,
        {
          method: 'PATCH',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            directorId: id,
            lastName: lastName,
            firstName: firstName,
            dateOfBirth: birthDate,
            placeOfBirth: placeOfBirth,
            countryOfBirth: selectedOptionCountry,
          }),
        }
      );

      const result = await response.json();

      return result.data;
    } catch (error) {
      console.log('Erreur', error);
    }
  };

  const execUpdateDirectorDetails = async () => {
    setIsLoading(true);

    const result = await updateDirectorDetails();

    return result;
  };

  const isEmpty = (string: string) => {
    return string.trim() === '';
  };

  const handleClickSave = () => {
    if (
      lastName === undefined ||
      firstName === undefined ||
      placeOfBirth === undefined ||
      selectedOptionCountry === undefined ||
      isEmpty(lastName) ||
      isEmpty(firstName) ||
      isEmpty(placeOfBirth) ||
      isEmpty(selectedOptionCountry)
    ) {
      setIsErrorHidden(false);
    } else {
      setIsErrorHidden(true);
      execUpdateDirectorDetails();
      setIsLoading(false);
      window.location.reload();
    }
  };

  return (
    <>
      {(isLoading && <Loader />) ||
        (!isLoading && (
          <>
            <div className='companyPopupContainer' ref={ref}>
              <div className='inlineBlocks'>
                <div className='companyPopupFieldToUpdate'>
                  <p>
                    <b>
                      {t('companyPageStrings.directorLastNameFieldPlaceholder')}
                    </b>
                  </p>
                  <TextInput
                    classname='updateCompanyDirectorTextField'
                    initialValue={lastName}
                    onSave={handleLastNameChange}
                  />
                </div>
                <div className='companyPopupFieldToUpdate'>
                  <p>
                    <b>
                      {t(
                        'companyPageStrings.directorFirstNameFieldPlaceholder'
                      )}
                    </b>
                  </p>
                  <div className='companyEditAndTooltipContainer'>
                    <TextInput
                      classname='updateCompanyDirectorTextField'
                      initialValue={firstName}
                      onSave={handleFirstNameChange}
                    />
                  </div>
                </div>
              </div>

              <div className='inlineBlocks'>
                <div className='companyPopupFieldToUpdate'>
                  <p>
                    <b>
                      {t(
                        'companyPageStrings.directorDateOfBirthFieldPlaceholder'
                      )}
                    </b>
                  </p>
                  <div className='companyEditAndTooltipContainer'>
                    <DatePicker
                      value={birthDate}
                      className='updateDirectorDate'
                      format='dd/MM/yyyy'
                      onChange={handleBirthDateChange}
                      locale={currentLanguage.toUpperCase().split('-')[0]}
                    />
                  </div>
                </div>
                <div className='companyPopupFieldToUpdate'>
                  <p>
                    <b>
                      {t(
                        'companyPageStrings.directorPlaceOfBirthFieldPlaceholder'
                      )}
                    </b>
                  </p>
                  <div className='companyEditAndTooltipContainer'>
                    <TextInput
                      classname='updateCompanyDirectorTextField'
                      initialValue={placeOfBirth}
                      onSave={handlePlaceOfBirthChange}
                    />
                  </div>
                </div>
              </div>
              <div className='companyPopupFieldToUpdate'>
                <p>
                  <b>
                    {t(
                      'companyPageStrings.directorCountryOfBirthFieldPlaceholder'
                    )}
                  </b>
                </p>
                <CountrySelector
                  divClassName='companyEditAndTooltipContainer'
                  selectorClassName='directorCountrySelector'
                  selectorValue={selectedOptionCountry}
                  selectorOnChange={handleSelectChangeCountry}
                  countriesList={countries}
                />
              </div>
            </div>
            <div>
              <div className='space'></div>
              {isErrorHidden ? (
                <></>
              ) : (
                <div className='companyError'>
                  <p>{t('orderPageStrings.error')}</p>
                </div>
              )}
              <div className='saveButtonContainer'>
                <button className='saveButton' onClick={handleClickSave}>
                  <p>{t('popupStrings.saveText')}</p>
                  <img
                    src={saveLogo}
                    alt={t('popupStrings.saveLogo')}
                    className='saveLogo'
                  />
                </button>
              </div>
            </div>
          </>
        ))}
    </>
  );
};

export default UpdateDirectorCompanyDetails;
