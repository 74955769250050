// Dependencies
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import Loader from 'components/Loader';
import EditButton from '../editButton/editButton';
import DocumentSlot from '../documentSlot/documentSlot';
import GlobalEmployeeDetails from '../updateForms/updateGlobalEmployeeDetails';
import UploadConfirmation from '../uploadConfirmation/uploadConfirmation';

// Interfaces
import EmployeeDetailsProperties from '../../interfaces/employeeDetailsProperties';

/**
 * Resources
 */
import { currentLanguage } from 'views';
import countriesEN from '../../assets/json/countriesEN.json';
import countriesFR from '../../assets/json/countriesFR.json';

// Stylesheets
import './employees.css';

/**
 * @name employeeDetails.tsx
 *
 * @path /components/employeeDetails.tsx
 *
 * @description Defines employee details structure
 */
const EmployeeDetails: React.FC<EmployeeDetailsProperties> = ({ id }) => {
  // Parameter that must be used before each translatable string to apply the good language version
  const { t } = useTranslation();

  // Popups' references
  const childRefGlobal = useRef<HTMLDivElement>(null);
  const globalButtonRef = useRef<HTMLButtonElement>(null);
  const globalEditPopupRef = useRef<HTMLDivElement>(null);

  const [globalEditPopupIsVisible, setGlobalEditPopupIsVisible] =
    useState<boolean>(false);

  const handleClickEditGlobalButton = () => {
    setGlobalEditPopupIsVisible(!globalEditPopupIsVisible);
  };

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [civility, setCivility] = useState<string>();
  const [firstName, setFirstName] = useState<string>();
  const [lastName, setLastName] = useState<string>();
  const [nationality, setNationality] = useState<string>();
  const [dateOfBirth, setDateOfBirth] = useState<string>();
  const [placeOfBirth, setPlaceOfBirth] = useState<string>();
  const [countryOfBirth, setCountryOfBirth] = useState<string>();
  const [countryOfBirthText, setCountryOfBirthText] = useState<string>();
  const [, setAddressId] = useState<number>();
  const [mainAddress, setMainAddress] = useState<string>();
  const [additionalAddress, setAdditionalAddress] = useState<string>();
  const [postalCode, setPostalCode] = useState<string>();
  const [city, setCity] = useState<string>();
  const [country, setCountry] = useState<string>();
  const [countryText, setCountryText] = useState<string>();

  const getEmployeeAddress = async (addressId: number) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API_URL}/api/v1/spw/address/getById`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ id: addressId }),
        }
      );

      const result = await response.json();

      if (response == null) {
        return;
      } else {
        setMainAddress(result.data.mainAddress);
        setAdditionalAddress(result.data.additionalAddress);
        setPostalCode(result.data.postalCode);
        setCity(result.data.city);
        setCountry(result.data.country);

        let addressCountry: string;
        if (currentLanguage === 'fr') {
          let country = countriesFR.find(
            (country) => country.code === result.data.country
          );
          addressCountry = country ? country.name : 'Country not found';
        } else {
          let country = countriesEN.find(
            (country) => country.code === result.data.country
          );
          addressCountry = country ? country.name : 'Country not found';
        }
        setCountryText(addressCountry);

        return result;
      }
    } catch (error) {
      console.log('Erreur', error);
    }
  };

  const getEmployeeDetails = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API_URL}/api/v1/spw/getEmployeeDetails`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ id: id }),
        }
      );

      const result = await response.json();

      if (response == null) {
        return;
      } else {
        if (result.data.civility === 'Mr' || result.data.civility === 'M') {
          setCivility('1');
        } else {
          setCivility('2');
        }
        setFirstName(result.data.firstName);
        setLastName(result.data.lastName);
        setNationality(result.data.nationality);
        setDateOfBirth(result.data.dateOfBirth);
        setPlaceOfBirth(result.data.placeOfBirth);
        setCountryOfBirth(result.data.countryOfBirth);
        setAddressId(result.data.addressId);

        let countryOfBirth: string;
        if (currentLanguage === 'fr') {
          let country = countriesFR.find(
            (country) => country.code === result.data.countryOfBirth
          );
          countryOfBirth = country ? country.name : 'Country not found';
        } else {
          let country = countriesEN.find(
            (country) => country.code === result.data.countryOfBirth
          );
          countryOfBirth = country ? country.name : 'Country not found';
        }
        setCountryOfBirthText(countryOfBirth);

        await getEmployeeAddress(result.data.addressId);

        return result;
      }
    } catch (error) {
      console.log('Erreur', error);
    }
  };

  useEffect(() => {
    async function exec() {
      await getEmployeeDetails();
    }
    exec().then(() => setIsLoading(false));
  }, []);

  const formattedDateOfBirth = (dataString: string): string => {
    let dateObject = new Date(dataString);

    let day = dateObject.getDate();
    let month = dateObject.getMonth() + 1;
    let year = dateObject.getFullYear();

    let formattedDate = `${day.toString().padStart(2, '0')}/${month
      .toString()
      .padStart(2, '0')}/${year}`;

    return formattedDate;
  };

  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleUpload = () => {
    setShowConfirmation(true);

    setTimeout(() => setShowConfirmation(false), 3000);
  };

  useEffect(() => {
    if (window.sessionStorage.getItem('fileUpload') === 'true') {
      handleUpload();
    }

    window.sessionStorage.setItem('fileUpload', 'false');
  });

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className='employeeDetailsContainer'>
          <div className='employeeDetailsGlobalContainer'>
            <h4>{t('employeesDetailsPageStrings.globalSubtitle')}</h4>
            <div className='globalInformationContent'>
              <div className='globalInformationContentSubzoneTop'>
                <p>
                  <b>{t('employeesDetailsPageStrings.civility')}</b>
                  {civility === '1'
                    ? t('employeesPopupPageStrings.mr')
                    : t('employeesPopupPageStrings.mrs')}
                </p>
                <p>
                  <b>{t('employeesDetailsPageStrings.firstName')}</b>
                  {firstName}
                </p>
                <p>
                  <b>{t('employeesDetailsPageStrings.lastName')}</b>
                  {lastName}
                </p>
                <p>
                  <b>{t('employeesDetailsPageStrings.nationality')}</b>
                  {nationality}
                </p>
                <p>
                  <b>{t('employeesDetailsPageStrings.dateOfBirth')}</b>
                  {formattedDateOfBirth(dateOfBirth)}
                </p>
                <p>
                  <b>{t('employeesDetailsPageStrings.placeOfBirth')}</b>
                  {placeOfBirth}
                </p>
                <p>
                  <b>{t('employeesDetailsPageStrings.countryOfBirth')}</b>
                  {countryOfBirthText}
                </p>
              </div>
              <div className='globalInformationContentSubzoneBottom'>
                <p>
                  <b>{t('employeesDetailsPageStrings.mainAddress')}</b>
                  {mainAddress}
                </p>
                <p>
                  <b>{t('employeesDetailsPageStrings.additionalAddress')}</b>
                  {additionalAddress === undefined ||
                  additionalAddress === '' ? (
                    <i>{t('globalStrings.notSpecified')}</i>
                  ) : (
                    additionalAddress
                  )}
                </p>
                <p>
                  <b>{t('employeesDetailsPageStrings.postalCode')}</b>
                  {postalCode}
                </p>
                <p>
                  <b>{t('employeesDetailsPageStrings.city')}</b>
                  {city}
                </p>
                <p>
                  <b>{t('employeesDetailsPageStrings.country')}</b>
                  {countryText}
                </p>
              </div>
            </div>
            <EditButton
              popupVisible={globalEditPopupIsVisible}
              myRef={globalButtonRef}
              popupRef={globalEditPopupRef}
              title={t('employeesPopupPageStrings.title')}
              action={handleClickEditGlobalButton}
              children={
                <GlobalEmployeeDetails
                  ref={childRefGlobal}
                  employeeId={id}
                  defaultCivility={civility}
                  defaultFirstName={firstName}
                  defaultLastName={lastName}
                  defaultNationality={nationality}
                  defaultDateOfBirth={dateOfBirth}
                  defaultPlaceOfBirth={placeOfBirth}
                  defaultCountryOfBirth={countryOfBirth}
                  defaultMainAddress={mainAddress}
                  defaultAdditionalAddress={additionalAddress}
                  defaultPostalCode={postalCode}
                  defaultCity={city}
                  defaultCountry={country}
                />
              }
            />
          </div>
          <div className='employeeDetailsDocumentsContainer'>
            <h4>{t('employeesDetailsPageStrings.documentSubtitle')}</h4>
            <DocumentSlot
              entitled={t('employeesDetailsPageStrings.identityDocument')}
              fileType='pdf'
              fileSlot={1}
              employeeId={id}
              employeeName={firstName + '_' + lastName}
            />
            <DocumentSlot
              entitled={t('employeesDetailsPageStrings.identityPhoto')}
              fileType='jpg'
              fileSlot={2}
              employeeId={id}
              employeeName={firstName + '_' + lastName}
            />
            <DocumentSlot
              entitled={t('employeesDetailsPageStrings.constructionCard')}
              fileType='pdf'
              fileSlot={3}
              isConstructionCardSlot={true}
              employeeId={id}
              employeeName={firstName + '_' + lastName}
            />
            <DocumentSlot
              entitled={t('employeesDetailsPageStrings.medicalCertificate')}
              fileType='pdf'
              fileSlot={4}
              employeeId={id}
              employeeName={firstName + '_' + lastName}
            />
            <DocumentSlot
              entitled={t('employeesDetailsPageStrings.a1Form')}
              fileType='pdf'
              fileSlot={5}
              employeeId={id}
              employeeName={firstName + '_' + lastName}
            />
            <DocumentSlot
              entitled={t('employeesDetailsPageStrings.employementContract')}
              fileType='pdf'
              fileSlot={6}
              employeeId={id}
              employeeName={firstName + '_' + lastName}
            />
          </div>
        </div>
      )}
      {showConfirmation && (
        <UploadConfirmation
          message={t('globalStrings.fileUploadSuccessfully')}
          duration={3000}
          onClose={() => setShowConfirmation(false)}
        />
      )}
    </>
  );
};

export default EmployeeDetails;
