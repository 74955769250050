// Dependencies
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-date-picker';

// Interfaces
import OrderProperties from '../../interfaces/orderProperties';

import { currentLanguage } from 'views';

// Stylesheets
import './orderForm.css';
import 'react-calendar/dist/Calendar.css';
import 'react-date-picker/dist/DatePicker.css';

/**
 * @name datepicker.tsx
 *
 * @path /components/orderForm/datepicker.tsx
 *
 * @description Defines form's component to choose the posting's period to add during mission's creation
 */
const Datepicker: React.FC<OrderProperties> = ({ setCount }) => {
  // Parameter that must be used before each translatable string to apply the good language
  const { t } = useTranslation();

  // Variables
  const [startDate, setStartDate] = useState<string | null>(
    window.sessionStorage.getItem('startDate')
  );
  const [endDate, setEndDate] = useState<string | null>(
    window.sessionStorage.getItem('endDate')
  );

  // Reloader
  const handleIncrement = () => {
    setCount((prevCount) => prevCount + 1);
  };

  // Fonction pour gérer la sélection de la date de début
  const handleStartDateChange = (date: any) => {
    setStartDate(date);
    window.sessionStorage.setItem('startDate', date);

    if (window.sessionStorage.getItem('startDate') !== 'null') {
      let dateObject = new Date(date);

      let day = dateObject.getDate();
      let month = dateObject.getMonth() + 1;
      let year = dateObject.getFullYear();

      let formattedDate = `${day.toString().padStart(2, '0')}/${month
        .toString()
        .padStart(2, '0')}/${year}`;

      window.sessionStorage.setItem('startDateFormatted', formattedDate);
    }

    handleIncrement();
  };

  // Fonction pour gérer la sélection de la date de fin
  const handleEndDateChange = (date: any) => {
    setEndDate(date);
    window.sessionStorage.setItem('endDate', date);

    if (window.sessionStorage.getItem('endDate') !== 'null') {
      let dateObject = new Date(date);

      let day = dateObject.getDate();
      let month = dateObject.getMonth() + 1;
      let year = dateObject.getFullYear();

      let formattedDate = `${day.toString().padStart(2, '0')}/${month
        .toString()
        .padStart(2, '0')}/${year}`;

      window.sessionStorage.setItem('endDateFormatted', formattedDate);
    }

    handleIncrement();
  };

  return (
    <div className='orderFormDatePickerContainer'>
      <div className='rowconditionDate'>
        <pre>
          <p id='obligatoire'>* </p>
        </pre>
        <pre>
          <p>{t('orderPageStrings.datePicker.start')}</p>
        </pre>
        <DatePicker
          value={startDate || ''}
          className='datePicker'
          format='dd/MM/yyyy'
          minDate={new Date()}
          clearIcon={null}
          onChange={handleStartDateChange}
          locale={currentLanguage.toUpperCase().split('-')[0]}
        />
      </div>
      <div className='rowconditionDate'>
        <pre>
          <p id='obligatoire'>* </p>
        </pre>
        <pre>
          <p>{t('orderPageStrings.datePicker.end')}</p>
        </pre>
        <DatePicker
          value={endDate || ''}
          className='datePicker'
          format='dd/MM/yyyy'
          minDate={startDate != null ? new Date(startDate) : new Date()}
          clearIcon={null}
          onChange={handleEndDateChange}
          locale={currentLanguage.toUpperCase().split('-')[0]}
        />
      </div>
    </div>
  );
};

export default Datepicker;
