// Dependencies
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';

// Interfaces
import FileUploaderProperties from '../../interfaces/fileUploaderProperties';

// Stylesheets
import './fileUploader.css';

/**
 * @name fileUploader.tsx
 *
 * @path /components/fileUploader/fileUploader.tsx
 *
 * @description Defines a tool to upload documents by dropping or exploring file storage
 *
 * @param onFileUpload (file: File) => void
 */
const FileUploader: React.FC<FileUploaderProperties> = ({
  onFileUpload,
  hidden,
  fileType,
  classname,
}) => {
  // Parameter that must be used before each translatable string to apply the good language version
  const { t } = useTranslation();

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (acceptedFiles.length > 0) {
        const selectedFile = acceptedFiles[0];
        onFileUpload(selectedFile);
      }
    },
    [onFileUpload]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept:
      fileType === 'jpg'
        ? { 'images/jpeg': ['.jpeg'], 'images/jpg': ['.jpg'] }
        : { 'application/pdf': ['.pdf'] },
  });

  return (
    <>
      {!hidden && (
        <div
          className={
            classname === undefined ? 'fileUploaderContainer' : classname
          }
        >
          {fileType === 'jpg' && <p>{t('fileUploader.imageSpecifications')}</p>}
          <div {...getRootProps()} className='dropzoneStyle'>
            <input {...getInputProps()} />
            <p>
              {isDragActive
                ? t(`fileUploader.dropFile${fileType.toUpperCase()}`)
                : t(`fileUploader.dragAndDrop${fileType.toUpperCase()}`)}
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default FileUploader;
