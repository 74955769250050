import actionTypes from 'store/company/actionTypes';
import routes from './routes';
import { actions as messageHandler } from 'store/message';

export default function ({ api }) {
  const request = routes({ api });
  return (store) => (next) => async (action) => {
    switch (action.type) {
      case actionTypes.SEARCH:
        try {
          next({ type: actionTypes.SEARCH_LOADING });
          const response = await request.search({
            ...action.payload,
          });
          next({
            type: actionTypes.SEARCH_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.SEARCH_ERROR,
            error,
          });
        }
        break;
      case actionTypes.GET_ALL_TRANSPORTER:
        try {
          next({ type: actionTypes.GET_ALL_TRANSPORTER_LOADING });
          const response = await request.getAllTransporter();
          next({
            type: actionTypes.GET_ALL_TRANSPORTER_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_ALL_TRANSPORTER_ERROR,
            error,
          });
        }
        break;
      case actionTypes.HAS_SUBSCRIBED_TO:
        try {
          next({ type: actionTypes.HAS_SUBSCRIBED_TO_LOADING });
          const response = await request.hasSubscribedTo({
            ...action.payload,
          });
          next({
            type: actionTypes.HAS_SUBSCRIBED_TO_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.HAS_SUBSCRIBED_TO_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.getErrorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.GET_ALL:
        try {
          next({ type: actionTypes.GET_ALL_LOADING });
          const response = await request.getAll();
          next({
            type: actionTypes.GET_ALL_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_ALL_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.getErrorMessage(error.status, error.message)
          );
        }
        break;
      case actionTypes.GET_PERMISSIONS:
        try {
          next({ type: actionTypes.GET_PERMISSIONS_LOADING });
          const response = await request.getPermissions({ ...action.payload });
          next({
            type: actionTypes.GET_PERMISSIONS_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_PERMISSIONS_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.getErrorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.GET_ONE:
        try {
          next({ type: actionTypes.GET_ONE_LOADING });
          const response = await request.getOne({ ...action.payload });
          next({
            type: actionTypes.GET_ONE_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.GET_ONE_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.getErrorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.CREATE:
        try {
          next({ type: actionTypes.CREATE_LOADING });
          await request.create({
            ...action.payload,
          });
          next({
            type: actionTypes.CREATE_SUCCESS,
          });
          store.dispatch(messageHandler.successMessage(''));
        } catch (error) {
          next({
            type: actionTypes.CREATE_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.UPDATE:
        try {
          next({ type: actionTypes.UPDATE_LOADING });
          await request.update({
            ...action.payload,
          });
          next({
            type: actionTypes.UPDATE_SUCCESS,
          });
          store.dispatch(messageHandler.successMessage(''));
        } catch (error) {
          next({
            type: actionTypes.UPDATE_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;

      case actionTypes.REMOVE:
        try {
          next({ type: actionTypes.REMOVE_LOADING });
          await request.remove({
            ...action.payload,
          });
          next({
            type: actionTypes.REMOVE_SUCCESS,
          });
          store.dispatch(messageHandler.successMessage(''));
        } catch (error) {
          next({
            type: actionTypes.REMOVE_ERROR,
            error,
          });
        }
        break;

      default:
        next(action);
        break;
    }
  };
}
