import { resetState, refreshError } from 'store/common';
import actionTypes from './actionTypes';
import produce from 'immer';

const INITIAL_STATE = {
  success: {
    getAllTransporter: false,
    getAll: false,
    getPermissions: false,
    getOne: false,
    create: false,
    update: false,
    remove: false,
    search: false,
    hasSubscribedTo: false,
  },
  errors: {
    getAllTransporter: null,
    getAll: null,
    getPermissions: null,
    getOne: null,
    create: null,
    update: null,
    remove: null,
    search: null,
    hasSubscribedTo: null,
  },
  loading: {
    getAllTransporter: false,
    getAll: false,
    getPermissions: false,
    getOne: false,
    create: false,
    update: false,
    remove: false,
    search: false,
    hasSubscribedTo: false,
  },
  transporters: null,
  companies: null,
  permissions: null,
  company: null,
  search: null,
  hasSubscribedTo: null,
  error: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {

     // search
     case actionTypes.SEARCH_LOADING:
      return produce(state, (draft) => {
        draft.loading.search = true;
      });
    case actionTypes.SEARCH_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.search = null;
        draft.loading.search = false;
        draft.success.search = true;
        draft.search = action.payload;
      });
    case actionTypes.SEARCH_ERROR:
      return produce(state, (draft) => {
        draft.errors.search = true;
        draft.loading.search = false;
        draft.success.search = false;
        !draft.error.includes('search') && draft.error.push('search');
      });

    // Get transporters
    case actionTypes.GET_ALL_TRANSPORTER_LOADING:
      return produce(state, (draft) => {
        draft.loading.getAllTransporter = true;
      });
    case actionTypes.GET_ALL_TRANSPORTER_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getAllTransporter = null;
        draft.loading.getAllTransporter = false;
        draft.success.getAllTransporter = true;
        draft.transporters = action.payload;
      });
    case actionTypes.GET_ALL_TRANSPORTER_ERROR:
      return produce(state, (draft) => {
        draft.errors.getAllTransporter = true;
        draft.loading.getAllTransporter = false;
        draft.success.getAllTransporter = false;
        !draft.error.includes('transporters') &&
          draft.error.push('transporters');
      });

    // Get services subscribed
    case actionTypes.HAS_SUBSCRIBED_TO_LOADING:
      return produce(state, (draft) => {
        draft.loading.hasSubscribedTo = true;
      });

    case actionTypes.HAS_SUBSCRIBED_TO_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.hasSubscribedTo = null;
        draft.loading.hasSubscribedTo = false;
        draft.success.hasSubscribedTo = true;
        draft.hasSubscribedTo = action.payload;
      });

    case actionTypes.HAS_SUBSCRIBED_TO_ERROR:
      return produce(state, (draft) => {
        draft.errors.hasSubscribedTo = true;
        draft.loading.hasSubscribedTo = false;
        draft.success.hasSubscribedTo = false;
        !draft.error.includes('hasSubscribedTo') &&
          draft.error.push('hasSubscribedTo');
      });

    // Get companies
    case actionTypes.GET_ALL_LOADING:
      return produce(state, (draft) => {
        draft.loading.getAll = true;
      });

    case actionTypes.GET_ALL_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getAll = null;
        draft.loading.getAll = false;
        draft.success.getAll = true;
        draft.companies = action.payload;
      });

    case actionTypes.GET_ALL_ERROR:
      return produce(state, (draft) => {
        draft.errors.getAll = true;
        draft.loading.getAll = false;
        draft.success.getAll = false;
        !draft.error.includes('companies') && draft.error.push('companies');
      });

    // Get one
    case actionTypes.GET_ONE_LOADING:
      return produce(state, (draft) => {
        draft.loading.getOne = true;
      });

    case actionTypes.GET_ONE_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getOne = null;
        draft.loading.getOne = false;
        draft.success.getOne = true;
        draft.company = action.payload;
      });

    case actionTypes.GET_ONE_ERROR:
      return produce(state, (draft) => {
        draft.errors.getOne = true;
        draft.loading.getOne = false;
        draft.success.getOne = false;
        !draft.error.includes('company') && draft.error.push('company');
      });

    // Get permissions
    case actionTypes.GET_PERMISSIONS_LOADING:
      return produce(state, (draft) => {
        draft.loading.getPermissions = true;
      });

    case actionTypes.GET_PERMISSIONS_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getPermissions = null;
        draft.loading.getPermissions = false;
        draft.success.getPermissions = true;
        draft.permissions = action.payload;
      });

    case actionTypes.GET_PERMISSIONS_ERROR:
      return produce(state, (draft) => {
        draft.errors.getPermissions = true;
        draft.loading.getPermissions = false;
        draft.success.getPermissions = false;
        !draft.error.includes('permissions') && draft.error.push('permissions');
      });

    // Create
    case actionTypes.CREATE_LOADING:
      return produce(state, (draft) => {
        draft.loading.create = true;
      });

    case actionTypes.CREATE_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.create = null;
        draft.loading.create = false;
        draft.success.create = true;
      });

    case actionTypes.CREATE_ERROR:
      return produce(state, (draft) => {
        draft.errors.create = true;
        draft.loading.create = false;
        draft.success.create = false;
        !draft.error.includes('create') && draft.error.push('create');
      });

    // Update
    case actionTypes.UPDATE_LOADING:
      return produce(state, (draft) => {
        draft.loading.update = true;
      });

    case actionTypes.UPDATE_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.update = null;
        draft.loading.update = false;
        draft.success.update = true;
      });

    case actionTypes.UPDATE_ERROR:
      return produce(state, (draft) => {
        draft.errors.update = true;
        draft.loading.update = false;
        draft.success.update = false;
        !draft.error.includes('update') && draft.error.push('update');
      });

    // remove
    case actionTypes.REMOVE_LOADING:
      return produce(state, (draft) => {
        draft.loading.remove = true;
      });

    case actionTypes.REMOVE_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.remove = null;
        draft.loading.remove = false;
        draft.success.remove = true;
      });

    case actionTypes.REMOVE_ERROR:
      return produce(state, (draft) => {
        draft.errors.remove = true;
        draft.loading.remove = false;
        draft.success.remove = false;
        !draft.error.includes('remove') && draft.error.push('remove');
      });

    // Reset
    case actionTypes.RESET:
      return resetState(state, INITIAL_STATE, action.payload.keys);

    case actionTypes.REFRESH_ERROR:
      return refreshError(state.error, action.payload.keys);

    default:
      return state;
  }
};
