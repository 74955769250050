import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Icon } from 'semantic-ui-react';
import { actions } from 'store';

import {
  NotificationNumber,
  NotificationWrapper,
} from './NotificationBellStyles';

const NotificationBell = ({ dispatch, numberNotifications }) => {
  const handleClick = () => dispatch(actions.notifications.openNotification());

  return (
    (numberNotifications !== 0 && <NotificationWrapper onClick={handleClick}>
      <Icon className='notificationBell' name='bell' inverted size='large' />
        <NotificationNumber className='notificationBell'>
          {numberNotifications > 100 ? '99+' : numberNotifications}
        </NotificationNumber>
    </NotificationWrapper>)
  );
};

const mapStateToProps = (state) => {
  return {};
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(NotificationBell);
