// Dependencies
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { selectors } from 'store';

// Components
import Loader from 'components/Loader';
import Mission from './mission';
import { EditText } from 'react-edit-text';

// Stylesheets
import './missions.css';
import 'react-edit-text/dist/index.css';

interface MissionsProperties {
  companyId: number;
}

/**
 * @name missions.tsx
 *
 * @path /components/missions.tsx
 *
 * @description Defines missions list's structure
 */
const Missions: React.FC<MissionsProperties> = ({ companyId, user }: any) => {
  // Parameter that must be used before each translatable string to apply the good language version
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const initialValue = 1;
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState<number>(initialValue);
  const adminOrOperator = user.admin || user.operator;
  const request = adminOrOperator
    ? 'getAllMissions'
    : 'getAllMissionsByCompany';

  const headers = [
    {
      id: 1,
      displayText: t('missionPageStrings.headers.reference'),
      classname: 'missionsHeaderReference',
    },
    {
      id: 2,
      displayText: t('missionPageStrings.headers.orderDate'),
      classname: 'missionsHeaderInformation',
    },
    {
      id: 3,
      displayText: t('missionPageStrings.headers.company'),
      classname: 'missionsHeaderInformation',
    },
    {
      id: 4,
      displayText: t('missionPageStrings.headers.startDate'),
      classname: 'missionsHeaderInformation',
    },
    {
      id: 5,
      displayText: t('missionPageStrings.headers.endDate'),
      classname: 'missionsHeaderInformation',
    },
    {
      id: 6,
      displayText: t('missionPageStrings.headers.countryOfOrigin'),
      classname: 'missionsHeaderInformation',
    },
    {
      id: 7,
      displayText: t('missionPageStrings.headers.sector'),
      classname: 'missionsHeaderInformation',
    },
    {
      id: 8,
      displayText: t('missionPageStrings.headers.employees'),
      classname: 'missionsHeaderInformation',
    },
    {
      id: 9,
      displayText: t('missionPageStrings.headers.inCharge'),
      classname: 'missionsHeaderInformation',
    },
    {
      id: 10,
      displayText: t('missionPageStrings.headers.status'),
      classname: 'missionsHeaderTags',
    },
  ];

  const [apiResult, setApiResult] = useState<[{ any: any }][]>([]);
  const [apiResultEmpty, setApiResultEmpty] = useState<boolean>(true);

  const [searchTerm, setSearchTerm] = useState<string>('');

  const filteredUsers =
    !apiResultEmpty &&
    apiResult.filter(
      (mission) =>
        mission[0]
          .toString()
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        //@ts-ignore
        mission[30]
          ?.toString()
          ?.toLowerCase()
          .includes(searchTerm?.toLowerCase() ?? '') ||
        //@ts-ignore
        mission[31].toString().toLowerCase().includes(searchTerm.toLowerCase())
    );

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const getCurrentPageItems = (data: any) => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return data.slice(startIndex, endIndex);
  };

  const getMissions = async () => {
    setIsLoading(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API_URL}/api/v1/spw/${request}`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            id: companyId,
            filter: window.sessionStorage.getItem('missionFilter'),
          }),
        }
      );

      const result = await response.json();

      if (response == null) {
        return;
      } else {
        setApiResult(result.data);
        if (result.data.length !== 0) {
          setApiResultEmpty(false);
        }

        return result;
      }
    } catch (error) {
      console.log('Erreur', error);
    }
  };

  useEffect(() => {
    async function exec() {
      await getMissions();
    }
    exec().then(() => setIsLoading(false));
  }, []);

  const formattedDate = (dataString: string): string => {
    let dateObject = new Date(dataString);

    let day = dateObject.getDate();
    let month = dateObject.getMonth() + 1;
    let year = dateObject.getFullYear();

    let formattedDate = `${day.toString().padStart(2, '0')}/${month
      .toString()
      .padStart(2, '0')}/${year}`;

    return formattedDate;
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className='missionsContainer'>
          <div className='searchFieldContainer'>
            <div className='searchField'>
              <EditText
                placeholder={t('globalStrings.searchPlaceholder')}
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </div>
          </div>
          {/* Header */}
          <div className='missionsHeaderContainer'>
            {headers.map((header) => (
              <p key={header.id} className={header.classname}>
                {header.displayText}
              </p>
            ))}
          </div>

          {/* Body */}
          <div className='missionsBodyContainer'>
            <>
              {apiResultEmpty ? (
                <div className='noMissionRegistered'>
                  <p>{t('missionPageStrings.noMissionRegistered')}</p>
                </div>
              ) : filteredUsers.length > 0 ? (
                getCurrentPageItems(filteredUsers).map((item, index) => (
                  <Mission
                    key={index}
                    missionId={item[32]}
                    orderDate={formattedDate(item[28])}
                    reference={item[0]}
                    company={item[31]}
                    companyId={item[1]}
                    startDate={formattedDate(item[10])}
                    endDate={formattedDate(item[11])}
                    originCountry={item[33]}
                    sector={item[2]}
                    numberOfEmployees={item[6]}
                    personInCharge={
                      item[30] == null ? (
                        <i>{t('globalStrings.onHold')}</i>
                      ) : (
                        item[30]
                      )
                    }
                    tags={[
                      item[24] === true ? 1 : 0,
                      item[25] === null ? 0 : 1,
                      item[29] === true ? 1 : 0,
                      item[30] === null ? 0 : 1,
                      item[34] === true ? 1 : 0,
                      item[35] === true ? 1 : 0,
                    ]}
                  />
                ))
              ) : (
                <div className='noMissionFind'>
                  <p>{t('missionPageStrings.noMissionFind')}</p>
                </div>
              )}
            </>
            {/* Footer */}
            <div className='missionsFooterContainer'>
              <>
                {apiResultEmpty ? (
                  <></>
                ) : (
                  <>
                    <button
                      onClick={() => setCurrentPage(currentPage - 1)}
                      disabled={currentPage === 1}
                    >
                      ◀
                    </button>
                    <span>{currentPage}</span>
                    <button
                      onClick={() => setCurrentPage(currentPage + 1)}
                      disabled={currentPage * itemsPerPage >= apiResult.length}
                    >
                      ▶
                    </button>
                  </>
                )}
              </>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: selectors.auth.userSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Missions);
