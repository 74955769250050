/**
 * Defines a timeout that stops execution during a
 * specified time.
 *
 * @param ms Pause duration, in milliseconds.
 *
 * @returns A promise that resolves automatically
 * after the specified interval.
 */
export async function sleep(ms: number): Promise<void> {
  return new Promise<void>((resolve) => setTimeout(resolve, ms));
}
