import React, { useEffect, useRef, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { actions, selectors } from 'store';
import { Form, Divider, Table } from 'semantic-ui-react';
import { Field, Formik } from 'formik';
import Wrapper from 'components/core/Wrapper/Wrapper';
import Button from 'components/Button';
import { operatorSchema } from 'interfaces/formSchema';
import Dropdown from 'components/Dropdown';
import TableSpacedRowsSmall from 'components/TableSpacedRowsSmall';
import { DefaultWrapper, IdWrapper } from '../../styles';

const Index = ({
  dispatch,
  operators,
  user,
  company,
  settings,
  settingsLoading,
  operatorsLoading
}) => {
  const userAdminOrOp = user.admin || user.operator
  const [operatorsList, setOperators] = useState(null);
 


  useEffect(() => {
    dispatch(actions.operators.getAllOperator());
    dispatch(actions.companyIntrastatSettings.getCompanySetting({
      params: {
        companyId: company.id
      }
    }));

    dispatch(actions.socket.subscribeGetIntrastSetting(company.id))

    return () => {
      dispatch(actions.operators.reset(['allOperator', 'error']));
      dispatch(actions.companyIntrastatSettings.reset(['getCompanySetting', 'error']));
      dispatch(actions.socket.unsubscribeGetIntrastSetting(company.id))
    };
  }, [dispatch, company.id]);

  useEffect(() => {
      operators && setOperators(
        operators.map((o) => {
          return {
              key: o.id,
              value: o.id,
              text: `${o.firstName}-${o.lastName}`,
            }
          })
        )
  }, [operators]);


  const save = (values) => {
    dispatch(
        actions.companyIntrastatSettings.handleSettings({
          params: { 
            companyId: company.id 
          },
          data: {
            action: 'create',
            operator: values.operator
        }})
      );
      
  };

  const handleDelete = (operator) => {
    dispatch(
      actions.companyIntrastatSettings.handleSettings({
        params: { 
          companyId: company.id 
        },
        data: {
          action: 'delete',
          operator: operator
      }})
    );
  }


  return (
    <Wrapper
      display='flex'
      justifyContent='center'
      height='400px'
      padding='2rem 10rem 5rem'> 

      <Wrapper 
        display='flex' 
        width='100%' 
        flexDirection='column' 
        justifyContent='flex-start' 
        alignItems='center'> 

          <Formik
            initialValues={{
              operator: '', 
            }}
            enableReinitialize={true}
            onSubmit={save}
            validationSchema={operatorSchema}
          >
            {({
              errors,
              touched,
              handleSubmit,
              setFieldValue,
              setFieldTouched,
              values,
            }: any) => (       
               <Form onSubmit={handleSubmit}> 
                              
                <Wrapper margin='0.5rem' width='380px'>                         
                  <Field  
                    label='Operators'            
                    placeholder='select an operator...'
                    name='operator'
                    component={Dropdown}
                    selection
                    disabled={!userAdminOrOp}
                    options={operatorsList}
                    loading={operatorsLoading}             
                    meta={{
                      errors,
                      touched,
                      setFieldValue,
                      setFieldTouched,
                    }}
                  />
                  <Divider hidden />
                  <Button 
                  type='submit'
                  disabled={!userAdminOrOp} 
                  content='Submit'
                  />
                </Wrapper> 
              </Form>
            )}
        </Formik>

    <Wrapper 
      display='flex' 
      justifyContent='center' 
      width='800px'>

      <TableSpacedRowsSmall>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>id</Table.HeaderCell>
            <Table.HeaderCell>First name</Table.HeaderCell>  
            <Table.HeaderCell>Last name</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>                   
          </Table.Row>
        </Table.Header>
        <Table.Body>
        {settings && settings.User && (
          <>
              <Table.Cell><IdWrapper>{settings.User_Id}</IdWrapper></Table.Cell>
              <Table.Cell><DefaultWrapper>{settings.User.firstName}</DefaultWrapper></Table.Cell>  
              <Table.Cell><DefaultWrapper>{settings.User.lastName}</DefaultWrapper></Table.Cell> 
              <Table.Cell>
                <Wrapper
                  display='flex'
                  justifyContent='flex-end'
                >
                  <Button
                    content='Delete'
                    icon='delete'
                    type='button'
                    disabled={!userAdminOrOp}
                    color='red'
                    labelPosition='right'
                    onClick={() => {
                      handleDelete(settings.User_Id)
                    }}
                    margin='5px'
                  />
                </Wrapper>
              </Table.Cell>                  
          </>
        )}
        </Table.Body>
      </TableSpacedRowsSmall>        
    </Wrapper>                  
    <Divider hidden/>   
    </Wrapper>
    </Wrapper>
  
  );
};

const mapStateToProps = (state) => {
  return {
    generalPermissions: selectors.admin.permissionsSelector(state),
    operators: selectors.operators.allOperatorSelector(state),
    operatorsLoading: selectors.operators.loadingSelector(state),
    settingsLoading: selectors.companyIntrastatSettings.getCompanySettingsLoadingSelector(state),
    settings: selectors.companyIntrastatSettings.companyIntrastatSettingsSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
