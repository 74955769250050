import React from 'react';
import { Table, Button as Btn, Icon } from 'semantic-ui-react';
import Button from 'components/Button';
import ButtonValidationModal from '../ButtonValidationModal';
import { Open, Status } from './DocumentsStyles';

const Documents = ({
  intl, 
  isOwner,
  statementValidation,
  docList,
  document,
  declarationState,
  index,
  handleShowDocument,
  handleCheckDocument,
  handleDeleteDocument,
  openTagManager,
}: DocumentsProps) => {

    let icon
    switch(document.status){
      case 'archive':
        icon = 'folder open outline';
        break;
  
    default:
        icon = 'file pdf';
    }

    // if document type is not (VATListing - 10) anyone can open tagManager
    // if document type is (VATListing - 10) and user is Operator, document can be downloaded
    // if documentg type is (State - 11) you cannot delete it

    return (  
        <>
        <Table.Row key={index}>
            <Table.Cell>{
            (
              document.IntrastatDocumentCategory_Id !== 10 && 
              document.status && (
                <Open>
                <Icon
                  name={document.split ? 'folder open' : icon}
                  size='small'
                  color='black'                    
                />
                {document.name}
                </Open>
              )) || (<Open><Icon
                name={document.split ? 'folder open' : icon}
                size='small'
                color='black'                    
              />{document.name}</Open>)}</Table.Cell>
            <Table.Cell>{document.IntrastatDocumentCategory.name}</Table.Cell>
            <Table.Cell>
              {new Date(
                document.createdAt
              ).toLocaleDateString('en', {
                weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
              })}
            </Table.Cell>
            <Table.Cell>
              {document.IntrastatDocumentCategory_Id === 11 && statementValidation && (
              <ButtonValidationModal
                noSubmit
                intl={intl}
                trgrContent={intl.formatMessage({id: `intrastat.validation` })}
                headerContent={intl.formatMessage({id: `intrastat.validation` })}
                modalContent={intl.formatMessage({id: `intrastat.validation.alert` })}
                trgrColor='blue'
                action='validate'
                disabled={document.validation}
                onClick={(status) => {
                  handleCheckDocument(document.id, status);
                }}
                margin='5px'
              />  
                                     
              )}    
            </Table.Cell>
            <Table.Cell>
            {(document.validation !== null && <Status color={document.validation === 1 ? 'green' : document.validation === 2 ? 'red' : 'transparent'}>
              {document.validation === 1 ? 'Validated' : document.validation === 2 ? 'Refused' : document.validation === 0 ? 'Awaiting' : null}
            </Status>)}
            </Table.Cell>
            <Table.Cell>            
              <Btn.Group vertical widths='two'>           
                <Button
                  type='submit'
                  margin='5px'
                  content={intl.formatMessage({id: `intrastat.show` })}
                  color='blue'
                  onClick={() => openTagManager()}
                />                      
                <ButtonValidationModal
                  noSubmit
                  intl={intl}
                  trgrContent={intl.formatMessage({id: `intrastat.delete` })}
                  headerContent={intl.formatMessage({id: `intrastat.delete` })}
                  modalContent={intl.formatMessage({id: `intrastat.deletion.alert` })}
                  action='delete'
                  trgrColor='red'
                  disabled={declarationState || (isOwner && document.IntrastatDocumentCategory_Id === 11)}
                  onClick={() => {
                    handleDeleteDocument(document.id);
                  }}
                  margin='5px'
                />                      
              </Btn.Group>
            
            </Table.Cell>
          </Table.Row>
        </>      
    )
}

interface DocumentsProps {
    intl: any
    userAdminOrOp: boolean,
    isOwner: boolean,
    statementValidation: boolean,
    declarationState: boolean, 
    document: any,
    index: number,
    handleShowDocument: (docId) => void;
    handleDeleteDocument: (docId) => void;
    handleCheckDocument: (docId, status) => void;
    openTagManager?: () => void;
    docList?: any,
  }
  
  export default Documents;