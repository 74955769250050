/**
 * Dependencies.
 */
import axios from 'axios';

/**
 * Downloads a file from a Minio S3 bucket.
 *
 * This function sends a POST request to the specified API endpoint to initiate
 * the download of a file located in S3. It receives the file path as a parameter,
 * processes the response to create a downloadable URL, and opens that URL in a new tab.
 *
 * @param filePath The path of the file to download from S3. This should
 * be the full path to the file in the S3 bucket.
 *
 * @throws Throws an error if the file download fails, which will be
 * logged to the console.
 *
 * @returns A promise that resolves when the download has
 * been initiated, or rejects if an error occurs.
 */
export async function downloadFromS3(filePath: string): Promise<void> {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_DEV_API_URL}/api/v1/spw/downloadFromS3`,
      { filePath },
      {
        responseType: 'blob',
      }
    );
    const fileType = response.headers['content-type'];
    const url = window.URL.createObjectURL(
      new Blob([response.data], { type: fileType })
    );

    const link = document.createElement('a');
    link.href = url;
    link.download = `${filePath.split('/')[1]}.pdf`;
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Error during file download', error);
  }
}
