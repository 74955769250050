// Dependencies
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { selectors } from 'store';

// Components
import Loader from 'components/Loader';
import Employee from './employee';
import Popup from '../popup/popup';
import CreateEmployee from '../createForms/createEmployee';

// Interfaces
import EmployeesProperties from '../../interfaces/employeesProperties';

// Resources
import addLogo from '../../assets/images/logos/add.png';

// Stylesheets
import './employees.css';

/**
 * @name employees.tsx
 *
 * @path /components/employees/employees.tsx
 *
 * @description Defines employees list's structure
 */
const Employees: React.FC<EmployeesProperties> = ({ companyId }, user: any) => {
  // Parameter that must be used before each translatable string to apply the good language version
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const initialValue = 1;
  const itemsPerPage = 5;
  const [currentPage, setCurrentPage] = useState<number>(initialValue);
  const [popupIsVisible, setPopupIsVisible] = useState<boolean>(false);
  const addEmployeeButtonRef = useRef<HTMLDivElement>(null);
  const addEmployeePopupRef = useRef<HTMLDivElement>(null);

  const headers = [
    {
      id: 1,
      displayText: t('employeesPageStrings.firstName'),
      classname: 'employeesFirstName',
    },
    {
      id: 2,
      displayText: t('employeesPageStrings.lastName'),
      classname: 'employeesLastName',
    },
    {
      id: 3,
      displayText: t('employeesPageStrings.civility'),
      classname: 'employeesCivility',
    },
    {
      id: 4,
      displayText: t('employeesPageStrings.dateOfBirth'),
      classname: 'employeesDateOfBirth',
    },
    {
      id: 5,
      displayText: t('employeesPageStrings.nationality'),
      classname: 'employeesNationality',
    },
  ];

  const [apiResult, setApiResult] = useState<[{ any: any }][]>([]);
  const [apiResultEmpty, setApiResultEmpty] = useState<boolean>(true);

  // Extract current page element
  const getCurrentPageItems = (data: any) => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return data.slice(startIndex, endIndex);
  };

  const handleClickCreateNewEmployee = () => {
    setPopupIsVisible(true);
  };

  const handleClickClosePopup = () => {
    setPopupIsVisible(false);
  };

  const getEmployees = async () => {
    setIsLoading(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API_URL}/api/v1/spw/getAllEmployeesByCompany`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ id: companyId }),
        }
      );

      const result = await response.json();

      if (response == null) {
        return;
      } else {
        setApiResult(result.data);
        if (result.data.length !== 0) {
          setApiResultEmpty(false);
        }
        return result;
      }
    } catch (error) {
      console.log('Erreur', error);
    }
  };

  useEffect(() => {
    async function exec() {
      await getEmployees();
    }
    exec().then(() => setIsLoading(false));
  }, []);

  const formattedDateOfBirth = (dataString: string): string => {
    let dateObject = new Date(dataString);

    let day = dateObject.getDate();
    let month = dateObject.getMonth() + 1;
    let year = dateObject.getFullYear();

    let formattedDate = `${day.toString().padStart(2, '0')}/${month
      .toString()
      .padStart(2, '0')}/${year}`;

    return formattedDate;
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className='employeesContainer'>
          {/* Header */}
          <div className='addEmployeeButtonContainer'>
            {!user.operator && (
              <button
                className='addEmployeeButton'
                onClick={handleClickCreateNewEmployee}
              >
                <div>
                  <p>{t('employeesPageStrings.addEmployee')}</p>
                </div>
                <img
                  src={addLogo}
                  alt={t('Test')}
                  className='addEmployeeLogo'
                />
              </button>
            )}
          </div>

          {popupIsVisible ? (
            <Popup
              myRef={addEmployeeButtonRef}
              title={t('employeesPopupPageStrings.createTitle')}
              onClickClose={handleClickClosePopup}
            >
              {<CreateEmployee ref={addEmployeePopupRef} id={companyId} />}
            </Popup>
          ) : null}

          <div className='employeesHeaderContainer'>
            {headers.map((header) => (
              <p key={header.id} className={header.classname}>
                {header.displayText}
              </p>
            ))}
          </div>

          {/* Body */}
          <div className='employeesBodyContainer'>
            <>
              {apiResultEmpty ? (
                <div className='noEmployeeRegistered'>
                  <p>{t('employeesPageStrings.noEmployeeRegistered')}</p>
                </div>
              ) : (
                getCurrentPageItems(apiResult).map((item, index) => (
                  <Employee
                    key={index}
                    companyId={companyId}
                    employeeId={item[0]}
                    firstName={item[2]}
                    lastName={item[3]}
                    gender={item[1]}
                    dateOfBirth={formattedDateOfBirth(item[5])}
                    nationality={item[4]}
                  />
                ))
              )}
            </>
          </div>

          {/* Footer */}
          <div className='employeesFooterContainer'>
            <>
              {apiResultEmpty ? (
                <></>
              ) : (
                <>
                  <button
                    onClick={
                      currentPage > 5
                        ? () => setCurrentPage(currentPage - 5)
                        : () => setCurrentPage(1)
                    }
                    disabled={currentPage === 1}
                  >
                    ◀◀
                  </button>
                  <button
                    onClick={() => setCurrentPage(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    ◀
                  </button>
                  <span>{currentPage}</span>
                  <button
                    onClick={() => setCurrentPage(currentPage + 1)}
                    disabled={currentPage * itemsPerPage >= apiResult.length}
                  >
                    ▶
                  </button>
                  <button
                    onClick={
                      currentPage + 5 <= apiResult.length / itemsPerPage
                        ? () => setCurrentPage(currentPage + 5)
                        : () =>
                            setCurrentPage(
                              Math.ceil(apiResult.length / itemsPerPage)
                            )
                    }
                    disabled={currentPage * itemsPerPage >= apiResult.length}
                  >
                    ▶▶
                  </button>
                </>
              )}
            </>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: selectors.auth.userSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Employees);
