import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  Container,
  Dimmer,
  Divider,
  Header,
  Step,
  Table,
} from 'semantic-ui-react';
import { actions, selectors } from 'store';

import Button from 'components/Button';
import LinkButton from 'components/core/LinkButton/LinkButton';
import { Main } from 'components/Layouts';
import NewDeclaration from 'views/Fiscal/VatDeclaration/New';
import EmptyTable from 'components/EmptyTable';
import Loader from 'components/Loader';
import Wrapper from 'components/core/Wrapper/Wrapper';
import Search from 'components/Search';
import { shortDate } from 'services/helper/dateFormater';
import Pagination from 'components/Pagination';
import SearchFilters from './SearchFilters/searchFilters';
import { Right } from './styles';

const Index = ({ 
  dispatch, 
  declarations, 
  companies,
  countries,
  mapping,
  errorHandlerData, 
  errorHandlerCompany, 
  errorHandlerDeclaration,
  loadingHandlerDeclaration, 
  error }) => {
  const [modalStatus, setmodalStatus] = useState(false);
  const [column, setcolumn] = useState(null);
  const [direction, setdirection] = useState(null);
  const [data, setdata] = useState(null)
  const [countData, setCountData] = useState(null);
  const [resetSearch, setresetSearch] = useState(false);
  const [filterBar, setfilterBar] = useState(false);
  const [companiesList, setcompaniesList] = useState([]);
  const [resetPage, setResetPagination]: any = useState(false);
  const LIMIT = 15


  useEffect(() => {
    dispatch(actions.fiscalDeclaration.reset(['error']));

    dispatch(actions.fiscalDeclaration.getDeclarations({
      query: {
        limit: LIMIT,
        page: sessionStorage.getItem('declaVatPage') ? 
        parseInt(sessionStorage.getItem('declaVatPage')) : 1
      }
    }));
    dispatch(actions.fiscalDeclaration.getCompanySubscribed());
    dispatch(actions.data.getAllCountries());                                              

    dispatch(actions.socket.subscribeUpdateDeclarations());

    return () => {
      dispatch(actions.fiscalDeclaration.reset([
        'declarations', 
        'companySubscribed', 
        'error'
      ]));
      dispatch(actions.data.reset(['allCountries', 'error']));
      dispatch(actions.socket.unsubscribeUpdateDeclarations());
    };
  }, [dispatch]);


  useEffect(() => {
    if(declarations){
      setCountData(declarations.count)
      declarations.rows && setdata(_.orderBy(declarations.rows, ['created_at'], ['desc']));
    }
  }, [declarations, resetSearch]);

  useEffect(() => {
    const tab = [];
    if (companies !== null) {
      companies.forEach((company, index) => {
        if (company.asdId !== null) {
          tab.push(company);
        }
      });
      setcompaniesList(
        tab.map((company) => {
          return {
            key: company.id,
            value: company,
            flag: company.country.toLowerCase(),
            text: company.name,
          };
        })
      );
    }
  }, [companies]);


  const onSearchResultChange = (newData) => {
    setdata(_.orderBy(newData, ['id'], ['desc']));
  };

  const handleSort = (name) => {
    if (column !== name) {
      setcolumn(name);
      setdirection('descending');
      setdata(_.sortBy(data, [name]));
      return;
    }
    setdata(data.reverse());
    setdirection(direction === 'descending' ? 'ascending' : 'descending');
  };

  const handlePageChange = (event, { activePage }) => {
    setResetPagination(false)
    type ISession = {
      company: string;
      emitter: string;
      startDate: string;
      endDate: string;
      state: string;
      workshop: string;
      country: string;
    }
    const session: ISession = JSON.parse(sessionStorage.getItem('searchVatPage'))
    if(event){
      dispatch(actions.fiscalDeclaration.getDeclarations({
        query: {
          limit: LIMIT,
          page: Math.ceil(activePage),
          ...(session && {
            ...session.company && {company: session.company},
            ...session.emitter && {emitter: session.emitter},
            ...session.startDate && {startDate: session.startDate},
            ...session.endDate && {endDate: session.endDate},
            ...session.state && {state: session.state},
            ...session.workshop && {workshop: session.workshop},
            ...session.country && {country: session.country},
          }) || {
            ...event.company && {company: event.company},
            ...event.emitter && {emitter: event.emitter},
            ...event.startDate && {startDate: event.startDate},
            ...event.endDate && {endDate: event.endDate},
            ...event.state && {state: event.state},
            ...event.workshop && {workshop: event.workshop},
            ...event.country && {country: event.country},
          }
        }}));
        sessionStorage.setItem('declaVatPage', Math.ceil(activePage).toString());
      }
    }

    const getDeclarationsByFilters = (values) => {
      if(values){
        dispatch(actions.fiscalDeclaration.getDeclarations({
          query: { 
            limit: LIMIT,
            page: Math.ceil(1).toString(),
            ...values.company && {company: values.company},
            ...values.emitter && {emitter: values.emitter},
            ...values.startDate && {startDate: values.startDate},
            ...values.endDate && {endDate: values.endDate},
            ...values.state && {state: values.state},
            ...values.workshop && {workshop: values.workshop},
            ...values.country && {country: values.country},
          }
        }));
        sessionStorage.setItem('searchVatPage', JSON.stringify(values));
        sessionStorage.setItem('declaVatPage', Math.ceil(1).toString());
       }
    }

    const resetSearchFilters = () => {
        dispatch(actions.fiscalDeclaration.getDeclarations({
          query: {
            limit: LIMIT,
            page: Math.ceil(1).toString(),
          }
        }));
        sessionStorage.removeItem('searchVatPage');
        sessionStorage.setItem('declaVatPage', Math.ceil(1).toString());
        setResetPagination(true)
    }

  return (
    <Main>
      <Divider hidden />
      <Header size='huge'>Declaration dashboard</Header>
      <Container>
        <Wrapper display='flex' justifyContent='space-between'>
          {!error && (
            <>
            <Right>
              <Button
                labelPosition='right'
                position='left'
                icon='plus circle'
                type='button'
                content='New declaration'
                onClick={() => setmodalStatus(true)}
              />       
              <Button
                icon='filter'
                type='button'
                content='Search Filters'
                color='blue'
                onClick={() => setfilterBar(!filterBar)}
              />
            </Right>
          </>
          )}
          <Search
            dataSource={declarations}
            onSearchResultChange={onSearchResultChange}
            setResetSearch={setresetSearch}
          />
        </Wrapper>
      </Container>
      <Container>
      <Wrapper display='flex' justifyContent='space-between'>
      {filterBar && (
        <SearchFilters
          isVisible={filterBar}
          companies={companies}
          mapping={mapping}
          countries={countries}
          applyFilters={getDeclarationsByFilters}
          resetFilters={resetSearchFilters}
        />
      )}
      </Wrapper>
      </Container>

      <Divider hidden />
      {modalStatus && companiesList && (
        <NewDeclaration
          open={modalStatus}
          companiesList={companiesList}
          countries={countries}
          errorHandlerCompany={errorHandlerCompany}
          errorHandlerDeclaration={errorHandlerDeclaration}
          errorHandlerData={errorHandlerData}
          handleClose={() => setmodalStatus(false)}
        />
      )}
      {(declarations === null && (
        <Dimmer active>
          <Loader content='Loading' />
        </Dimmer>
      )) || (
        <Container>
          {`Declarations found: ${countData}`}
          <Table columns='7' sortable selectable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell
                  sorted={column === 'companyName' ? direction : null}
                  onClick={() => handleSort('companyName')}
                >
                  Company Name
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === 'date' ? direction : 'ascending'}
                  onClick={() => handleSort('date')}
                >
                  Date
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === 'country' ? direction : null}
                  onClick={() => handleSort('country')}
                >
                  Declaration Country
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === 'workshop' ? direction : null}
                  onClick={() => handleSort('workshop')}
                >
                  Workshop
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === 'emitter' ? direction : null}
                  onClick={() => handleSort('emitter')}
                >
                  Emitter
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === 'state' ? direction : null}
                  onClick={() => handleSort('state')}
                >
                  State
                </Table.HeaderCell>
                <Table.HeaderCell>Action</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {((data === null || data.length === 0) && <EmptyTable colNumber={7} />) ||
                data.map((e, i) => {
                  return (
                    <Table.Row key={i}>
                      <Table.Cell>{e.client}</Table.Cell>
                      <Table.Cell>{shortDate(new Date(e.created_at))}</Table.Cell>
                      <Table.Cell>{e.declaration_country}</Table.Cell>
                      <Table.Cell>{e.workshop}</Table.Cell>
                      <Table.Cell>{e.emitter}</Table.Cell>
                      <Table.Cell>
                        <Step.Group size='small'>
                          <Step>{e.state}</Step>
                        </Step.Group>
                      </Table.Cell>
                      <Table.Cell>
                        <LinkButton
                          disabled={
                            e.state === 'Finished' ||
                            e.state === 'Validated' ||
                            e.state === 'Correction necessary' ||
                            e.state === 'Not validated'
                              ? false
                              : true
                          }
                          content='Details'
                          color='blue'
                          path={`/fiscal/vat/declaration/${e.client}/${e.id}`}
                        />
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
            </Table.Body>
          </Table>

        {countData>0 && (
        <Wrapper textAlign='center'>
          <Pagination
            totalPages={countData / 10}
            storedPage={sessionStorage.getItem('declaVatPage') !== null ? 
              parseInt(sessionStorage.getItem('declaVatPage')) : 1}
            resetPagination={resetPage}
            onChange={handlePageChange}
          />
        </Wrapper>
        )}   
        </Container>
      )}
    </Main>
  );
};

const mapStateToProps = (state) => {
  return {
    declarations: selectors.fiscalDeclaration.declarationsSelector(state),
    companies: selectors.fiscalDeclaration.companySubscribedSelector(state),
    countries: selectors.data.allCountriesSelector(state),
    mapping: selectors.fiscalDeclaration.mappingSelector(state),
    errorHandlerData: selectors.data.errorSelector(state),
    errorHandlerDeclaration: selectors.fiscalDeclaration.errorSelector(state),
    errorHandlerCompany: selectors.company.errorSelector(state),
    error: selectors.message.errorSelector(state),
    loadingHandlerDeclaration: selectors.fiscalDeclaration.mappingLoadingSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
