// Dependencies
import React from 'react';
import { useTranslation } from 'react-i18next';

// Stylesheets
import './missions.css';

interface MissionProperties {
  missionId: number;
  reference: string;
  orderDate: string;
  company: string;
  companyId: number;
  startDate: string;
  endDate: string;
  originCountry: string;
  sector: string;
  numberOfEmployees: number;
  personInCharge: string;
  tags: any[];
}

/**
 * @name mission.tsx
 *
 * @path /components/missions/mission.tsx
 *
 * @description Defines cell's content for Missions component
 */
const Mission: React.FC<MissionProperties> = ({
  missionId,
  reference,
  orderDate,
  company,
  companyId,
  startDate,
  endDate,
  originCountry,
  sector,
  numberOfEmployees,
  personInCharge,
  tags,
}) => {
  const { t } = useTranslation();

  return (
    <div
      className='missionContainer'
      onClick={() =>
        (window.location.href = `/spw/missions/${companyId}/details/${missionId}`)
      }
    >
      <div className='missionReference'>
        <p>{reference}</p>
      </div>

      <div className='missionInformation'>
        <p>{orderDate}</p>
      </div>

      <div className='missionInformation'>
        <p>{company}</p>
      </div>

      <div className='missionInformation'>
        <p>{startDate}</p>
      </div>

      <div className='missionInformation'>
        <p>{endDate}</p>
      </div>

      <div className='missionInformation'>
        <p>{originCountry}</p>
      </div>

      <div className='missionInformation'>
        <p>
          {sector === 'Construction'
            ? t('orderPageStrings.missionSector.construction')
            : t('orderPageStrings.missionSector.other')}
        </p>
      </div>

      <div className='missionInformation'>
        <p>{numberOfEmployees}</p>
      </div>

      <div className='missionInformation'>
        <p>{personInCharge}</p>
      </div>

      <div className='missionTags'>
        {tags[5] === 1 ? (
          <div className='missionTagIsInvalid'>
            {t('missionPageStrings.tags.isInvalid')}
          </div>
        ) : (
          <>
            {tags[0] === 1 ? (
              <div className='missionTagPaid'>
                {t('missionPageStrings.tags.paid')}
              </div>
            ) : (
              <div className='missionTagUnpaid'>
                {t('missionPageStrings.tags.unpaid')}
              </div>
            )}
            {tags[1] === 1 ? (
              <div className='missionTagInspection'>
                {t('missionPageStrings.tags.inspection')}
              </div>
            ) : (
              <></>
            )}
            {tags[2] === 1 ? (
              <div className='missionTagCompleted'>
                {t('missionPageStrings.tags.completed')}
              </div>
            ) : tags[4] === 1 ? (
              <div className='missionTagHandled'>
                {t('missionPageStrings.tags.handled')}
              </div>
            ) : tags[3] === 1 ? (
              <div className='missionTagInProgress'>
                {t('missionPageStrings.tags.inProgress')}
              </div>
            ) : (
              <div className='missionTagOnHold'>
                {t('missionPageStrings.tags.onHold')}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Mission;
