// Dependencies
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-date-picker';

// Resources
import { currentLanguage } from '../../../../views';

// Stylesheets
import './forms.css';
import 'react-edit-text/dist/index.css';
import 'react-calendar/dist/Calendar.css';
import 'react-date-picker/dist/DatePicker.css';
import AddressBlock from '../addressBlock/addressBlock';
import saveLogo from '../../assets/images/logos/save.png';
import Loader from 'components/Loader';

interface LodgingMissionDetailsProperties {
  ref: React.RefObject<HTMLDivElement>;
  id: number;
  defaultStartDate: string;
  defaultEndDate: string;
  defaultType: string;
  defaultMainAddress: string;
  defaultAdditionalAddress: string;
  defaultPostalCode: string;
  defaultCity: string;
  defaultCountry: string;
}

/**
 * @name lodgingMissionDetails.tsx
 * @implements updateFormsProperties.tsx
 *
 * @path /components/updateForms/lodgingMissionDetails.tsx
 *
 * @description Defines content for update lodging information popup in MissionDetails component
 */
const LodgingMissionDetails: React.FC<LodgingMissionDetailsProperties> = ({
  ref,
  id,
  defaultStartDate,
  defaultEndDate,
  defaultType,
  defaultMainAddress,
  defaultAdditionalAddress,
  defaultPostalCode,
  defaultCity,
  defaultCountry,
}) => {
  // Parameter that must be used before each translatable string to apply the good language version
  const { t } = useTranslation();

  const [isErrorHidden, setIsErrorHidden] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const lodgingTypeOptions = [
    { id: 1, value: t('popupStrings.missionDetailsPopupStrings.apartment') },
    { id: 2, value: t('popupStrings.missionDetailsPopupStrings.camping') },
    { id: 3, value: t('popupStrings.missionDetailsPopupStrings.hotel') },
    { id: 4, value: t('popupStrings.missionDetailsPopupStrings.other') },
  ];

  // State variable to store current selected option
  const [, setCountries] = useState<any[]>([]);
  const [selectedOptionCountry, setSelectedOptionCountry] = useState<
    string | null
  >(defaultCountry);
  const [selectedOptionLodgingType, setSelectedOptionLodgingType] = useState<
    string | null
  >(defaultType);

  // Is what happening when user performed a click on the country selector
  const handleSelectChangeCountry = (value: string) => {
    setSelectedOptionCountry(value);
  };

  useEffect(() => {
    setCountries(['France']);
  }, []);

  const [lodgingStart, setLodgingStart] = useState<string>(defaultStartDate);
  const [lodgingEnd, setLodgingEnd] = useState<string>(defaultEndDate);
  const [lodgingMainAddress, setLodgingMainAddress] =
    useState<string>(defaultMainAddress);
  const [lodgingAdditionalAddress, setLodgingAdditionalAddress] =
    useState<string>(defaultAdditionalAddress);
  const [lodgingPostalCode, setLodgingPostalCode] =
    useState<string>(defaultPostalCode);
  const [lodgingCity, setLodgingCity] = useState<string>(defaultCity);

  const handleLodgingStartDateChange = (date: any) => {
    setLodgingStart(date);
  };

  const handleLodgingEndDateChange = (date: any) => {
    setLodgingEnd(date);
  };

  const handleLodgingTypeChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const newLodgingType = event.target.value;

    setSelectedOptionLodgingType(newLodgingType);
  };

  const handleLodgingMainAddressChange = (value: string) => {
    setLodgingMainAddress(value);
  };

  const handleLodgingAdditionalAddressChange = (value: string) => {
    setLodgingAdditionalAddress(value);
  };

  const handleLodgingPostalCodeChange = (value: string) => {
    setLodgingPostalCode(value);
  };

  const handleLodgingCityChange = (value: string) => {
    setLodgingCity(value);
  };

  const isEmpty = (string: string) => {
    return string.trim() === '';
  };

  const updateLodgingDetails = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API_URL}/api/v1/spw/updateLodging/lodgingDetails`,
        {
          method: 'PATCH',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            id: id,
            startDate: lodgingStart,
            endDate: lodgingEnd,
            type: selectedOptionLodgingType,
            mainAddress: lodgingMainAddress,
            additionalAddress: lodgingAdditionalAddress,
            postalCode: lodgingPostalCode,
            city: lodgingCity,
            country: selectedOptionCountry,
          }),
        }
      );

      const result = await response.json();

      return result.data;
    } catch (error) {
      console.log('Erreur', error);
    }
  };

  const execUpdateLodgingDetails = async () => {
    setIsLoading(true);

    const result = await updateLodgingDetails();

    return result;
  };

  const handleClickSave = () => {
    if (
      lodgingStart === undefined ||
      lodgingEnd === undefined ||
      selectedOptionLodgingType === undefined ||
      isEmpty(selectedOptionLodgingType) ||
      lodgingMainAddress === undefined ||
      isEmpty(lodgingMainAddress) ||
      lodgingPostalCode === undefined ||
      isEmpty(lodgingPostalCode) ||
      lodgingCity === undefined ||
      isEmpty(lodgingCity) ||
      selectedOptionCountry === undefined ||
      isEmpty(selectedOptionCountry)
    ) {
      setIsErrorHidden(false);
    } else {
      setIsErrorHidden(true);
      execUpdateLodgingDetails();
      setIsLoading(false);
      window.location.reload();
    }
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className='detailsPopupContent' ref={ref}>
            {/* Occupancy's period */}
            <p>
              <b>
                {t('popupStrings.missionDetailsPopupStrings.occupancysPeriod')}
              </b>
            </p>

            {/* Start date */}
            <div className='missionDetailsStartDate'>
              <p>
                <b>{t('popupStrings.missionDetailsPopupStrings.startDate')}</b>
              </p>
              <DatePicker
                value={lodgingStart || ''}
                className='missionDetailsDatePicker'
                format='dd/MM/yyyy'
                onChange={handleLodgingStartDateChange}
                locale={currentLanguage.toUpperCase().split('-')[0]}
              />
            </div>

            {/* End date */}
            <div className='missionDetailsEndDate'>
              <p>
                <b>{t('popupStrings.missionDetailsPopupStrings.endDate')}</b>
              </p>
              <DatePicker
                value={lodgingEnd || ''}
                className='missionDetailsDatePicker'
                format='dd/MM/yyyy'
                onChange={handleLodgingEndDateChange}
                locale={currentLanguage.toUpperCase().split('-')[0]}
              />
            </div>

            {/* Lodging Type */}
            <div className='missionDetailsLodgingType'>
              <p>
                <b>
                  {t('popupStrings.missionDetailsPopupStrings.lodgingType')}
                </b>
              </p>
              <div className='lodgingTypeSelectorContainer'>
                <select
                  className='lodgingTypeSelector'
                  value={selectedOptionLodgingType || ''}
                  onChange={handleLodgingTypeChange}
                >
                  <option key='defaultLodgingType' value=''>
                    {t(
                      'popupStrings.missionDetailsPopupStrings.chooseALodgingType'
                    )}
                  </option>
                  {lodgingTypeOptions.map((option, index) => {
                    return (
                      <option key={option.id} value={option.id}>
                        {option.value}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>

            {/* Address */}

            <AddressBlock
              divClassName='updateAddresses'
              title={t('companyPageStrings.address')}
              initialMainAddress={defaultMainAddress}
              initialAdditionalAddress={defaultAdditionalAddress}
              initialPostalCode={defaultPostalCode}
              initialCity={defaultCity}
              initialCountry={defaultCountry}
              onSaveMainAddress={handleLodgingMainAddressChange}
              onSaveAdditionalAddress={handleLodgingAdditionalAddressChange}
              onSavePostalCode={handleLodgingPostalCodeChange}
              onSaveCity={handleLodgingCityChange}
              onSaveCountry={handleSelectChangeCountry}
              onlyFrance={true}
            />
            <div>
              <div className='space'></div>
              {isErrorHidden ? (
                <></>
              ) : (
                <div className='companyError'>
                  <p>{t('orderPageStrings.error')}</p>
                </div>
              )}
              <div className='saveButtonContainer'>
                <button className='saveButton' onClick={handleClickSave}>
                  <p>{t('popupStrings.saveText')}</p>
                  <img
                    src={saveLogo}
                    alt={t('popupStrings.saveLogo')}
                    className='saveLogo'
                  />
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default LodgingMissionDetails;
