/**
 * Dependencies
 */
import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

/**
 * TextInputProps interface defines the structure of props
 * expected by the TextInput component.
 */
interface TextInputProps extends WithTranslation {
  classname: string;
  placeholder?: string;
  initialValue: string;
  onSave: (value: string) => void;
}

/**
 * TextInputState interface defines all values
 * that can be constantly update by user as long as
 * he's on component parent page
 */
interface TextInputState {
  value: string;
}

/**
 * TextInput component displays a styled input of text type
 * that can take as parameters all necessary functionnalities
 * that EditText module gave until this component, and more since
 * operators have made some demands of update not avaible in EditText
 */
class TextInput extends Component<TextInputProps, TextInputState> {
  constructor(props: TextInputProps) {
    super(props);
    this.state = {
      value: props.initialValue,
    };

    this.handleBlur = this.handleBlur.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleBlur() {
    this.props.onSave(this.state.value);
  }

  handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'Enter') {
      this.props.onSave(this.state.value);
    }
  }

  handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ value: event.target.value });
    this.props.onSave(event.target.value);
  }

  render() {
    return (
      <input
        type='text'
        className={this.props.classname}
        placeholder={this.props.placeholder}
        value={this.state.value}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        onKeyDown={this.handleKeyDown}
        autoComplete='off'
      />
    );
  }
}

export default withTranslation()(TextInput);
