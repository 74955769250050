// Dependencies
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import CountrySelector from '../countriesSelector/countriesSelector';

// Interfaces
import OrderProperties from '../../interfaces/orderProperties';

// Resources
import { currentLanguage } from '../../../../views';
import countriesEN from '../../assets/json/countriesEN.json';
import countriesFR from '../../assets/json/countriesFR.json';

// Stylesheets
import './orderForm.css';

/**
 * @name countries.tsx
 *
 * @path /components/orderForm/orderForm.tsx
 *
 * @description Defines form's component to choose start and end countries during mission's creation
 */
const Countries: React.FC<OrderProperties> = ({ setCount }) => {
  // Parameter that must be used before each translatable string to apply the good language version
  const { t } = useTranslation();

  const countryFromRef = useRef<HTMLSelectElement>(null);
  const optionsRef = useRef<HTMLOptionElement>(null);

  // Setters for dropdown button's visibility
  const [, setIsOpenCountryFrom] = useState(false);
  const [, setIsOpenCountryTo] = useState(false);

  // Create a state variable to store the selected option
  const [selectedOptionCountryFrom, setSelectedOptionCountryFrom] =
    useState<string>('');
  const [selectedOptionCountryTo, setSelectedOptionCountryTo] =
    useState<string>('');
  const [selectedOptionCountryFromText, setSelectedOptionCountryFromText] =
    useState<string | null>(null);
  const [selectedOptionCountryToText, setSelectedOptionCountryToText] =
    useState<string | null>(null);
  const [countriesFrom, setCountriesFrom] = useState<any[]>([]);
  const [countriesTo, setCountriesTo] = useState<any[]>([]);

  // Reloader
  const handleIncrement = () => {
    setCount((prevCount) => prevCount + 1);
  };

  // Attach an event handler to update the selected option when the user makes a selection
  const handleSelectChangeCountryFrom = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const newSelectedOptionCountry = event.target.value;
    const newSelectedOptionCountryText =
      event.target.options[event.target.selectedIndex].text;

    console.log(selectedOptionCountryFromText);
    console.log(selectedOptionCountryToText);

    setSelectedOptionCountryFrom(newSelectedOptionCountry);
    setSelectedOptionCountryFromText(newSelectedOptionCountryText);

    // Save the selected option to sessionStorage
    window.sessionStorage.setItem(
      'selectedOptionCountryFrom',
      newSelectedOptionCountry
    );
    window.sessionStorage.setItem(
      'selectedOptionCountryFromText',
      newSelectedOptionCountryText
    );
    handleIncrement();
  };

  // Attach an event handler to update the selected option when the user makes a selection
  const handleSelectChangeCountryTo = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const newSelectedOptionCountry = event.target.value;
    const newSelectedOptionCountryText =
      event.target.options[event.target.selectedIndex].text;

    setSelectedOptionCountryTo(newSelectedOptionCountry);
    setSelectedOptionCountryToText(newSelectedOptionCountryText);

    // Save the selected option to sessionStorage
    window.sessionStorage.setItem(
      'selectedOptionCountryTo',
      newSelectedOptionCountry
    );
    window.sessionStorage.setItem(
      'selectedOptionCountryToText',
      newSelectedOptionCountryText
    );
    handleIncrement();
  };

  // Retrieve the selected option from sessionStorage when the component mounts (optional)
  useEffect(() => {
    const storedSelectedOptionCountry = window.sessionStorage.getItem(
      'selectedOptionCountryFrom'
    );
    if (storedSelectedOptionCountry) {
      setSelectedOptionCountryFrom(storedSelectedOptionCountry);
    }

    const storedSelectedOptionCountryText = window.sessionStorage.getItem(
      'selectedOptionCountryFromText'
    );
    if (storedSelectedOptionCountryText) {
      setSelectedOptionCountryFromText(storedSelectedOptionCountryText);
    }
  }, []);

  // Retrieve the selected option from sessionStorage when the component mounts (optional)
  useEffect(() => {
    const storedSelectedOptionCountry = window.sessionStorage.getItem(
      'selectedOptionCountryTo'
    );
    if (storedSelectedOptionCountry) {
      setSelectedOptionCountryTo(storedSelectedOptionCountry);
    }

    const storedSelectedOptionCountryText = window.sessionStorage.getItem(
      'selectedOptionCountryToText'
    );
    if (storedSelectedOptionCountryText) {
      setSelectedOptionCountryToText(storedSelectedOptionCountryText);
    }
  }, []);

  // Defines the action that must be performed when the user clicks outside of the component
  const handleOutsideClickCountryFrom = (event: MouseEvent) => {
    if (
      countryFromRef.current &&
      optionsRef.current &&
      !countryFromRef.current.contains(event.target as Node) &&
      !optionsRef.current.contains(event.target as Node)
    ) {
      setIsOpenCountryFrom(false);
    }
  };

  // Defines the action that must be performed when the user clicks outside of the component
  const handleOutsideClickCountryTo = (event: MouseEvent) => {
    if (
      countryFromRef.current &&
      optionsRef.current &&
      !countryFromRef.current.contains(event.target as Node) &&
      !optionsRef.current.contains(event.target as Node)
    ) {
      setIsOpenCountryTo(false);
    }
  };

  // Configuration of page's current state when handleOutsideClick is performed
  useEffect(() => {
    document.addEventListener('click', handleOutsideClickCountryFrom);
    document.addEventListener('click', handleOutsideClickCountryTo);

    return () => {
      document.removeEventListener('click', handleOutsideClickCountryFrom);
      document.removeEventListener('click', handleOutsideClickCountryTo);
    };
  }, []);

  useEffect(() => {
    if (currentLanguage === 'fr') {
      setCountriesFrom(
        countriesFR.sort((a: any, b: any) => {
          return a.name.localeCompare(b.name);
        })
      );
    } else {
      setCountriesFrom(
        countriesEN.sort((a: any, b: any) => {
          return a.name.localeCompare(b.name);
        })
      );
    }

    setCountriesTo([{ name: 'France', code: 'FR' }]);
  }, []);

  return (
    <div className='orderFormCountriesContainer'>
      <div className='rowcondition'>
        <pre>
          <p id='obligatoire'>* </p>
        </pre>
        <pre>
          <p>{t('orderPageStrings.missionCountry.from')}</p>
        </pre>
        <CountrySelector
          divClassName='content-dropdown-country'
          selectorClassName='content-dropdown-button'
          selectorValue={selectedOptionCountryFrom}
          selectorOnChange={handleSelectChangeCountryFrom}
          countriesList={countriesFrom}
        />
      </div>

      <div className='rowcondition'>
        <pre>
          <p id='obligatoire'>* </p>
        </pre>
        <pre>
          <p>{t('orderPageStrings.missionCountry.to')}</p>
        </pre>
        <CountrySelector
          divClassName='content-dropdown-country'
          selectorClassName='content-dropdown-button'
          selectorValue={selectedOptionCountryTo}
          selectorOnChange={handleSelectChangeCountryTo}
          countriesList={countriesTo}
        />
      </div>
    </div>
  );
};

export default Countries;
