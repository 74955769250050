
import CompaniesMonitoring from 'views/Intrastat/Operator/CompaniesMonitoring';
import Monitoring from 'views/Intrastat/Monitoring';
import MainMonitoring from 'views/Intrastat/Operator/MainMonitoring';
import SubscribedCompaniesIntrastat from 'views/Intrastat'
import IntrastatDetail from 'views/Intrastat/IntrastatDetails';
import CompanyService from 'views/Intrastat/Operator/Service/CompanyService';
import paths from "./paths";


export default [
  {
    private: true,
    path: paths.intrastat,
    component: SubscribedCompaniesIntrastat,
    exact: true,
    authorizedRoles: [],
  },
  {
    private: true,
    path: paths.intrastatClient,
    component: SubscribedCompaniesIntrastat,
    exact: true,
    authorizedRoles: [],
  },
  {
    private: true,
    path: paths.intrastatMonitoring,
    component: MainMonitoring,
    exact: true,
    authorizedRoles: [],
  },
  {
    private: true,
    path: paths.intrastatList,
    component: Monitoring,
    exact: true,
    authorizedRoles: [],
  },
  {
    private: true,
    path: paths.intrastatCountryList,
    component: CompanyService,
    exact: true,
    authorizedRoles: [],
  },
  {
    private: true,
    path: paths.intrastatCompanyList,
    component: CompaniesMonitoring,
    exact: true,
    authorizedRoles: [],
  },
  {
    private: true,
    path: paths.companiesIntrastatDetails,
    component: IntrastatDetail,
    exact: true,
    authorizedRoles: [],
  },
  {
    private: true,
    path: paths.intrastatDetailsClient,
    component: IntrastatDetail,
    exact: true,
    authorizedRoles: [],
  },
  {
    private: true,
    path: paths.intrastatDetails,
    component: IntrastatDetail,
    exact: true,
    authorizedRoles: [],
  },
  {
    private: true,
    path: paths.intrastatDetails,
    companent: IntrastatDetail,
    exact: true,
    authorizedRoles: [],
  },
];
