// Dependencies
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-date-picker';

// Components
import Loader from 'components/Loader';
import CountrySelector from '../countriesSelector/countriesSelector';
import TextInput from '../textInput/textInput';
import AddressBlock from '../addressBlock/addressBlock';

// Interfaces
import UpdateGlobalEmployeeDetailsProperties from '../../interfaces/updateGlobalEmployeeDetailsProperties';

// Resources
import { currentLanguage } from '../../../../views';
import countriesEN from '../../assets/json/countriesEN.json';
import countriesFR from '../../assets/json/countriesFR.json';
import saveLogo from '../../assets/images/logos/save.png';

// Stylesheets
import './forms.css';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';

const GlobalEmployeeDetails: React.FC<
  UpdateGlobalEmployeeDetailsProperties
> = ({
  ref,
  employeeId,
  defaultCivility,
  defaultFirstName,
  defaultLastName,
  defaultNationality,
  defaultDateOfBirth,
  defaultPlaceOfBirth,
  defaultCountryOfBirth,
  defaultMainAddress,
  defaultAdditionalAddress,
  defaultPostalCode,
  defaultCity,
  defaultCountry,
}) => {
  // Parameter that must be used before each translatable string to apply the good language version
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isErrorHidden, setIsErrorHidden] = useState<boolean>(true);

  const [civility, setCivility] = useState<string>(defaultCivility);
  const [civilityText, setCivilityText] = useState<string>();
  const [firstName, setFirstName] = useState<string>(defaultFirstName);
  const [lastName, setLastName] = useState<string>(defaultLastName);
  const [nationality, setNationality] = useState<string>(defaultNationality);
  const [dateOfBirth, setDateOfBirth] = useState<string>(defaultDateOfBirth);
  const [placeOfBirth, setPlaceOfBirth] = useState<string>(defaultPlaceOfBirth);
  const [countryOfBirth, setCountryOfBirth] = useState<string | undefined>(
    defaultCountryOfBirth
  );
  const [mainAddress, setMainAddress] = useState<string>(defaultMainAddress);
  const [additionalAddress, setAdditionalAddress] = useState<
    string | undefined
  >(defaultAdditionalAddress);
  const [postalCode, setPostalCode] = useState<string>(defaultPostalCode);
  const [city, setCity] = useState<string>(defaultCity);
  const [country, setCountry] = useState<string | undefined>(defaultCountry);

  const handleCivilityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCivility(event.target.value);

    if (currentLanguage === 'fr') {
      if (event.target.value === '1') {
        setCivilityText('M');
      } else {
        setCivilityText('Mme');
      }
    } else {
      if (event.target.value === '1') {
        setCivilityText('Mr');
      } else {
        setCivilityText('Mrs');
      }
    }
  };

  const handleFirstNameChange = (value: string) => {
    setFirstName(value);
  };

  const handleLastNameChange = (value: string) => {
    setLastName(value);
  };

  const handleNationalityChange = (value: string) => {
    setNationality(value);
  };

  const handleDateOfBirthChange = (date: any) => {
    setDateOfBirth(date);
  };

  const handlePlaceOfBirthChange = (value: string) => {
    setPlaceOfBirth(value);
  };

  const handleCountryOfBirthChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setCountryOfBirth(event.target.value);
  };

  const handleMainAddressChange = (value: string) => {
    setMainAddress(value);
  };

  const handleAdditionalAddressChange = (value: string) => {
    setAdditionalAddress(value);
  };

  const handlePostalCodeChange = (value: string) => {
    setPostalCode(value);
  };

  const handleCityChange = (value: string) => {
    setCity(value);
  };

  const handleCountryChange = (value: string) => {
    setCountry(value);
  };

  // Options setters
  const [countries, setCountries] = useState<any[]>([]);

  // Civility options
  const civilityOptions = [
    {
      id: '1',
      label: t('employeesPopupPageStrings.mr'),
    },
    {
      id: '2',
      label: t('employeesPopupPageStrings.mrs'),
    },
  ];

  useEffect(() => {
    if (currentLanguage === 'fr') {
      setCountries(
        countriesFR.sort((a: any, b: any) => {
          return a.name.localeCompare(b.name);
        })
      );
    } else {
      setCountries(
        countriesEN.sort((a: any, b: any) => {
          return a.name.localeCompare(b.name);
        })
      );
    }

    if (currentLanguage === 'fr') {
      if (defaultCivility === '1') {
        setCivilityText('M');
      } else {
        setCivilityText('Mme');
      }
    } else {
      if (defaultCivility === '1') {
        setCivilityText('Mr');
      } else {
        setCivilityText('Mrs');
      }
    }
  }, []);

  const updateEmployeeDetails = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API_URL}/api/v1/spw/updateEmployee/employeeDetails`,
        {
          method: 'PATCH',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            id: employeeId,
            civility: civilityText,
            firstName: firstName,
            lastName: lastName,
            nationality: nationality,
            dateOfBirth: dateOfBirth,
            placeOfBirth: placeOfBirth,
            countryOfBirth: countryOfBirth,
            mainAddress: mainAddress,
            additionalAddress: additionalAddress,
            postalCode: postalCode,
            city: city,
            country: country,
          }),
        }
      );

      const result = await response.json();

      return result.data;
    } catch (error) {
      console.log('Erreur', error);
    }
  };

  const execUpdateEmployeeDetails = async () => {
    setIsLoading(true);

    const result = await updateEmployeeDetails();

    return result;
  };

  const isEmpty = (string: string) => {
    return string.trim() === '';
  };

  const handleClickSave = () => {
    if (
      firstName === undefined ||
      lastName === undefined ||
      nationality === undefined ||
      placeOfBirth === undefined ||
      countryOfBirth === undefined ||
      mainAddress === undefined ||
      postalCode === undefined ||
      city === undefined ||
      country === undefined ||
      isEmpty(firstName) ||
      isEmpty(lastName) ||
      isEmpty(nationality) ||
      isEmpty(placeOfBirth) ||
      isEmpty(countryOfBirth) ||
      isEmpty(mainAddress) ||
      isEmpty(postalCode) ||
      isEmpty(city) ||
      isEmpty(country)
    ) {
      setIsErrorHidden(false);
    } else {
      setIsErrorHidden(true);
      execUpdateEmployeeDetails();
      setIsLoading(false);
      window.location.reload();
    }
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className='createEmployeePopup' ref={ref}>
            <div className='civilityBlock'>
              <p>
                <b>{t('employeesPopupPageStrings.civility')}</b>
              </p>
              {civilityOptions.map((civilityOption) => (
                <div key={civilityOption.id} className='civilityOptionsLabel'>
                  <label key={civilityOption.id}>
                    <input
                      id={civilityOption.id}
                      type='radio'
                      name='civilityOption'
                      value={civilityOption.id}
                      checked={civility === civilityOption.id}
                      onChange={handleCivilityChange}
                    />
                    {civilityOption.label}
                  </label>
                </div>
              ))}
            </div>
            <div className='namesAndNationalityBlock'>
              <div className='namesAndNationalityBlockFields'>
                <p>
                  <b>{t('employeesPopupPageStrings.firstName')}</b>
                </p>
                <TextInput
                  classname='firstName'
                  initialValue={firstName}
                  onSave={handleFirstNameChange}
                />
              </div>
              <div className='namesAndNationalityBlockFields'>
                <p>
                  <b>{t('employeesPopupPageStrings.lastName')}</b>
                </p>
                {/* <div className='secondBlockFieldsEditText'>
                  <EditText
                    defaultValue={lastName}
                    onSave={handleLastNameChange}
                  />
                </div> */}
                <TextInput
                  classname='lastName'
                  initialValue={lastName}
                  onSave={handleLastNameChange}
                />
              </div>
              <div className='namesAndNationalityBlockFields'>
                <p>
                  <b>{t('employeesPopupPageStrings.nationality')}</b>
                </p>
                {/* <div className='secondBlockFieldsEditText'>
                  <EditText
                    defaultValue={nationality}
                    onSave={handleNationalityChange}
                  />
                </div> */}
                <TextInput
                  classname='nationality'
                  initialValue={nationality}
                  onSave={handleNationalityChange}
                />
              </div>
            </div>
            <div className='birthBlock'>
              <div className='birthBlockFields'>
                <p>
                  <b>{t('employeesPopupPageStrings.dateOfBirth')}</b>
                </p>
                <DatePicker
                  value={dateOfBirth}
                  className='selectorBirthDate'
                  format='dd/MM/yyyy'
                  onChange={handleDateOfBirthChange}
                  locale={currentLanguage.toUpperCase().split('-')[0]}
                />
              </div>
              <div className='birthBlockFields'>
                <p>
                  <b>{t('employeesPopupPageStrings.placeOfBirth')}</b>
                </p>
                {/* <div className='thirdBlockFieldsEditText'>
                  <EditText
                    defaultValue={placeOfBirth}
                    onSave={handlePlaceOfBirthChange}
                  />
                </div> */}
                <TextInput
                  classname='birthPlace'
                  initialValue={placeOfBirth}
                  onSave={handlePlaceOfBirthChange}
                />
              </div>
              <div className='birthBlockFields'>
                <p>
                  <b>{t('employeesPopupPageStrings.countryOfBirth')}</b>
                </p>
                <CountrySelector
                  divClassName='selectorBirthCountryDiv'
                  selectorClassName='selectorBirthCountry'
                  selectorValue={countryOfBirth}
                  selectorOnChange={handleCountryOfBirthChange}
                  countriesList={countries}
                />
              </div>
            </div>
            <AddressBlock
              divClassName='createEmployeeAddressBlock'
              title={t('employeesPopupPageStrings.addressTitle')}
              initialMainAddress={mainAddress}
              initialAdditionalAddress={additionalAddress}
              initialPostalCode={postalCode}
              initialCity={city}
              initialCountry={country}
              onSaveMainAddress={handleMainAddressChange}
              onSaveAdditionalAddress={handleAdditionalAddressChange}
              onSavePostalCode={handlePostalCodeChange}
              onSaveCity={handleCityChange}
              onSaveCountry={handleCountryChange}
            />
          </div>
          <div>
            <div className='space'></div>
            {isErrorHidden ? (
              <></>
            ) : (
              <div className='companyError'>
                <p>{t('orderPageStrings.error')}</p>
              </div>
            )}
            <div className='saveButtonContainer'>
              <button className='saveButton' onClick={handleClickSave}>
                <p>{t('popupStrings.saveText')}</p>
                <img
                  src={saveLogo}
                  alt={t('popupStrings.saveLogo')}
                  className='saveLogo'
                />
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default GlobalEmployeeDetails;
