/**
 * Depenencies
 */
import React, { Component } from 'react';
import Markdown from 'markdown-to-jsx';
import { withTranslation, WithTranslation } from 'react-i18next';

/**
 * Resources
 */
import './patchNotes.css';

/**
 *
 */
interface PatchNotesProps extends WithTranslation {}

/**
 *
 */
class PatchNotes extends Component<PatchNotesProps> {
  render() {
    const markdownContent = `
# Changelog

## [1.2.0] - 21/01/2025

### Added

* Add country of origin and order date in missions list.
* Add some tags for each mission in missions list.
* Add some translations.
* Add new operator buttons to update missions tags.

### Changed

* Refactor mission slot in missions list.
* Refactor some tags for each missions in missions list.
* Refactor dashboardButton.
* Refactor dashboardInformation.

### Fixed

* Fix url redirection when creating a mission.
* Fix some bugs and warnings.

## [1.1.0] - 31/12/2024

### Added

* Tooltips for SIPSI declarations and construction cards.
* Possibility to upload Excel files (.xlsx, xls, .csv, .ods) to a mission in addition to pdf files.
* Patch notes page.
* Add a new module for pagination (react-paginate@^7.1.3 to package.json).
* Add a new download icon (white version).
* Add some translations for invoices list page.
* Add everything that's necessary to have a new invoices list page, that displays all invoices related to a company.
* Add invoices page in mission details, that open a list of invoices related to the current mission.
* Add a new utils folder in project, to save all functions that can be used everywhere in the project.
* Add five new utils functions: sleep, formattedDate, downloadFromS3, downloadZipFromS3, and translatedCountryName.

### Changed

* Dashboard information are now clickable.
* Update translations for companies list page.
* Update companies list page.
* Update addresses endpoints.

### Fixed

* Country code to display as title of NavbarDropdownLanguage button.
* Country {"Serbia and Montenegro", "CS"} into countries {"Serbia", "RS"} and {"Montenegro", "ME"}.
* Fix mission details url (no more based on reference, but on mission id).
* Fix dashboard requests to fit with refactor on backoffice.
* Fix many minor issues.

## [1.0.0] - 09/10/2024

### Added

* Initial release of the application.


    `;
    return (
      <div className='patchNotesContainer'>
        <Markdown>{markdownContent}</Markdown>
      </div>
    );
  }
}

export default withTranslation()(PatchNotes);
