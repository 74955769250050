import * as Yup from 'yup';
import config from 'config';

const customsDocuments = Yup.mixed()
  .test('fileType', 'Unsupported file format', (value) => {
    if (value) {
      const splitedName = value.name.split('.');
      return config.file.customsDocuments.fileType.includes(
        splitedName[splitedName.length - 1].toLowerCase()
      );
    }
  })
  .test('fileSize', 'File is too large', (value) => {
    return value && value.size < config.file.customsDocuments.fileSize;
  })
  .required('Required');

export const newAccises = Yup.object().shape({
  type: Yup.string().oneOf(['accises']).required(),
  clientReference: Yup.string().required(), 
  departure: Yup.string().required(),
  arrival: Yup.string().required(),
  startDate: Yup.string().required(),
  endDate: Yup.string().required(),
})

export const newMailFollowers = Yup.array()

export const customsDeclaration = Yup.object().shape({
  type: Yup.string()
    .oneOf(['customsClearance', 'traces', 'customsClerk', 'customsOnly', 'accises', 'mandat', 'transit'])
    .required('Required'),
  sivep: Yup.boolean().when('type', {
    is: 'customsClearance',
    then: Yup.boolean().required('Required'),
  }).when('type', {
    is: 'traces',
    then: Yup.boolean().required('Required'),
  }),
  truckLicensePlate: Yup.string().required('Required'),
  trailerLicensePlate: Yup.string().required('Required'),
  truckOrigin: Yup.string().required('Required'),
  trailerOrigin: Yup.string().required('Required'),
  customsOffice: Yup.number().required('Required'),
  fortyTwoRegime: Yup.boolean(),
  indRepresentation: Yup.boolean(), 
  permissions: Yup.array(),
  purchaseNumber: Yup.string(),
  estimatedDateArrival: Yup.date().required('Required'),
  estimatedTimeArrival: Yup.string().required('Required'),
  meanTimeOffset: Yup.number(),
  transporter: Yup.object().shape({
    name: Yup.string(),
    id: Yup.number(),
  }).nullable(null),
  driver: Yup.number(),
  transporterPhoneNumber: Yup.string(),
  crossingStatus: Yup.string(),
  documents: Yup.array().of(
    Yup.object().shape({
      file: customsDocuments,
      id: Yup.number().required('Required'),
      number: Yup.string().nullable(true),
    })
  ),
  customsTrade: Yup.array()
});

export const arrayOfCustomsDocuments = Yup.object().shape({
  documents: Yup.array()
    .of(
      Yup.object()
        .shape({
          file: customsDocuments,
          id: Yup.number().min(1).required('Required'),
          reference: Yup.string().nullable(true)
        })
        .nullable(true)
    )
    .min(1)
    .required('Required'),
    type: Yup.number().required('Required'),
});

export const reasonForCancel = Yup.object().shape({
  reason: Yup.string().required('Required'),
});

export const addLines = Yup.object().shape({
  action: Yup.string().required('required'),
  comments: Yup.string().required('required'),
  service: Yup.object().shape({
    description: Yup.string(),
    id: Yup.number(),
    price: Yup.number().nullable(true),
  }).required('Required'),
  quantity: Yup.number().required('Required'),
  addTo: Yup.boolean()
});

export const accises = Yup.object().shape({
  category: Yup.string().required('required'),
  comments: Yup.string().required('required'),
  quantity: Yup.number().required('Required'),
  cost: Yup.number().required('Required'),
});

export const updateDeclaration = Yup.object().shape({
  currentCompanyId: Yup.number().required(),
  Company_Id: Yup.string().nullable(true),
  transporterPhoneNumber: Yup.string(),
  truckLicensePlate: Yup.string().required('Required'),
  truckOrigin: Yup.string().required('Required'),
  trailerLicensePlate: Yup.string().required('Required'),
  trailerOrigin: Yup.string().required('Required'),
  customsOffice: Yup.string().required('Required'),
  estimatedDateArrival: Yup.string().required('Required'),
  estimatedTimeArrival: Yup.string().required('Required'),
  fortyTwoRegime: Yup.string(),
  indRepresentation: Yup.string(),
  transporter: Yup.string(),
  purchaseNumber: Yup.string(),
  crossingStatus: Yup.string(),
  permissions: Yup.array(),
  intranetRef: Yup.string().nullable(true),
});

export const updateIntrastat = Yup.object().shape({
  period: Yup.string().required('Required'),
  filingCountry: Yup.string().required('Required'),
  declarationType: Yup.object().shape({
    id: Yup.number().required(), 
    name: Yup.string().required('Required')
  }), 
  flow: Yup.string().required('Required'),
  clientReference: Yup.string(),
});


export const updateTransit = Yup.object().shape({
  preTaxInvoice: Yup.number().required('Required'),
  customsDutyRate: Yup.number().required('Required'),
  transitVatRate: Yup.number().required('Required'),
  accisesTaxAmount: Yup.number().nullable(true), 
  departure: Yup.string().required('Required'),
  arrival: Yup.string().required('Required'),
  startDate: Yup.string().required('Required'),
  endDate: Yup.string().required('Required'),
  estimatedTimeDeparture: Yup.string().required('Required'),
  customsOffice: Yup.number().nullable(true),
  purchaseNumber: Yup.string(),
  transporter: Yup.string(),
  transporterPhoneNumber: Yup.string().required('Required'),
  clientReference: Yup.string(),
  vatRate: Yup.string().nullable(true)
});

export const updateAccises = Yup.object().shape({
  clientReference: Yup.string().required('Required'),
  departure: Yup.string().required('Required'),
  arrival: Yup.string().required('Required'),
  startDate: Yup.string().required('Required'),
  endDate: Yup.string().required('Required'),
});

export const updateVat = Yup.object().shape({
  taxPeriod: Yup.string().required('Required'),
  country: Yup.object().shape({
    iso: Yup.string().required('Required'), 
    periodicity: Yup.string().required('Required')
  }),
  clientReference: Yup.string().required('Required'), 
});


export const monitoringFiltersSchema = Yup.object().shape({
  id: Yup.string(),
  company: Yup.number(),
  startDate: Yup.string(),
  endDate: Yup.string(),
  licensePlate: Yup.string(),
  customsOffices: Yup.number(),
  intranetReference: Yup.string(),
  clientReference: Yup.string(),
  purchaseNumber: Yup.string(),
  dsceNumber: Yup.string(),
  showAll: Yup.boolean(),
});

export const vatDeclarationFiltersSchema = Yup.object().shape({
  startDate: Yup.string(),
  endDate: Yup.string(),
  company: Yup.string(),
  workshop: Yup.string(),
  emitter: Yup.string(),
  state: Yup.string(),
  showAll: Yup.boolean(),
});

export const statisticsFiltersSchema = Yup.object().shape({
  period: Yup.string().nullable(true),
  company: Yup.number().nullable(true),
  startDate: Yup.string(),
  endDate: Yup.string(),
  showAll: Yup.boolean().nullable(true),
});

export const extractDocumentsSchema = Yup.object().shape({
  startDate: Yup.string().required('Required'),
  endDate: Yup.string().required('Required'),
  dau: Yup.boolean(),
  dauBae: Yup.boolean(),
});
