/**
 * Dependencies.
 */
import React, { Component, ReactNode } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import ReactPaginate from 'react-paginate';

/**
 * Resources.
 */
import './invoicesList.css';
import downArrowLogo from '../../assets/images/logos/downArrow.png';
import upArrowLogo from '../../assets/images/logos/upArrow.png';
import downloadLogo from '../../assets/images/logos/downloadWhite.png';
import Loader from '../../../../components/Loader';
import TextInput from '../textInput/textInput';
import { formattedDate } from 'spw/src/utils/formattedDate';
import { downloadFromS3 } from 'spw/src/utils/downloadFromS3';
import { downloadZipFromS3 } from 'spw/src/utils/downloadZipFromS3';
import { sleep } from 'spw/src/utils/sleep';

/**
 * InvoicesListProps defines the structure of props
 * expected by the InvoicesList component.
 */
interface InvoicesListProps extends WithTranslation {
  viewAll: boolean;
  missionId?: number;
}

/**
 * InvoicesListState defines all state variables
 * needed by InvoicesList component.
 */
interface InvoicesListState {
  isLoading: boolean;
  researchTerm: string;
  invoicesList: InvoiceData[];
  filteredInvoicesList: InvoiceData[];
  error: string | null;
  companyId: number;
  itemOffset: number;
  itemsPerPage: number;
  isButtonPressed: boolean;
  referenceAscending: boolean;
  dateAscending: boolean;
}

/**
 * InvoiceData defines properties from database
 * to display in InvoicesList.
 */
interface InvoiceData {
  reference: string;
  date: string;
  s3Path: string;
}

/**
 * InvoicesList component displays a styled list of all
 * invoices related to a company.
 */
class InvoicesList extends Component<InvoicesListProps, InvoicesListState> {
  constructor(props: InvoicesListProps) {
    super(props);
    let initCompanyId = 0;
    if (this.props.viewAll) {
      initCompanyId = Number(
        window.location.href.split('/').filter(Boolean).pop()
      );
    } else {
      initCompanyId = Number(window.location.href.split('/')[5]);
    }
    this.state = {
      isLoading: true,
      researchTerm: '',
      invoicesList: [],
      filteredInvoicesList: [],
      error: null,
      companyId: initCompanyId,
      itemOffset: 0,
      itemsPerPage: 5,
      isButtonPressed: true,
      referenceAscending: false,
      dateAscending: false,
    };

    this.setResearchTerm = this.setResearchTerm.bind(this);
    this.setReferenceAscending = this.setReferenceAscending.bind(this);
    this.setDateAscending = this.setDateAscending.bind(this);
    this.setItemsPerPageBy5 = this.setItemsPerPageBy5.bind(this);
    this.setItemsPerPageBy10 = this.setItemsPerPageBy10.bind(this);
  }

  researchInvoiceByReference() {
    const filteredInvoices = this.state.invoicesList.filter((invoice) =>
      invoice.reference
        .toLowerCase()
        .includes(this.state.researchTerm.toLowerCase())
    );
    this.setState({ filteredInvoicesList: filteredInvoices });
  }

  async filterInvoicesByReference() {
    this.setState({ isLoading: true });
    const filteredInvoices = this.state.invoicesList.sort(
      (invoice1, invoice2) => {
        const reference1 = invoice1.reference;
        const reference2 = invoice2.reference;
        return this.state.referenceAscending
          ? reference1.localeCompare(reference2)
          : reference2.localeCompare(reference1);
      }
    );
    this.setState({ filteredInvoicesList: filteredInvoices });
    await sleep(100);
    this.setState({ isLoading: false });
  }

  async filterInvoicesByDate() {
    this.setState({ isLoading: true });
    const filteredInvoices = this.state.invoicesList.sort(
      (invoice1, invoice2) => {
        const date1 = new Date(invoice1.date).getTime();
        const date2 = new Date(invoice2.date).getTime();
        return this.state.dateAscending ? date1 - date2 : date2 - date1;
      }
    );
    this.setState({ filteredInvoicesList: filteredInvoices });
    await sleep(100);
    this.setState({ isLoading: false });
  }

  setResearchTerm(value: string) {
    this.setState({ researchTerm: value }, this.researchInvoiceByReference);
  }

  setReferenceAscending() {
    this.setState(
      (prevState) => ({
        referenceAscending: !prevState.referenceAscending,
      }),
      this.filterInvoicesByReference
    );
  }

  setDateAscending() {
    this.setState(
      (prevState) => ({
        dateAscending: !prevState.dateAscending,
      }),
      this.filterInvoicesByDate
    );
  }

  setItemsPerPageBy5() {
    this.setState({ itemsPerPage: 5, isButtonPressed: true });
  }

  setItemsPerPageBy10() {
    this.setState({ itemsPerPage: 10, isButtonPressed: false });
  }

  async getAllInvoicesByCompanyId() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API_URL}/api/v1/spw/invoice/getAllByCompanyId`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ id: this.state.companyId }),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const jsonResponse = await response.json();

      let invoices = [];

      if (JSON.stringify(jsonResponse) !== '{}') {
        const formattedJsonResponse = jsonResponse.data.map((obj) => [
          obj.invoiceId,
          obj.reference,
          obj.date,
          obj.missionId,
          obj.billerId,
          obj.companyId,
          obj.s3Path,
        ]);

        if (this.props.viewAll) {
          invoices = formattedJsonResponse.map((invoice: InvoiceData[]) => ({
            reference: invoice[1],
            date: invoice[2],
            s3Path: invoice[6],
          }));
        } else {
          invoices = formattedJsonResponse
            .filter(
              (invoice: InvoiceData) => invoice[3] == this.props.missionId
            )
            .map((invoice: InvoiceData[]) => ({
              reference: invoice[1],
              date: invoice[2],
              s3Path: invoice[6],
            }));
        }
      }

      this.setState({
        isLoading: false,
        invoicesList: invoices,
        filteredInvoicesList: invoices,
      });
      this.filterInvoicesByDate();
    } catch (error: any) {
      this.setState({ isLoading: false, error: error.message });
      console.log(error.message);
    }
  }

  async downloadInvoiceFromS3(filePath: string) {
    this.setState({ isLoading: true });
    await downloadFromS3(filePath).then(() =>
      this.setState({ isLoading: false })
    );
  }

  componentDidMount(): void {
    this.getAllInvoicesByCompanyId();
  }

  async downloadAllInvoicesFromS3(invoicesList: string[]) {
    this.setState({ isLoading: true });
    await downloadZipFromS3(invoicesList).then(() =>
      this.setState({ isLoading: false })
    );
  }

  render(): ReactNode {
    const {
      isLoading,
      researchTerm,
      filteredInvoicesList,
      itemOffset,
      itemsPerPage,
      isButtonPressed,
      referenceAscending,
      dateAscending,
    } = this.state;

    /**
     * Extract the `t` function from `useTranslation` hook.
     */
    const { t } = this.props;

    const endOffset = itemOffset + itemsPerPage;
    const currentItems = filteredInvoicesList.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(filteredInvoicesList.length / itemsPerPage);

    const headers = [
      {
        id: 1,
        text: t('invoicesPageStrings.headers.reference'),
        classname: 'invoicesListItemHeader',
        filter: referenceAscending,
        action: () => this.setReferenceAscending(),
      },
      {
        id: 2,
        text: t('invoicesPageStrings.headers.date'),
        classname: 'invoicesListItemHeader',
        filter: dateAscending,
        action: () => this.setDateAscending(),
      },
      {
        id: 3,
        text: t('invoicesPageStrings.headers.actions'),
        classname: 'invoicesListDownloadButtonHeader',
        filter: 'none',
        action: () => {},
      },
    ];

    function handlePageClick(event) {
      const newOffset =
        (event.selected * itemsPerPage) % filteredInvoicesList.length;
      console.log(
        `User requested page number ${event.selected}, which is offset ${newOffset}`
      );
      this.setState({ itemOffset: newOffset });
    }

    return (
      <>
        {isLoading && <Loader />}
        <div className='invoicesListComponentContainer'>
          <div className='invoicesListGeneralActionsContainer'>
            <TextInput
              classname='invoicesListResearchTextInput'
              placeholder={t('invoicesPageStrings.searchPlaceholder')}
              initialValue={researchTerm}
              onSave={this.setResearchTerm}
            />

            <button
              className='invoicesListDownloadButton'
              onClick={async () =>
                this.downloadAllInvoicesFromS3(
                  this.state.filteredInvoicesList.map(
                    (invoice) => invoice.s3Path
                  )
                )
              }
            >
              <div>{t('invoicesPageStrings.downloadAll')}</div>
              <img
                src={downloadLogo}
                alt='Download logo'
                className='invoicesListDownloadLogo'
              />
            </button>
          </div>
          <div className='invoicesListHeaderContainer'>
            {headers.map((header) =>
              header.filter === 'none' ? (
                <div key={header.id} className={header.classname}>
                  {header.text}
                </div>
              ) : (
                <div key={header.id} className={header.classname}>
                  {header.text}
                  {header.filter ? (
                    <img
                      src={upArrowLogo}
                      alt='upArrowLogo'
                      className='invoicesListFilterLogos'
                      onClick={header.action}
                    />
                  ) : (
                    <img
                      src={downArrowLogo}
                      alt='downArrowLogo'
                      className='invoicesListFilterLogos'
                      onClick={header.action}
                    />
                  )}
                </div>
              )
            )}
          </div>
          <div className='invoicesListContainer'>
            {currentItems.length === 0 ? (
              <div className='noInvoicesListItem'>
                <p>{t('invoicesPageStrings.noInvoices')}</p>
              </div>
            ) : (
              currentItems.map((invoice, index) => (
                <div key={index} className='invoicesListItem'>
                  <div className='invoicesListItemColumn'>
                    {invoice.reference}
                  </div>
                  <div className='invoicesListItemColumn'>
                    {formattedDate(invoice.date)}
                  </div>
                  <div className='invoicesListDownloadButtonContainer'>
                    <button
                      className='invoicesListDownloadButton'
                      onClick={async () =>
                        this.downloadInvoiceFromS3(invoice.s3Path)
                      }
                    >
                      <div>{t('invoicesPageStrings.download')}</div>
                      <img
                        src={downloadLogo}
                        alt='Download logo'
                        className='invoicesListDownloadLogo'
                      />
                    </button>
                  </div>
                </div>
              ))
            )}
          </div>
          <div className='invoicesListFooterContainer'>
            {currentItems.length !== 0 && (
              <>
                <div className='paginate'>
                  <ReactPaginate
                    nextLabel={t('invoicesPageStrings.nextButton')}
                    onPageChange={handlePageClick.bind(this)}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={2}
                    pageCount={pageCount}
                    previousLabel={t('invoicesPageStrings.previousButton')}
                    pageClassName='pageItem'
                    pageLinkClassName='pageLink'
                    previousClassName='pageItem'
                    previousLinkClassName='pageLink'
                    nextClassName='pageItem'
                    nextLinkClassName='pageLink'
                    breakLabel='...'
                    breakClassName='pageItem'
                    breakLinkClassName='pageLink'
                    containerClassName='pagination'
                    activeClassName='active'
                    renderOnZeroPageCount={null}
                  />
                </div>
                <div className='itemsPerPage'>
                  <div>{t('invoicesPageStrings.invoicesPerPage')}</div>
                  <div>
                    <button
                      className={
                        isButtonPressed
                          ? 'itemsPerPageButtonPressed'
                          : 'itemsPerPageButton'
                      }
                      onClick={this.setItemsPerPageBy5}
                    >
                      5
                    </button>
                    <button
                      className={
                        isButtonPressed
                          ? 'itemsPerPageButton'
                          : 'itemsPerPageButtonPressed'
                      }
                      onClick={this.setItemsPerPageBy10}
                    >
                      10
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation()(InvoicesList);
