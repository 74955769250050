import styled from "@emotion/styled";
import { Form } from "semantic-ui-react";

export const Wrapper = styled.div(({ margin }: any) => ({
  margin: "0",
  display: "inline-block"
}));

export const Field = styled(Form.Field)`
  &&& {
    margin: 0;
    display: inline-block;
  }
`;

export const Right = styled.div(() => ({
  float: 'left',
  display: 'flex',
  alignItems: 'column'
}))

