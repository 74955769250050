import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Button as Btn, Container, Divider, Table} from 'semantic-ui-react';
import { actions, selectors } from 'store';
import LinkButton from 'components/core/LinkButton/LinkButton';
import TableSpacedRows from 'components/TableSpacedRows';
import { Main } from 'components/Layouts';
import Loader from 'components/Loader';
import EmptyTable from 'components/EmptyTableCurve';
import Wrapper from 'components/core/Wrapper/Wrapper';
import TextValidationModal from 'components/TextValidationModal';
import Search from 'components/Search';

const Index = ({
  dispatch,
  companies,
  userCompanies,
  user,
}) => {
  const [column, setcolumn] = useState(null);
  const [direction, setdirection] = useState(null);
  const [data, setdata] = useState(null);
  const [resetSearch, setresetSearch] = useState(false);
  const delRef: any = useRef()

  useEffect(() => {
    dispatch(actions.company.reset(['error']));
    user && console.log(user)
    if (user.admin || user.operator) {
      dispatch(actions.company.getAll());
      dispatch(actions.socket.subscribeUpdateCompany());
    } else {
      dispatch(
        actions.companyUser.getUserCompanies({
          params: { userId: user.id },
        })
      );
      dispatch(actions.socket.subscribeUpdateUserCompany(user.id));
    }
    return () => {
      dispatch(actions.company.reset(['companies', 'error']));
      dispatch(actions.companyUser.reset(['userCompanies', 'error']));
      if (user.admin || user.operator)
        dispatch(actions.socket.unsubscribeUpdateCompany());
      else dispatch(actions.socket.unsubscribeUpdateUserCompany(user.id));
    };
  }, [dispatch, user]);

  useEffect(() => {
    const companyToUse =
      ((user.admin || user.operator) && companies) ||
      (!user.admin && !user.operator && userCompanies);
    setdata(_.orderBy(companyToUse, ['name'], ['asc']));
    resetSearch && setresetSearch(false);
  }, [companies, userCompanies, user, resetSearch]);

  const onSearchResultChange = (newData) => {
    setdata(_.orderBy(newData, ['name'], ['asc']));
  };

  const handleDeleteCompany = (companyId) => {
    if(delRef && delRef.current){
      delRef.current.value = ''
    }
    dispatch(actions.company.remove({
      params: {
        companyId: companyId
      }
    }))
  }

  const handleSort = (name) => {
    if (column !== name) {
      setcolumn(name);
      setdirection('descending');
      setdata(_.sortBy(data, [name]));
      return;
    }
    setdata(data.reverse());
    setdirection(direction === 'descending' ? 'ascending' : 'descending');
  };

  const cellCss = {'width': '100%'} 

  return (
    <Main>
      <Divider hidden />
      <Container>
        <Wrapper display='flex' justifyContent='space-between'>
          <LinkButton
            labelPosition='right'
            icon='plus circle'
            content='Add new company'
            path={`/companies/new`}
          />
           <Search
            dataSource={
              ((user.admin || user.operator) && companies) ||
              (!user.admin && !user.operator && userCompanies)
            }
            onSearchResultChange={onSearchResultChange}
            setResetSearch={setresetSearch}
          />
        </Wrapper>
      </Container>

      {(!data && <Loader />) || (
        <Container>
          <TableSpacedRows sortable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell
                  sorted={column === 'name' ? direction : null}
                  onClick={() => handleSort('name')}
                >
                  Name
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === 'email' ? direction : null}
                  onClick={() => handleSort('email')}
                >
                  Email
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === 'country' ? direction : null}
                  onClick={() => handleSort('country')}
                >
                  Country
                </Table.HeaderCell>
               
                <Table.HeaderCell>
                  Details
                </Table.HeaderCell>
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {(data.length === 0 && <EmptyTable colNumber={4} />) ||
                data.map((company, i) => {
                  return (
                    <Table.Row key={i}>
                      <Table.Cell style={cellCss}>{company.name}</Table.Cell>
                      <Table.Cell style={cellCss}>{company.email}</Table.Cell>
                      <Table.Cell style={cellCss}>{company.country}</Table.Cell>
                      <Table.Cell style={cellCss}>
                        <Btn.Group horzontal widths='two'>
                          <LinkButton
                            content='Details'
                            icon='eye'
                            labelPosition='right'
                            path={`/companies/${company.id}`}
                          />
                        </Btn.Group>
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
            </Table.Body>
          </TableSpacedRows>
        </Container>
      )}
    </Main>
  );
};

const mapStateToProps = (state) => {
  return {
    user: selectors.auth.userSelector(state),
    companies: selectors.company.companiesSelector(state),
    userCompanies: selectors.companyUser.userCompaniesSelector(state),
    companyLoading: selectors.company.companiesLoadingSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
