/**
 * Dependencies.
 */
import React, { Component, ReactNode } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import ReactPaginate from 'react-paginate';

/**
 * Resources.
 */
import './companiesList.css';
import downArrowLogo from '../../assets/images/logos/downArrow.png';
import upArrowLogo from '../../assets/images/logos/upArrow.png';
import Loader from 'components/Loader';
import TextInput from '../textInput/textInput';
import { sleep } from 'spw/src/utils/sleep';
import { translatedCountryName } from 'spw/src/utils/translatedCountryName';

/**
 * CompaniesListProps defines the structure of props
 * expected by the CompaniesList component.
 */
interface CompaniesListProps extends WithTranslation {}

/**
 * CompaniesListState defines all state variables
 * needed by CompaniesList component.
 */
interface CompaniesListState {
  isLoading: boolean;
  researchTerm: string;
  companiesList: CompanyWithCountriesData[];
  filteredCompaniesList: CompanyWithCountriesData[];
  error: string | null;
  itemOffset: number;
  itemsPerPage: number;
  isButtonPressed: boolean;
  nameAscending: boolean;
  countryAscending: boolean;
}

/**
 * CompanyData defines properties from database
 * to use to create CompanyWithCountriesData objects.
 */
interface CompanyData {
  id: number;
  name: string;
  billingAddressId: number;
}

/**
 * CompanyWithCountriesData defines properties from database
 * to display in CompaniesList.
 */
interface CompanyWithCountriesData {
  id: number;
  name: string;
  country: string;
}

class CompaniesList extends Component<CompaniesListProps, CompaniesListState> {
  constructor(props: CompaniesListProps) {
    super(props);
    this.state = {
      isLoading: true,
      researchTerm: '',
      companiesList: [],
      filteredCompaniesList: [],
      error: null,
      itemOffset: 0,
      itemsPerPage: 5,
      isButtonPressed: true,
      nameAscending: true,
      countryAscending: false,
    };

    this.setResearchTerm = this.setResearchTerm.bind(this);
    this.setNameAscending = this.setNameAscending.bind(this);
    this.setCountryAscending = this.setCountryAscending.bind(this);
    this.setItemsPerPageBy5 = this.setItemsPerPageBy5.bind(this);
    this.setItemsPerPageBy10 = this.setItemsPerPageBy10.bind(this);
  }

  researchCompanyByName() {
    const fileredCompanies = this.state.companiesList.filter((company) =>
      company.name.toLowerCase().includes(this.state.researchTerm.toLowerCase())
    );
    this.setState({ filteredCompaniesList: fileredCompanies });
  }

  async filterCompaniesByName() {
    this.setState({ isLoading: true });
    const filteredCompanies = this.state.companiesList.sort(
      (company1, company2) => {
        const name1 = company1.name;
        const name2 = company2.name;
        return this.state.nameAscending
          ? name1.localeCompare(name2)
          : name2.localeCompare(name1);
      }
    );
    this.setState({ filteredCompaniesList: filteredCompanies });
    await sleep(100);
    this.setState({ isLoading: false });
  }

  async filterCompaniesByCountry() {
    this.setState({ isLoading: true });
    const filteredCompanies = this.state.companiesList.sort(
      (company1, company2) => {
        const country1 = company1.country;
        const country2 = company2.country;
        return this.state.countryAscending
          ? country1.localeCompare(country2)
          : country2.localeCompare(country1);
      }
    );
    this.setState({ filteredCompaniesList: filteredCompanies });
    await sleep(100);
    this.setState({ isLoading: false });
  }

  setResearchTerm(value: string) {
    this.setState({ researchTerm: value }, this.researchCompanyByName);
  }

  setNameAscending() {
    this.setState(
      (prevState) => ({
        nameAscending: !prevState.nameAscending,
      }),
      this.filterCompaniesByName
    );
  }

  setCountryAscending() {
    this.setState(
      (prevState) => ({
        countryAscending: !prevState.countryAscending,
      }),
      this.filterCompaniesByCountry
    );
  }

  setItemsPerPageBy5() {
    this.setState({ itemsPerPage: 5, isButtonPressed: true });
  }

  setItemsPerPageBy10() {
    this.setState({ itemsPerPage: 10, isButtonPressed: false });
  }

  async getAllCompanies() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API_URL}/api/v1/spw/company/getAll`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const jsonResponse = await response.json();

      let companiesWithCountries = [];

      if (JSON.stringify(jsonResponse) !== '{}') {
        const formattedJsonResponse = jsonResponse.data.map((obj) => [
          obj.companyId,
          obj.companyUserId,
          obj.companyName,
          obj.legalStatus,
          obj.vatOrRegistrationNumber,
          obj.deliveryAddress,
          obj.billingAddress,
          obj.director,
          obj.contact,
        ]);

        let companies = formattedJsonResponse.map((company: CompanyData[]) => ({
          id: company[0],
          name: company[2],
          billingAddressId: company[6],
        }));

        companiesWithCountries = await Promise.all(
          companies.map(async (company) => {
            const country = await this.getCompanyBillingCountry(
              company.billingAddressId
            );
            return {
              id: company.id,
              name: company.name,
              country: translatedCountryName(country),
            };
          })
        );
      }
      this.setState({
        isLoading: false,
        companiesList: companiesWithCountries,
        filteredCompaniesList: companiesWithCountries,
      });
      this.filterCompaniesByName();
    } catch (error: any) {
      this.setState({ isLoading: false, error: error.message });
      console.log(error.message);
    }
  }

  async getCompanyBillingCountry(addressId) {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API_URL}/api/v1/spw/address/getById`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ id: addressId }),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const jsonResponse = await response.json();

      return jsonResponse.data.country;
    } catch (error: any) {
      this.setState({ isLoading: false, error: error.message });
      console.log(error.message);
    }
  }

  componentDidMount(): void {
    this.getAllCompanies();
  }

  render(): ReactNode {
    const {
      isLoading,
      researchTerm,
      filteredCompaniesList,
      itemOffset,
      itemsPerPage,
      isButtonPressed,
      nameAscending,
      countryAscending,
    } = this.state;

    /**
     * Extract the `t` function from `useTranslation` hook.
     */
    const { t } = this.props;

    const endOffset = itemOffset + itemsPerPage;
    const currentItems = filteredCompaniesList.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(filteredCompaniesList.length / itemsPerPage);

    const headers = [
      {
        id: 1,
        text: t('companiesPageStrings.headers.companyName'),
        classname: 'companiesListItemHeader',
        filter: nameAscending,
        action: () => this.setNameAscending(),
      },
      {
        id: 2,
        text: t('companiesPageStrings.headers.country'),
        classname: 'companiesListItemHeaderReduce',
        filter: countryAscending,
        action: () => this.setCountryAscending(),
      },
      {
        id: 3,
        text: t('companiesPageStrings.headers.actions'),
        classname: 'companiesListActionsButtonHeader',
        filter: 'none',
        action: () => {},
      },
    ];

    function handleClickDetails(companyId: number) {
      window.location.href = `/spw/company/details/` + companyId;
    }

    function handleClickEmployees(companyId: number) {
      window.location.href = `/spw/company/employees/` + companyId;
    }

    function handleClickInvoices(companyId: number) {
      window.location.href = `/spw/company/invoices/` + companyId;
    }

    function handlePageClick(event) {
      const newOffset =
        (event.selected * itemsPerPage) % filteredCompaniesList.length;
      console.log(
        `User requested page number ${event.selected}, which is offset ${newOffset}`
      );
      this.setState({ itemOffset: newOffset });
    }

    return (
      <>
        {isLoading && <Loader />}
        <div className='companiesListComponentContainer'>
          <div className='companiesListGeneralActionsContainer'>
            <TextInput
              classname='companiesListResearchTextInput'
              placeholder={t('companiesPageStrings.searchPlaceholder')}
              initialValue={researchTerm}
              onSave={this.setResearchTerm}
            />
          </div>
          <div className='companiesListHeaderContainer'>
            {headers.map((header) =>
              header.filter === 'none' ? (
                <div key={header.id} className={header.classname}>
                  {header.text}
                </div>
              ) : (
                <div key={header.id} className={header.classname}>
                  {header.text}
                  {header.filter ? (
                    <img
                      src={upArrowLogo}
                      alt='upArrowLogo'
                      className='companiesListFilterLogos'
                      onClick={header.action}
                    />
                  ) : (
                    <img
                      src={downArrowLogo}
                      alt='downArrowLogo'
                      className='companiesListFilterLogos'
                      onClick={header.action}
                    />
                  )}
                </div>
              )
            )}
          </div>
          <div className='companiesListContainer'>
            {currentItems.length === 0 ? (
              <div className='noCompaniesListItem'>
                <p>{t('companiesPageStrings.noCompanies')}</p>
              </div>
            ) : (
              currentItems.map((company, index) => (
                <div key={index} className='companiesListItem'>
                  <div className='companiesListItemColumn'>{company.name}</div>
                  <div className='companiesListItemColumnReduce'>
                    {company.country}
                  </div>
                  <div className='companiesListActionsButtonContainer'>
                    <button
                      className='companiesListActionButton'
                      onClick={() => handleClickDetails(company.id)}
                    >
                      <div>{t('companiesPageStrings.detailsButton')}</div>
                    </button>
                    <button
                      className='companiesListActionButton'
                      onClick={() => handleClickEmployees(company.id)}
                    >
                      <div>{t('companiesPageStrings.employeesButton')}</div>
                    </button>
                    <button
                      className='companiesListActionButton'
                      onClick={() => handleClickInvoices(company.id)}
                    >
                      <div>{t('companiesPageStrings.invoicesButton')}</div>
                    </button>
                  </div>
                </div>
              ))
            )}
          </div>
          <div className='companiesListFooterContainer'>
            {currentItems.length !== 0 && (
              <>
                <div className='paginate'>
                  <ReactPaginate
                    nextLabel={t('companiesPageStrings.nextButton')}
                    onPageChange={handlePageClick.bind(this)}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={2}
                    pageCount={pageCount}
                    previousLabel={t('companiesPageStrings.previousButton')}
                    pageClassName='pageItem'
                    pageLinkClassName='pageLink'
                    previousClassName='pageItem'
                    previousLinkClassName='pageLink'
                    nextClassName='pageItem'
                    nextLinkClassName='pageLink'
                    breakLabel='...'
                    breakClassName='pageItem'
                    breakLinkClassName='pageLink'
                    containerClassName='pagination'
                    activeClassName='active'
                    renderOnZeroPageCount={null}
                  />
                </div>
                <div className='itemsPerPage'>
                  <div>{t('companiesPageStrings.companiesPerPage')}</div>
                  <div>
                    <button
                      className={
                        isButtonPressed
                          ? 'itemsPerPageButtonPressed'
                          : 'itemsPerPageButton'
                      }
                      onClick={this.setItemsPerPageBy5}
                    >
                      5
                    </button>
                    <button
                      className={
                        isButtonPressed
                          ? 'itemsPerPageButton'
                          : 'itemsPerPageButtonPressed'
                      }
                      onClick={this.setItemsPerPageBy10}
                    >
                      10
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation()(CompaniesList);
