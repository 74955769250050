import styled from '@emotion/styled';
import logo from '../../../assets/images/logo_couleur.png'

export const ImageLoginStyled = styled.div((props: any) => {
  return {
    display: 'block',
    height: props.height || '100%',
    width: props.width || '100%',
    maxWidth: props.maxWidth || '100%',
    maxHeight: props.maxHeight || '100%',
    margin: props.margin || '0px',
    backgroundImage: `url(${logo})`,
    backgroundSize: 'cover'
  };
});
