// Dependencies
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Stylesheets
import './forms.css';
import 'react-edit-text/dist/index.css';
import 'react-calendar/dist/Calendar.css';
import 'react-date-picker/dist/DatePicker.css';
import AddressBlock from '../addressBlock/addressBlock';
import saveLogo from '../../assets/images/logos/save.png';
import Loader from 'components/Loader';
import TextInput from '../textInput/textInput';

interface LocationMissionDetailsProperties {
  ref: React.RefObject<HTMLDivElement>;
  id: number;
  defaultName: string;
  defaultMainAddress: string;
  defaultAdditionalAddress: string;
  defaultPostalCode: string;
  defaultCity: string;
  defaultCountry: string;
}

/**
 * @name locationMissionDetails.tsx
 * @implements updateFormsProperties.tsx
 *
 * @path /components/updateForms/lodgingMissionDetails.tsx
 *
 * @description Defines content for update lodging information popup in MissionDetails component
 */
const LocationMissionDetails: React.FC<LocationMissionDetailsProperties> = ({
  ref,
  id,
  defaultName,
  defaultMainAddress,
  defaultAdditionalAddress,
  defaultPostalCode,
  defaultCity,
  defaultCountry,
}) => {
  // Parameter that must be used before each translatable string to apply the good language version
  const { t } = useTranslation();

  const [isErrorHidden, setIsErrorHidden] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // State variable to store current selected option
  const [, setCountries] = useState<any[]>([]);
  const [selectedOptionCountry, setSelectedOptionCountry] = useState<
    string | null
  >(defaultCountry);

  // Is what happening when user performed a click on the country selector
  const handleSelectChangeCountry = (value: string) => {
    setSelectedOptionCountry(value);
  };

  useEffect(() => {
    setCountries(['France']);
  }, []);

  const [locationName, setLocationName] = useState<string>(defaultName);
  const [locationMainAddress, setLocationMainAddress] =
    useState<string>(defaultMainAddress);
  const [locationAdditionalAddress, setLocationAdditionalAddress] =
    useState<string>(defaultAdditionalAddress);
  const [locationPostalCode, setLocationPostalCode] =
    useState<string>(defaultPostalCode);
  const [locationCity, setLocationCity] = useState<string>(defaultCity);

  const handleLocationNameChange = (value: string) => {
    setLocationName(value);
  };

  const handleLocationMainAddressChange = (value: string) => {
    setLocationMainAddress(value);
  };

  const handleLocationAdditionalAddressChange = (value: string) => {
    setLocationAdditionalAddress(value);
  };

  const handleLocationPostalCodeChange = (value: string) => {
    setLocationPostalCode(value);
  };

  const handleLocationCityChange = (value: string) => {
    setLocationCity(value);
  };

  const isEmpty = (string: string) => {
    return string.trim() === '';
  };

  const updateLocationDetails = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API_URL}/api/v1/spw/updateLocation/locationDetails`,
        {
          method: 'PATCH',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            id: id,
            name: locationName,
            mainAddress: locationMainAddress,
            additionalAddress: locationAdditionalAddress,
            postalCode: locationPostalCode,
            city: locationCity,
            country: selectedOptionCountry,
          }),
        }
      );

      const result = await response.json();

      return result.data;
    } catch (error) {
      console.log('Erreur', error);
    }
  };

  const execUpdateLodgingDetails = async () => {
    setIsLoading(true);

    const result = await updateLocationDetails();

    return result;
  };

  const handleClickSave = () => {
    if (
      locationName === undefined ||
      isEmpty(locationName) ||
      locationMainAddress === undefined ||
      isEmpty(locationMainAddress) ||
      locationPostalCode === undefined ||
      isEmpty(locationPostalCode) ||
      locationCity === undefined ||
      isEmpty(locationCity) ||
      selectedOptionCountry === undefined ||
      isEmpty(selectedOptionCountry)
    ) {
      setIsErrorHidden(false);
    } else {
      setIsErrorHidden(true);
      execUpdateLodgingDetails();
      setIsLoading(false);
      window.location.reload();
    }
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className='detailsPopupContent' ref={ref}>
            <TextInput
              classname='updateLocationName'
              placeholder={t('orderPageStrings.location.name')}
              initialValue={defaultName}
              onSave={handleLocationNameChange}
            />

            {/* Address */}

            <AddressBlock
              divClassName='updateAddresses'
              title={t('companyPageStrings.address')}
              initialMainAddress={defaultMainAddress}
              initialAdditionalAddress={defaultAdditionalAddress}
              initialPostalCode={defaultPostalCode}
              initialCity={defaultCity}
              initialCountry={defaultCountry}
              onSaveMainAddress={handleLocationMainAddressChange}
              onSaveAdditionalAddress={handleLocationAdditionalAddressChange}
              onSavePostalCode={handleLocationPostalCodeChange}
              onSaveCity={handleLocationCityChange}
              onSaveCountry={handleSelectChangeCountry}
              onlyFrance={true}
            />
            <div>
              <div className='space'></div>
              {isErrorHidden ? (
                <></>
              ) : (
                <div className='companyError'>
                  <p>{t('orderPageStrings.error')}</p>
                </div>
              )}
              <div className='saveButtonContainer'>
                <button className='saveButton' onClick={handleClickSave}>
                  <p>{t('popupStrings.saveText')}</p>
                  <img
                    src={saveLogo}
                    alt={t('popupStrings.saveLogo')}
                    className='saveLogo'
                  />
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default LocationMissionDetails;
