import React, { useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';

import Monitoring from './Monitoring';
import CompanyList from './Operator/Service';
import ServicePaused from 'components/ServicePaused';
import { selectors, actions } from 'store';
import Loader from 'components/Loader';
import { Container } from 'semantic-ui-react';

const Index = ({
  user,
  dispatch,
  history,
  companySelected,
  hasSubscribedTo
}: any) => {
  useEffect(() => {
     if(companySelected){
       dispatch(
         actions.company.hasSubscribedTo({
           params: {
             companyId: companySelected,
             serviceName: 'Intrastat',
           },
         })
       );
       return () => {
        dispatch(actions.company.reset(['hasSubscribedTo', 'error']));
      };
     } else {
       dispatch(actions.company.reset(['hasSubscribedTo', 'error']));
     }
 
    
   }, [dispatch, companySelected]);


  return (
    ((user.admin || user.operator) && (
      <CompanyList history={history} dispatch={dispatch} user={user} companySelected={companySelected}/>
    )) || 
    (companySelected && hasSubscribedTo && !hasSubscribedTo.pause && (
      <Monitoring 
        dispatch={dispatch} 
        history={history} 
        user={user}
      />
    )) || 
    (hasSubscribedTo && hasSubscribedTo.pause && (
      <ServicePaused history={history} companySelected={companySelected} />
    )) || 
    ((companySelected === null || hasSubscribedTo === false) && (
      <Container textAlign='center'>
        <h1>You have not subscribed to this service</h1>
      </Container>
    )) || <Loader />
  );
};

const mapStateToProps = (state) => {
  return {
    user: selectors.auth.userSelector(state),
    companySelected: selectors.auth.companySelected(state),
    hasSubscribedTo: selectors.company.hasSubscribedToSelector(state),
    hasSubscribedToLoading: selectors.company.hasSubscribedToLoadingSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
