/**
 * Resources.
 */
import { currentLanguage } from '../../../views';
import countriesEN from '../assets/json/countriesEN.json';
import countriesFR from '../assets/json/countriesFR.json';

/**
 * Converts a country code, issued from database, into
 * coutry fullname, in the correct language version.
 *
 * @param countryCode universal 2-digits code to identify
 * a country.
 *
 * @returns the fullname of the country related to countryCode,
 * in the correct language version.
 */
export function translatedCountryName(countryCode: string): string {
  let formattedCountry;

  if (currentLanguage === 'fr') {
    let country = countriesFR.find((country) => country.code === countryCode);

    formattedCountry = country ? country.name : 'Country not found';
  } else {
    let country = countriesEN.find((country) => country.code === countryCode);

    formattedCountry = country ? country.name : 'Country not found';
  }

  return formattedCountry;
}
