import React, { useEffect, useState } from 'react';
import { Field, Formik } from 'formik';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { selectors, actions } from 'store';
import { Form, Icon } from 'semantic-ui-react';

import Button from 'components/Button';
import Wrapper from 'components/core/Wrapper/Wrapper';
import Segment from 'components/core/Segment/Segment';
import { FiscalPeriod } from 'components/Form';
import { vatDeclarationFiltersSchema } from 'interfaces/formSchema/customsValidators';
import { Input } from 'components/Form';
import Dropdown from 'components/Dropdown';

const SearchFilters = ({
  dispatch,
  applyFilters,
  searchLoading,
  resetFilters,
  isVisible,
  companies,
  countries,
  mapping,
}: any) => {
  const [mappingList, setmappingList]: any = useState([]);
  const [companiesList, setcompaniesList]: any = useState([]);
  const [countriesList, setcountriesList]: any = useState([]);

  
  useEffect(() => {
    if (isVisible) {
      dispatch(actions.customsDeclaration.getAllCustomsOffice());
    } else {
      dispatch(
        actions.customsDeclaration.reset(['customsOffices', 'error'])
      );
    }
  }, [isVisible, dispatch]);


  useEffect(() => {                                                                                
    return () => {
      dispatch(
        actions.fiscalDeclaration.reset([
          'mapping',
          'ta',
          'error',
        ])
      );
    };
  }, [dispatch]);


  useEffect(() => {
    if (companies !== null) {
      setcompaniesList(
        companies.map((company, index) => {
          return {
            key: index,
            value: company.name,
            flag: company.country.toLowerCase(),
            text: company.name,
          };
        })
      );
    }
  }, [companies]);


  useEffect(() => {
    if (mapping !== null) {
      setmappingList(
        mapping.map((workshop, index) => {
          return {
            key: index,
            value: workshop.name,
            text: workshop.name,
          };
        })
      );
    }
  }, [mapping]);


  useEffect(() => {
    if (countries !== null) {
      setcountriesList(
        countries.map((country, index) => {
          return {
            key: index,
            value: country.iso_code,
            text: country.english_name,
            flag: country.iso_code.toLowerCase(),
          };
        })
      );
    }
  }, [countries]);


  const companiesSelected = (companyName) => {
    dispatch(actions.fiscalDeclaration.reset(['ta']));
    if (companyName) {
      dispatch(actions.fiscalDeclaration.getMapping());
    } 
  };


  return (
    <Segment
      {...(!isVisible && { display: 'none' })}
      margin='1rem 0 0 0'
      width='100%'
    >
      <Formik
        initialValues={{
          startDate: null,
          endDate: null,
          company: null,
          workshop: null,
          emitter: null,
          state: null,
          country: null
        }}
        onSubmit={applyFilters}
        validationSchema={vatDeclarationFiltersSchema}
      >
        {({
          values,
          errors,
          touched,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
          resetForm,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Wrapper
              display='flex'
              justifyContent='center'
              flexDirection='column'
            >
              <Wrapper display='flex' margin='0.5rem' justifyContent='center'>
                <Wrapper margin='0.5rem'>
                  <Field
                    placeholder='From'
                    name='startDate'
                    component={FiscalPeriod}
                    meta={{ 
                      errors, 
                      touched, 
                      setFieldValue, 
                      setFieldTouched 
                    }}
                  />
                </Wrapper>
                <Wrapper margin='0.5rem'>
                  <Field
                    placeholder='To'
                    name='endDate'
                    component={FiscalPeriod}
                    meta={{ 
                      errors, 
                      touched, 
                      setFieldValue, 
                      setFieldTouched 
                    }}
                  />
                </Wrapper>
              </Wrapper>
              <Wrapper display='flex' margin='0.5rem' justifyContent='center'> 
                <Wrapper margin='0.5rem' width='300px'>
                  <Field
                    placeholder='Company Name...'
                    name='company'
                    options={companiesList}
                    component={Dropdown}
                    meta={{
                      errors,
                      touched,
                      setFieldValue,
                      setFieldTouched,
                    }}
                    onChange={companiesSelected}
                  />
                </Wrapper>
                <Wrapper margin='0.5rem' width='170px'>
                  <Field
                    placeholder='Workshop...'
                    name='workshop'
                    options={mappingList}
                    component={Dropdown}
                    meta={{
                      errors,
                      touched,
                      setFieldValue,
                      setFieldTouched,
                    }}
                  />
                </Wrapper>
                <Wrapper margin='0.5rem'>
                  <Field
                    placeholder='Emitter'
                    name='emitter'
                    component={Input}
                    meta={{ 
                      errors, 
                      touched, 
                      setFieldValue, 
                      setFieldTouched 
                    }}
                  />
                </Wrapper>
                <Wrapper margin='0.5rem' width='150px'>
                  <Field
                    placeholder='State...'
                    name='state'
                    component={Dropdown}
                    options={
                      [
                        {
                          key: 0,
                          value: 'Error',
                          text: 'Error'
                        },
                        {
                          key: 1,
                          value: 'Finished',
                          text: 'Finished'
                        },
                        {
                          key: 2,
                          value: 'Validated',
                          text: 'Validated'
                        }
                      ]
                    }
                    meta={{ 
                      errors, 
                      touched, 
                      setFieldValue, 
                      setFieldTouched 
                    }}
                  />
                </Wrapper>
                <Wrapper margin='0.5rem' width='150px'>
                  <Field
                    placeholder='Country...'
                    name='country'
                    component={Dropdown}
                    options={countriesList}
                    meta={{ 
                      errors, 
                      touched, 
                      setFieldValue, 
                      setFieldTouched 
                    }}
                  />
                </Wrapper>
              </Wrapper>
            </Wrapper>
            <Wrapper display='flex' margin='1rem' justifyContent='center'>
              <Button
                disabled={searchLoading}
                {...(searchLoading && {
                  icon: <Icon loading name='spinner' />,
                })}
                type='submit'
                content='Apply'
                color='green'
              />
              <Button
                type='button'
                content='Reset'
                color='red'
                onClick={() => {
                  resetFilters();
                  resetForm();
                }}
              />
            </Wrapper>
          </Form>
        )}
      </Formik>
    </Segment>
  );
};

const mapStateToProps = (state) => {
  return {
    customsOffices: selectors.customsDeclaration.getAllCustomsOfficeSelector(state),
    customsOfficesLoading: selectors.customsDeclaration.getAllCustomsOfficeLoadingSelector(state),
    searchLoading: selectors.customsDeclaration.loadingSelector(state),
    mapping: selectors.fiscalDeclaration.mappingSelector(state),
    mappingLoading: selectors.fiscalDeclaration.loadingSelector(state),
    ta: selectors.fiscalDeclaration.taSelector(state),
    errorHandlerDeclaration: selectors.fiscalDeclaration.errorSelector(state),
    loadingHandlerTA: selectors.fiscalDeclaration.taLoadingSelector(state),
    loadingHandlerData: selectors.data.allCountryLoadingSelector(state),
    loadingHandlerDeclaration: selectors.fiscalDeclaration.mappingLoadingSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(SearchFilters);
