let actionTypes = {
  GET_ALL_TRANSPORTER: 'MY_ASD.COMPANY.GET_ALL_TRANSPORTER',
  GET_ALL_TRANSPORTER_LOADING: 'MY_ASD.COMPANY.GET_ALL_TRANSPORTER_LOADING',
  GET_ALL_TRANSPORTER_SUCCESS: 'MY_ASD.COMPANY.GET_ALL_TRANSPORTER_SUCCESS',
  GET_ALL_TRANSPORTER_ERROR: 'MY_ASD.COMPANY.GET_ALL_TRANSPORTER_ERROR',

  HAS_SUBSCRIBED_TO: 'MY_ASD.COMPANY.HAS_SUBSCRIBED_TO',
  HAS_SUBSCRIBED_TO_LOADING: 'MY_ASD.COMPANY.HAS_SUBSCRIBED_TO_LOADING',
  HAS_SUBSCRIBED_TO_SUCCESS: 'MY_ASD.COMPANY.HAS_SUBSCRIBED_TO_SUCCESS',
  HAS_SUBSCRIBED_TO_ERROR: 'MY_ASD.COMPANY.HAS_SUBSCRIBED_TO_ERROR',

  GET_ALL: 'MY_ASD.COMPANY.GET_ALL',
  GET_ALL_LOADING: 'MY_ASD.COMPANY.GET_ALL_LOADING',
  GET_ALL_SUCCESS: 'MY_ASD.COMPANY.GET_ALL_SUCCESS',
  GET_ALL_ERROR: 'MY_ASD.COMPANY.GET_ALL_ERROR',

  GET_PERMISSIONS: 'MY_ASD.COMPANY.GET_PERMISSIONS',
  GET_PERMISSIONS_LOADING: 'MY_ASD.COMPANY.GET_PERMISSIONS_LOADING',
  GET_PERMISSIONS_SUCCESS: 'MY_ASD.COMPANY.GET_PERMISSIONS_SUCCESS',
  GET_PERMISSIONS_ERROR: 'MY_ASD.COMPANY.GET_PERMISSIONS_ERROR',

  GET_ONE: 'MY_ASD.COMPANY.GET_ONE',
  GET_ONE_LOADING: 'MY_ASD.COMPANY.GET_ONE_LOADING',
  GET_ONE_SUCCESS: 'MY_ASD.COMPANY.GET_ONE_SUCCESS',
  GET_ONE_ERROR: 'MY_ASD.COMPANY.GET_ONE_ERROR',

  CREATE: 'MY_ASD.COMPANY.CREATE',
  CREATE_LOADING: 'MY_ASD.COMPANY.CREATE_LOADING',
  CREATE_SUCCESS: 'MY_ASD.COMPANY.CREATE_SUCCESS',
  CREATE_ERROR: 'MY_ASD.COMPANY.CREATE_ERROR',

  UPDATE: 'MY_ASD.COMPANY.UPDATE',
  UPDATE_LOADING: 'MY_ASD.COMPANY.UPDATE_LOADING',
  UPDATE_SUCCESS: 'MY_ASD.COMPANY.UPDATE_SUCCESS',
  UPDATE_ERROR: 'MY_ASD.COMPANY.UPDATE_ERROR',

  REMOVE: 'MY_ASD.COMPANY.REMOVE',
  REMOVE_LOADING: 'MY_ASD.COMPANY.REMOVE_LOADING',
  REMOVE_SUCCESS: 'MY_ASD.COMPANY.REMOVE_SUCCESS',
  REMOVE_ERROR: 'MY_ASD.COMPANY.REMOVE_ERROR',

  SEARCH: 'MY_ASD.COMPANY.SEARCH',
  SEARCH_LOADING: 'MY_ASD.COMPANY.SEARCH_LOADING',
  SEARCH_SUCCESS: 'MY_ASD.COMPANY.SEARCH_SUCCESS',
  SEARCH_ERROR: 'MY_ASD.COMPANY.SEARCH_ERROR',

  RESET: 'MY_ASD.COMPANY.RES',
  REFRESH_ERROR: 'MY_ASD.COMPANY.REFRESH_ERROR',
};

export default actionTypes;
