/**
 * Depenencies
 */
import React, { Component, ReactNode } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { withTranslation, WithTranslation } from 'react-i18next';

/**
 * Resources
 */
import '../index.css';
import Header from '../components/header/header';
import Footer from '../components/footer/footer';
import Content from '../components/content/content';
import InvoicesList from '../components/invoicesList/invoicesList';

/**
 *
 */
interface Params {
  missionId: number;
}

/**
 * MissionInvoicesPageProps interface defines the structure of props
 * expected by the InvoicePage component.
 */
interface MissionInvoicesPageProps extends WithTranslation {
  missionId: number;
}

/**
 * MissionInvoicesPage component displays a styled list of all
 * invoices related to a company.
 */
class MissionInvoicesPage extends Component<
  RouteComponentProps<Params>,
  MissionInvoicesPageProps
> {
  render(): ReactNode {
    /**
     * Extract the `t` function from `useTranslation` hook.
     */
    const { t } = this.props;
    const { missionId } = this.props.match.params;

    return (
      <div className='pages'>
        <Helmet>
          <title>
            {t('headerStrings.companyButtonStrings.invoices')}
            {t('helmetString')}
          </title>
        </Helmet>
        <Header />
        <Content
          title={t('headerStrings.companyButtonStrings.invoices')}
          homepage={false}
          history={window.location.href.split('/').slice(0, -1).join('/')}
        />
        <InvoicesList viewAll={false} missionId={missionId} />
        <Footer />
      </div>
    );
  }
}

export default withTranslation()(MissionInvoicesPage);
