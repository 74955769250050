import React from 'react';
import { useTranslation } from 'react-i18next';

// Resources
import { currentLanguage } from '../../../../views';
import logoCouleur from '../../../../assets/images/logoSPWCouleur.png';

// Interfaces
import UpdateFormsProperties from '../../interfaces/updateFormsProperties';

// Stylesheets
import './about.css';

const About: React.FC<UpdateFormsProperties> = ({ ref }) => {
  const { t } = useTranslation();

  return (
    <div ref={ref} className='aboutContainer'>
      <img src={logoCouleur} alt='ASD SPW Logo' />
      <p>{t('popupStrings.aboutContent')}</p>
      {currentLanguage === 'fr' ? (
        <>
          <a href='https://nc.asd-int.com/s/jQL6eZTAJQaaDPR/download?path=%2F&files=Conditions_générales_d_utilisation.pdf'>
            {t('popupStrings.termsOfService')}
          </a>
          <a href='https://nc.asd-int.com/s/jjDiLaPCCA4HFo5/download?path=%2F&files=Conditions_générales_de_vente.pdf'>
            {t('popupStrings.termsAndConditionsOfSale')}
          </a>
        </>
      ) : (
        <>
          <a href='https://nc.asd-int.com/s/jQL6eZTAJQaaDPR/download?path=%2F&files=Terms_of_service.pdf'>
            {t('popupStrings.termsOfService')}
          </a>
          <a href='https://nc.asd-int.com/s/jjDiLaPCCA4HFo5/download?path=%2F&files=Terms_and_conditions_of_sale.pdf'>
            {t('popupStrings.termsAndConditionsOfSale')}
          </a>
        </>
      )}
      <div className='contact'>
        <h4>{t('popupStrings.contactUs')}</h4>
        <p>
          {t('popupStrings.email')}
          <a href='mailto: operators@asdbusinessapp.com'>
            operators@asdbusinessapp.com
          </a>
        </p>
        <p>
          {t('popupStrings.phone')}
          <a href='callto: +33 4 92 380 805'>+33 4 92 380 805</a>
        </p>
      </div>
    </div>
  );
};

export default About;
