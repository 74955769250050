// Dependencies
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

// Components
import FileUploader from '../fileUploader/fileUploader';
import Loader from 'components/Loader';

// Resources
import extension from '../../assets/images/logos/extension.png';
import switcher from '../../assets/images/logos/switcher.png';
import saveLogo from '../../assets/images/logos/save.png';

// Stylesheets
import './pdfViewer.css';
import 'react-edit-text/dist/index.css';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';

interface PdfViewerProperties {
  fileType: string;
  isConstructionCardViewer?: boolean;
  fileSlot: number;
  employeeId: number;
  employeeName: string;
}

const PdfViewer: React.FC<PdfViewerProperties> = ({
  fileType,
  isConstructionCardViewer,
  fileSlot,
  employeeId,
  employeeName,
}) => {
  const { t } = useTranslation();

  const [isErrorHidden, setIsErrorHidden] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [file, setFile] = useState<File | null>(null);
  const [isFileUpload, setIsFileUpload] = useState<boolean>(false);
  const [url, setUrl] = useState<string>();
  const [documentName, setDocumentName] = useState<string>();

  useEffect(() => {
    switch (fileSlot) {
      case 1:
        setDocumentName('identity_document');
        break;
      case 2:
        setDocumentName('identity_photo');
        break;
      case 3:
        setDocumentName('construction_card');
        break;
      case 4:
        setDocumentName('medical_certificate');
        break;
      case 5:
        setDocumentName('a1_form');
        break;
      case 6:
        setDocumentName('employement_contract');
        break;
      default:
        return;
    }
  }, [documentName]);

  function uniqid(mimeType: string) {
    const sec = Date.now() * 1000 + Math.random() * 1000;
    const id = sec.toString(16).replace(/\./g, '').padEnd(14, '0');
    return `${id}.${mimeType}`;
  }

  const handleFileUpload = (uploadedFile: File) => {
    setFile(uploadedFile);
    setUrl(URL.createObjectURL(uploadedFile));
    setIsFileUpload(true);
  };

  const handleClickExtensionButton = () => {
    window.open(url, '_blank');
  };

  const handleClickSwitchButton = () => {
    setIsFileUpload(false);
    setUrl(undefined);
    setFile(null);
  };

  const execUploadEmployeeDocumentsOnS3 = async (
    employeeId: number,
    documentName: string,
    fileName: string
  ) => {
    setIsLoading(true);
    const filePath = `${employeeId}/${documentName}`;
    const formData = new FormData();
    let newFile = uniqid(fileType);
    formData.append('file', file, newFile);
    formData.append('filePath', filePath);

    try {
      const response = await axios
        .post(
          `${process.env.REACT_APP_DEV_API_URL}/api/v1/spw/updateEmployee/uploadEmployeeDocuments`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        )
        .then(
          await execUpdateEmployeeDocuments(employeeId, documentName, newFile)
        );

      window.sessionStorage.setItem('fileUpload', 'true');
      console.log('File uploaded successfully:', response.data);
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const execUpdateEmployeeDocuments = async (
    employeeId: number,
    documentName: string,
    fileName: string
  ) => {
    const url = `/${employeeId}/${documentName}/${fileName}`;
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API_URL}/api/v1/spw/updateEmployee/updateEmployeeDocuments`,
        {
          method: 'PATCH',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            id: employeeId,
            name: employeeName,
            documentName: documentName,
            url: url,
            fileName: fileName,
          }),
        }
      );

      const result = await response.json();

      return result.data;
    } catch (error) {
      console.log('Erreur', error);
    }
  };

  const handleClickSave = () => {
    if (
      employeeId === undefined ||
      employeeName === undefined ||
      documentName === undefined ||
      file === undefined ||
      file.name === undefined ||
      file.type === undefined ||
      isEmpty(employeeName) ||
      isEmpty(documentName) ||
      isEmpty(file.name) ||
      isEmpty(file.type)
    ) {
      setIsErrorHidden(false);
    } else {
      setIsErrorHidden(true);
      execUploadEmployeeDocumentsOnS3(employeeId, documentName, file.name).then(
        () => window.location.reload()
      );
    }
  };

  const isEmpty = (string: string) => {
    return string.trim() === '';
  };

  return (
    <>
      <div
        className={
          isConstructionCardViewer
            ? 'employeeConstructionCard'
            : 'employeeDocumentsList'
        }
      >
        {file ? (
          <div className='iframe'>
            <iframe
              title='PDF Viewer'
              src={URL.createObjectURL(file)}
              className='pdfViewer'
            />
            <div className='pdfViewerButtons'>
              <img
                src={extension}
                alt='extensionLogo'
                className='pdfViewerButton'
                onClick={handleClickExtensionButton}
              />
              <img
                src={switcher}
                alt='switchLogo'
                className='pdfViewerButton'
                onClick={handleClickSwitchButton}
              />
            </div>
          </div>
        ) : (
          <FileUploader
            onFileUpload={handleFileUpload}
            hidden={isFileUpload}
            fileType={fileType}
          />
        )}
        {isLoading && <Loader />}
      </div>
      <div>
        <div className='space'></div>
        {isErrorHidden ? (
          <></>
        ) : (
          <div className='companyError'>
            <p>{t('orderPageStrings.error')}</p>
          </div>
        )}
        <div className='saveButtonContainer'>
          <button className='saveButton' onClick={handleClickSave}>
            <p>{t('popupStrings.saveText')}</p>
            <img
              src={saveLogo}
              alt={t('popupStrings.saveLogo')}
              className='saveLogo'
            />
          </button>
        </div>
      </div>
    </>
  );
};

export default PdfViewer;
