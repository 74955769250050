/**
 * Dependencies
 */
import React, {
  Component,
  ChangeEvent,
  Dispatch,
  SetStateAction,
  createRef,
} from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

/**
 * Resources
 */
import './orderForm.css';

/**
 * ConstructionCardProps interface defines the structure of props
 * expected by the ConstructionCard component.
 */
interface ConstructionCardProps extends WithTranslation {
  setCount: Dispatch<SetStateAction<number>>;
}

/**
 *
 */
interface ConstructionCardState {
  selectedOptionNumber?: number;
}

/**
 * ConstructionCard component displays a part of OrderForm,
 * that gives user a choice between buy new construction cards,
 * or modify its existing cards.
 */
class ConstructionCard extends Component<
  ConstructionCardProps,
  ConstructionCardState
> {
  private numberOfConstructionCardRef;

  constructor(props) {
    super(props);
    this.state = {
      selectedOptionNumber: undefined,
    };

    this.numberOfConstructionCardRef = createRef();
    this.handleNewCardOptionsChange =
      this.handleNewCardOptionsChange.bind(this);
  }

  handleIncrement(): void {
    this.props.setCount((prevCount) => prevCount + 1);
  }

  handleNewCardOptionsChange(event: ChangeEvent<HTMLSelectElement>): void {
    window.sessionStorage.setItem('selectedNewCardOption', event.target.value);
    this.setState({
      selectedOptionNumber: Number(event.target.value),
    });
    this.handleIncrement();
  }

  componentDidMount(): void {
    const storedSelectedOptionNumber = window.sessionStorage.getItem(
      'selectedNewCardOption'
    );
    if (storedSelectedOptionNumber) {
      this.setState({
        selectedOptionNumber: Number(storedSelectedOptionNumber),
      });
    }
  }

  render() {
    const numbers = Array.from(
      {
        length:
          Number(window.sessionStorage.getItem('selectedOptionNumber')) + 1,
      },
      (_, index) => index
    );

    /**
     * Extract the `t` function from `useTranslation` hook.
     */
    const { t } = this.props;

    return (
      <div className='orderConstructionCard'>
        <div className='orderConstructionCardOptions'>
          <pre>
            <p id='obligatoire'>* </p>
          </pre>
          <pre>
            <p>
              {t('orderPageStrings.missionTypeOptions.needConstructionCard')}
            </p>
          </pre>
        </div>
        <div className='orderConstructionCardOptions'>
          <select
            id='select'
            ref={this.numberOfConstructionCardRef}
            className='employeesDropdown'
            value={this.state.selectedOptionNumber}
            onChange={this.handleNewCardOptionsChange}
          >
            <option key='defaultNumberOfEmployees' value=''>
              {t('---')}
            </option>
            {numbers.map((number) => (
              <option key={number} value={number}>
                {number}
              </option>
            ))}
          </select>
        </div>
      </div>
    );
  }
}

export default withTranslation()(ConstructionCard);
