// Dependencies
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-date-picker';
import tippy from 'tippy.js';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { selectors } from 'store';

// Components
import NavbarDropdownLanguage from '../navbarDropdown/navbarDropdownLanguage';
import CountrySelector from '../countriesSelector/countriesSelector';
import TextInput from '../textInput/textInput';
import AddressBlock from '../addressBlock/addressBlock';

// Resources
import { currentLanguage } from '../../../../views';
import saveLogo from '../../assets/images/logos/save.png';
import tooltip from '../../assets/images/logos/tooltip.png';
import countriesEN from '../../assets/json/countriesEN.json';
import countriesFR from '../../assets/json/countriesFR.json';

// Stylesheets
import './createForm.css';
import 'tippy.js/dist/tippy.css';

const CreateCompany: React.FC = ({ user }: any) => {
  const { t } = useTranslation();

  const [activeComponent, setActiveComponent] = useState<number>(1);

  const [companyName, setCompanyName] = useState<string>('');
  const [legalStatus, setLegalStatus] = useState<string>('');
  const [vatNumber, setVatNumber] = useState<string>('');
  const [deliveryMainAddress, setDeliveryMainAddress] = useState<string>('');
  const [deliveryAdditionalAddress, setDeliveryAdditionalAddress] =
    useState<string>('');
  const [deliveryPostalCode, setDeliveryPostalCode] = useState<string>('');
  const [deliveryCity, setDeliveryCity] = useState<string>('');
  const [deliveryCountry, setDeliveryCountry] = useState<string>('');
  const [billingMainAddress, setBillingMainAddress] = useState<string>('');
  const [billingAdditionalAddress, setBillingAdditionalAddress] =
    useState<string>('');
  const [billingPostalCode, setBillingPostalCode] = useState<string>('');
  const [billingCity, setBillingCity] = useState<string>('');
  const [billingCountry, setBillingCountry] = useState<string>('');
  const [directorFirstName, setDirectorFirstName] = useState<string>('');
  const [directorLastName, setDirectorLastName] = useState<string>('');
  const [directorDateOfBirth, setDirectorDateOfBirth] = useState<string>('');
  const [directorPlaceOfBirth, setDirectorPlaceOfBirth] = useState<string>('');
  const [directorCountryOfBirth, setDirectorCountryOfBirth] =
    useState<string>('');
  const [contactLastName, setContactLastName] = useState<string>('');
  const [contactFirstName, setContactFirstName] = useState<string>('');
  const [contactMailAddress, setContactMailAddress] = useState<string>('');
  const [contactPhoneNumber, setContactPhoneNumber] = useState<string>('');

  const handleCompanyNameChange = (value: string) => {
    setCompanyName(value);
  };

  const handleLegalStatusChange = (value: string) => {
    setLegalStatus(value);
  };

  const handleVatNumberChange = (value: string) => {
    setVatNumber(value);
  };

  const handleDeliveryMainAddressChange = (value: string) => {
    setDeliveryMainAddress(value);
  };

  const handleDeliveryAdditionalAddressChange = (value: string) => {
    setDeliveryAdditionalAddress(value);
  };

  const handleDeliveryPostalCodeChange = (value: string) => {
    setDeliveryPostalCode(value);
  };

  const handleDeliveryCityChange = (value: string) => {
    setDeliveryCity(value);
  };

  const handleBillingMainAddressChange = (value: string) => {
    setBillingMainAddress(value);
  };

  const handleBillingAdditionalAddressChange = (value: string) => {
    setBillingAdditionalAddress(value);
  };

  const handleBillingPostalCodeChange = (value: string) => {
    setBillingPostalCode(value);
  };

  const handleBillingCityChange = (value: string) => {
    setBillingCity(value);
  };

  const handleDirectorFirstNameChange = (value: string) => {
    setDirectorFirstName(value);
  };

  const handleDirectorLastNameChange = (value: string) => {
    setDirectorLastName(value);
  };

  const handleDirectorPlaceOfBirthChange = (value: string) => {
    setDirectorPlaceOfBirth(value);
  };

  const handleContactLastNameChange = (value: string) => {
    setContactLastName(value);
  };

  const handleContactFirstNameChange = (value: string) => {
    setContactFirstName(value);
  };

  const handleContactMailAddressChange = (value: string) => {
    setContactMailAddress(value);
  };

  const handleContactPhoneNumberChange = (value: string) => {
    setContactPhoneNumber(value);
  };

  const [isBackButtonHidden, setIsBackButtonHidden] = useState<boolean>(true);
  const [isNextButtonHidden, setIsNextButtonHidden] = useState<boolean>(false);
  const [isErrorHidden, setIsErrorHidden] = useState<boolean>(true);

  const handleClickBackButton = () => {
    switch (activeComponent) {
      case 2: {
        clickFormButton(1);
        setIsBackButtonHidden(true);
        break;
      }
      case 3: {
        clickFormButton(2);
        break;
      }
      case 4: {
        clickFormButton(3);
        break;
      }
      default:
        return;
    }
  };

  const handleClickNextButton = () => {
    switch (activeComponent) {
      case 1: {
        if (
          companyName === undefined ||
          legalStatus === undefined ||
          vatNumber === undefined ||
          isEmpty(companyName) ||
          isEmpty(legalStatus) ||
          isEmpty(vatNumber)
        ) {
          setIsErrorHidden(false);
        } else {
          clickFormButton(2);
        }
        break;
      }
      case 2: {
        if (
          deliveryMainAddress === undefined ||
          deliveryPostalCode === undefined ||
          deliveryCity === undefined ||
          deliveryCountry === undefined ||
          billingMainAddress === undefined ||
          billingPostalCode === undefined ||
          billingCity === undefined ||
          billingCountry === undefined ||
          isEmpty(deliveryMainAddress) ||
          isEmpty(deliveryPostalCode) ||
          isEmpty(deliveryCity) ||
          isEmpty(deliveryCountry) ||
          isEmpty(billingMainAddress) ||
          isEmpty(billingPostalCode) ||
          isEmpty(billingCity) ||
          isEmpty(billingCountry)
        ) {
          setIsErrorHidden(false);
        } else {
          clickFormButton(3);
        }

        break;
      }
      case 3: {
        if (
          directorFirstName === undefined ||
          directorLastName === undefined ||
          directorDateOfBirth === undefined ||
          directorPlaceOfBirth === undefined ||
          directorCountryOfBirth === undefined ||
          isEmpty(directorFirstName) ||
          isEmpty(directorLastName) ||
          isEmpty(directorPlaceOfBirth) ||
          isEmpty(directorCountryOfBirth)
        ) {
          setIsErrorHidden(false);
        } else {
          clickFormButton(4);
          setIsNextButtonHidden(true);
        }

        break;
      }
      default:
        return;
    }
  };

  function isEmpty(string: string) {
    return string.trim() === '';
  }

  function clickFormButton(activeComponent: number) {
    setActiveComponent(activeComponent);
    setIsErrorHidden(true);
    setIsBackButtonHidden(false);
    setIsNextButtonHidden(false);
  }

  const handleClickSaveButton = () => {
    if (
      contactMailAddress === undefined ||
      contactPhoneNumber === undefined ||
      isEmpty(contactMailAddress) ||
      isEmpty(contactPhoneNumber)
    ) {
      setIsErrorHidden(false);
    } else {
      setIsErrorHidden(true);
      execCreateCompany();
      window.location.reload();
    }
  };

  async function execCreateCompany(): Promise<any> {
    const result = await createNewCompany();

    return result;
  }

  const createNewCompany = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API_URL}/api/v1/spw/newCompany`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            userId: user.id,
            name: companyName,
            legalStatus: legalStatus,
            vatOrRegistrationNumber: vatNumber,
            mainActivitySector: 1,
            deliveryMainAddress: deliveryMainAddress,
            deliveryAdditionalAddress: deliveryAdditionalAddress,
            deliveryPostalCode: deliveryPostalCode,
            deliveryCity: deliveryCity,
            deliveryCountry: deliveryCountry,
            billingMainAddress: billingMainAddress,
            billingAdditionalAddress: billingAdditionalAddress,
            billingPostalCode: billingPostalCode,
            billingCity: billingCity,
            billingCountry: billingCountry,
            directorLastName: directorLastName,
            directorFirstName: directorFirstName,
            directorDateOfBirth: directorDateOfBirth,
            directorPlaceOfBirth: directorPlaceOfBirth,
            directorCountryOfBirth: directorCountryOfBirth,
            contactLastName: contactLastName,
            contactFirstName: contactFirstName,
            contactMailAddress: contactMailAddress,
            contactPhoneNumber: contactPhoneNumber,
          }),
        }
      );

      const result = await response.json();

      return result.data;
    } catch (error) {
      console.log('Erreur', error);
    }
  };

  const legalStatusTooltipRef = useRef(null);
  const vatNumberTooltipRef = useRef(null);

  const [, setSelectedOptionDeliveryCountry] = useState<string>('');

  const handleSelectChangeDeliveryCountry = (value: string) => {
    const newSelectedOptionDeliveryCountry = value;

    setSelectedOptionDeliveryCountry(newSelectedOptionDeliveryCountry);
    setDeliveryCountry(value);
  };

  const [selectedOptionBillingCountry, setSelectedOptionBillingCountry] =
    useState<string>('');

  const handleSelectChangeBillingCountry = (value: string) => {
    setSelectedOptionBillingCountry(value);
    setBillingCountry(value);
  };

  useEffect(() => {
    if (legalStatusTooltipRef.current) {
      tippy(legalStatusTooltipRef.current, {
        content: t('companyPageStrings.legalStatusTooltip'),
        placement: 'right',
        arrow: true,
      });
    }
    if (vatNumberTooltipRef.current) {
      tippy(vatNumberTooltipRef.current, {
        content: t('companyPageStrings.vatNumberTooltip'),
        placement: 'right',
        arrow: true,
      });
    }
  });

  const [birthDate, setBirthDate] = useState<string | null>();

  const [selectedOptionCountry, setSelectedOptionCountry] = useState<
    string | null
  >(null);
  const [countries, setCountries] = useState<any[]>([]);

  const handleSelectChangeCountry = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const newSelectedOptionCountry = event.target.value;

    setSelectedOptionCountry(newSelectedOptionCountry);
    setDirectorCountryOfBirth(newSelectedOptionCountry);
  };

  const handleStartDateChange = (date: any) => {
    setBirthDate(date);
    setDirectorDateOfBirth(date);
  };

  useEffect(() => {
    if (currentLanguage === 'fr') {
      setCountries(
        countriesFR.sort((a: any, b: any) => {
          return a.name.localeCompare(b.name);
        })
      );
    } else {
      setCountries(
        countriesEN.sort((a: any, b: any) => {
          return a.name.localeCompare(b.name);
        })
      );
    }
  }, []);

  return (
    <div className='companyPopupContainer'>
      {activeComponent === 1 && (
        <>
          <NavbarDropdownLanguage createCompany={true} />
          <h4>{t('companyPageStrings.globalDetailsTitle')}</h4>
          <p className='companySubtitle'>
            {t('companyPageStrings.globalDetailsContent')}
          </p>
          <div className='companyPopupContainer'>
            <div className='companyPopupFieldToUpdate'>
              <p>
                <b>{t('companyPageStrings.companyNameFieldPlaceHolder')}</b>
              </p>
              <TextInput
                classname='companyEditText'
                initialValue={companyName}
                onSave={handleCompanyNameChange}
              />
            </div>
            <div className='companyPopupFieldToUpdate'>
              <p>
                <b>{t('companyPageStrings.legalStatusFieldPlaceHolder')}</b>
              </p>
              <div className='companyEditAndTooltipContainer'>
                <TextInput
                  classname='companyEditText'
                  initialValue={legalStatus}
                  onSave={handleLegalStatusChange}
                />
                <img
                  src={tooltip}
                  alt='tooltipButton'
                  className='companyTooltip'
                  ref={legalStatusTooltipRef}
                />
              </div>
            </div>
            <div className='companyPopupFieldToUpdate'>
              <p>
                <b>{t('companyPageStrings.vatNumberFieldPlaceHolder')}</b>
              </p>
              <div className='companyEditAndTooltipContainer'>
                <TextInput
                  classname='companyEditText'
                  initialValue={vatNumber}
                  onSave={handleVatNumberChange}
                />
                <img
                  src={tooltip}
                  alt='tooltipButton'
                  className='companyTooltip'
                  ref={vatNumberTooltipRef}
                />
              </div>
            </div>
          </div>
        </>
      )}
      {activeComponent === 2 && (
        <>
          <h4>{t('companyPageStrings.addresses')}</h4>
          <div className='companyPopupContainer'>
            <AddressBlock
              divClassName='updateAddresses'
              title={t('companyPageStrings.deliveryTitle')}
              initialMainAddress={deliveryMainAddress}
              initialAdditionalAddress={deliveryAdditionalAddress}
              initialPostalCode={deliveryPostalCode}
              initialCity={deliveryCity}
              initialCountry={deliveryCountry}
              onSaveMainAddress={handleDeliveryMainAddressChange}
              onSaveAdditionalAddress={handleDeliveryAdditionalAddressChange}
              onSavePostalCode={handleDeliveryPostalCodeChange}
              onSaveCity={handleDeliveryCityChange}
              onSaveCountry={handleSelectChangeDeliveryCountry}
            />
            <AddressBlock
              divClassName='updateAddresses'
              title={t('companyPageStrings.billingTitle')}
              initialMainAddress={billingMainAddress}
              initialAdditionalAddress={billingAdditionalAddress}
              initialPostalCode={billingPostalCode}
              initialCity={billingCity}
              initialCountry={selectedOptionBillingCountry}
              onSaveMainAddress={handleBillingMainAddressChange}
              onSaveAdditionalAddress={handleBillingAdditionalAddressChange}
              onSavePostalCode={handleBillingPostalCodeChange}
              onSaveCity={handleBillingCityChange}
              onSaveCountry={handleSelectChangeBillingCountry}
            />
          </div>
        </>
      )}
      {activeComponent === 3 && (
        <>
          <h4>{t('companyPageStrings.directorDetails')}</h4>
          <div className='companyPopupContainer'>
            <div className='inlineBlocks'>
              <div className='companyPopupFieldToUpdate'>
                <p>
                  <b>
                    {t('companyPageStrings.directorLastNameFieldPlaceholder')}
                  </b>
                </p>
                <TextInput
                  classname='companyEditTextFieldHalfSize'
                  initialValue={directorLastName}
                  onSave={handleDirectorLastNameChange}
                />
              </div>
              <div className='companyPopupFieldToUpdate'>
                <p>
                  <b>
                    {t('companyPageStrings.directorFirstNameFieldPlaceholder')}
                  </b>
                </p>
                <div className='companyEditAndTooltipContainer'>
                  <TextInput
                    classname='companyEditTextFieldHalfSize'
                    initialValue={directorFirstName}
                    onSave={handleDirectorFirstNameChange}
                  />
                </div>
              </div>
            </div>

            <div className='inlineBlocks'>
              <div className='companyPopupFieldToUpdate'>
                <p>
                  <b>
                    {t(
                      'companyPageStrings.directorDateOfBirthFieldPlaceholder'
                    )}
                  </b>
                </p>
                <div className='companyEditAndTooltipContainer'>
                  <DatePicker
                    className='companyEditTextFieldHalfSizeDate'
                    format='dd/MM/yyyy'
                    onChange={handleStartDateChange}
                    locale={currentLanguage.toUpperCase().split('-')[0]}
                    value={birthDate || ''}
                  />
                </div>
              </div>
              <div className='companyPopupFieldToUpdate'>
                <p>
                  <b>
                    {t(
                      'companyPageStrings.directorPlaceOfBirthFieldPlaceholder'
                    )}
                  </b>
                </p>
                <div className='companyEditAndTooltipContainer'>
                  <TextInput
                    classname='companyEditTextFieldHalfSize'
                    initialValue={directorPlaceOfBirth}
                    onSave={handleDirectorPlaceOfBirthChange}
                  />
                </div>
              </div>
            </div>
            <div className='companyPopupFieldToUpdate'>
              <p>
                <b>
                  {t(
                    'companyPageStrings.directorCountryOfBirthFieldPlaceholder'
                  )}
                </b>
              </p>
              <CountrySelector
                divClassName='companyEditAndTooltipContainer'
                selectorClassName='companyEditText'
                selectorValue={selectedOptionCountry}
                selectorOnChange={handleSelectChangeCountry}
                countriesList={countries}
              />
            </div>
          </div>
        </>
      )}
      {activeComponent === 4 && (
        <>
          <h4>{t('companyPageStrings.contactDetails')}</h4>
          <div className='companyPopupContainer'>
            <div className='inlineBlocks'>
              <div className='companyPopupFieldToUpdate'>
                <p>
                  <b>
                    {t('companyPageStrings.contactLastNameFieldPlaceHolder')}
                  </b>
                </p>
                <TextInput
                  classname='companyEditTextFieldHalfSize'
                  initialValue={contactLastName}
                  onSave={handleContactLastNameChange}
                />
              </div>
              <div className='companyPopupFieldToUpdate'>
                <p>
                  <b>
                    {t('companyPageStrings.contactFirstNameFieldPlaceHolder')}
                  </b>
                </p>
                <TextInput
                  classname='companyEditTextFieldHalfSize'
                  initialValue={contactFirstName}
                  onSave={handleContactFirstNameChange}
                />
              </div>
            </div>
            <div className='inlineBlocks'>
              <div className='companyPopupFieldToUpdate'>
                <p>
                  <b>
                    {t('companyPageStrings.contactMailAddressFieldPlaceHolder')}
                  </b>
                </p>
                <TextInput
                  classname='companyEditTextFieldHalfSize'
                  initialValue={contactMailAddress}
                  onSave={handleContactMailAddressChange}
                />
              </div>
              <div className='companyPopupFieldToUpdate'>
                <p>
                  <b>
                    {t('companyPageStrings.contactPhoneNumberFieldPlaceHolder')}
                  </b>
                </p>
                <TextInput
                  classname='companyEditTextFieldHalfSize'
                  initialValue={contactPhoneNumber}
                  onSave={handleContactPhoneNumberChange}
                />
              </div>
            </div>
          </div>
        </>
      )}
      <div className='companyButtonsContainer'>
        <div className='companyBackButtonContainer'>
          {isBackButtonHidden === true ? (
            <></>
          ) : (
            <button
              onClick={handleClickBackButton}
              className='companyBackButton'
            >
              {t('globalStrings.back')}
            </button>
          )}
        </div>
        {isErrorHidden ? (
          <></>
        ) : (
          <div className='companyError'>
            <p>{t('orderPageStrings.error')}</p>
          </div>
        )}
        <div className='companyNextButtonContainer'>
          {isNextButtonHidden === true ? (
            <button className='saveButton' onClick={handleClickSaveButton}>
              <p>{t('popupStrings.saveText')}</p>
              <img
                src={saveLogo}
                alt={t('popupStrings.saveLogo')}
                className='saveLogo'
              />
            </button>
          ) : (
            <button
              onClick={handleClickNextButton}
              className='companyNextButton'
            >
              {t('globalStrings.next')}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: selectors.auth.userSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(CreateCompany);
