import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

import './excelViewer.css';

interface ExcelFileViewerProps extends WithTranslation {
  data: string[][];
  onFileUpload: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

class ExcelFileViewer extends Component<ExcelFileViewerProps> {
  render() {
    /**
     * Extract the `t` function from `useTranslation` hook.
     */
    const { t } = this.props;

    return (
      <div>
        <label htmlFor='fileInput' className='excelViewerLabel'>
          {this.props.data === undefined
            ? t('excelViewerStrings.chooseAFileToUpload')
            : t('excelViewerStrings.changeTheFileToBeUploaded')}
        </label>
        <input
          type='file'
          id='fileInput'
          accept='.xlsx, .xls, .csv, .ods'
          onChange={this.props.onFileUpload}
          className='excelViewerInput'
        />
        {this.props.data !== undefined && (
          <div className='excelViewerContainer'>
            <table className='excelViewerTable'>
              <thead>
                <tr>
                  {this.props.data[0].map((header, index) => (
                    <th key={index} className='excelViewerTh'>
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {this.props.data.slice(1).map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {row.map((cell, cellIndex) => (
                      <td key={cellIndex} className='excelViewerTd'>
                        {cell}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    );
  }
}

export default withTranslation()(ExcelFileViewer);
