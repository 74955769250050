/**
 * Converts a date string into a formatted date string in the format DD/MM/YYYY.
 *
 * @param dataString A string representing a date, which can be in various formats
 * recognized by the Date constructor (e.g., ISO 8601 format).
 *
 * @returns A string representing the date formatted as DD/MM/YYYY, where DD is the
 * day, MM is the month, and YYYY is the year.
 * The day and month are padded with leading zeros if they are single digits.
 */
export function formattedDate(dataString: string): string {
  let dateObject = new Date(dataString);

  let day = dateObject.getDate();
  let month = dateObject.getMonth() + 1;
  let year = dateObject.getFullYear();
  let formattedDate = `${day.toString().padStart(2, '0')}/${month
    .toString()
    .padStart(2, '0')}/${year}`;

  return formattedDate;
}
