import React, { useCallback, useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { compose } from "redux";

import { Main } from "components/Layouts";
import { actions, selectors } from "store";
import { Button, Checkbox, Container, Dimmer, Divider, Icon, Loader, Popup, Table } from "semantic-ui-react";
import { Details, Informations } from "components/DeclarationDetails";
import ButtonValidationModal from "components/ButtonValidationModal";
import LinkedButton from "components/core/LinkedButton/LinkedButton";

const Index = ({ dispatch, match, declaration, history, error, loading }) => {

  const declarationId = match.params.declarationId
  const companyName = match.params.companyName

  const [info, setInfo] = useState(null);
  const [note, setNote] = useState(false);
  const [calculation, setCalculation]: any = useState(null);

  useEffect(() => {
    if(declarationId && companyName){
      dispatch(
        actions.fiscalDeclaration.getDeclaration({
          params: {
            companyName: companyName,
            declarationId: declarationId
          },
        })
      );
      return () => {
        dispatch(actions.fiscalDeclaration.reset(['declaration', 'error']));
      };
    }
    
  }, [dispatch, declarationId, companyName]);

  useEffect(() => {
    if(declaration){
      setCalculation(declaration)
      setInfo({
        companyName: declaration.companyName,
        activity: declaration.activity,
        tier: declaration.tier,
        country: declaration.declaration_country,
        workshop: declaration.workshop,
        created_at: declaration.created_at,
        emitter: declaration.emitter,
        state: declaration.state,
      });
    }
  }, [declaration]);


  const validate = useCallback(() => {
    declaration && dispatch(
      actions.fiscalDeclaration.validate({
        params: {
          companyName: declaration.companyName,
          declarationId: declaration.declarationId,
        },
        data: {
          note,
          s3_key: `${declaration.companyName}/processed/${declaration.companyName}_${declaration.workshop}_${declaration.declarationId}.json`
        },
      })
    );
    history.push('/fiscal/vat/declaration');

  }, [dispatch, declaration, note, history]);

  const remove = () => {
    dispatch(
      actions.fiscalDeclaration.remove({
        params: {
          companyName: match.params.companyName,
          declarationId: match.params.declarationId,
          limit: 15,
          page: sessionStorage.getItem('declaVatPage') ? 
            parseInt(sessionStorage.getItem('declaVatPage')) : 
            1, 
        },
      })
    );
    history.push('/fiscal/vat/declaration');
  };

  return (
    <Main>
      <Divider hidden />
      {(
        (
          loading || 
          calculation === null ||
          info === null
        ) && (
        <Dimmer active>
          <Loader content='Loading' />
        </Dimmer>
        )) || (
        <Container>
          {!error && (
            <>
            <LinkedButton 
              labelPosition='left'
              icon='angle left'
              size='medium'
              type='button'
              content='Back'
              path={`/fiscal/vat/declaration`}
            />      
            <Informations info={info} />
            <Details
              statement={calculation.statement}
              error={calculation.error}
            />
              {(calculation.state === 'Validated' ||
                calculation.state === 'Not validated') && (
                <>
                <Divider hidden />
                <Table
                  color={
                    calculation.duplicates.length === 0
                      ? 'green'
                      : 'yellow'
                  }
                >
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>
                        Duplicate invoices{' '}
                        {typeof calculation.duplicates !==
                          'string' &&
                          `(${calculation.duplicates.length})`}{' '}
                        <Popup
                          trigger={<Icon name='info circle' />}
                          content={
                            'these invoices have not been integrated into the intranet'
                          }
                        />
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {(typeof calculation.duplicates === 'string' && (
                      <Table.Row>
                        <Table.Cell>
                          {calculation.duplicates}
                        </Table.Cell>
                      </Table.Row>
                    )) ||
                      calculation.duplicates.map(
                        (duplicate, index) => (
                          <Table.Row key={index}>
                            <Table.Cell>{duplicate}</Table.Cell>
                          </Table.Row>
                        )
                      )}
                  </Table.Body>
                  </Table>
                </>
              )}
            </>
          )}
          {!error && (
          <Button.Group floated='right'>
              {calculation.state !== 'Validated' && (
                <ButtonValidationModal
                  trgrContent='Delete'
                  headerContent='Delete'
                  modalContent='Do you confirm the deletion of this calculation ?'
                  trgrColor='red'
                  trgrIcon='trash'
                  headerIcon='trash'
                  onClick={remove}
                  margin='5px'
                />
              )}
              {calculation.state === 'Finished' && (
                <ButtonValidationModal
                  trgrContent='Validate'
                  headerContent='Validate'
                  modalContent={
                    <div>
                      <p>
                        Be careful, this action will integrate the declaration
                        into the intranet. Do you want to validate it?
                      </p>
                      <p>Do you want to integrate the notes ?</p>
                      <Checkbox
                        name='note'
                        label='Add notes'
                        checked={note}
                        onChange={() => setNote(!note)}
                      />
                    </div>
                  }
                  trgrColor='green'
                  trgrIcon='checkmark'
                  headerIcon='checkmark'
                  onClick={validate}
                  margin='5px'
                />
              )}
            </Button.Group>
          )}
          </Container>)}
      </Main>
  );
};

const mapStateToProps = (state) => {
  return {
    declaration: selectors.fiscalDeclaration.declarationSelector(state),
    loading: selectors.fiscalDeclaration.getDeclarationLoadingSelector(state),
    error: selectors.message.errorSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
