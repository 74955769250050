const paths = {
  spwHome: '/spw',
  spwPatchNotes: '/spw/patchnotes',
  spwCompanies: '/spw/companies',
  spwCompany: '/spw/company/details/:companyId',
  spwOrder: '/spw/order',
  spwEmployees: '/spw/company/employees/:companyId',
  spwInvoices: '/spw/company/invoices/:companyId',
  spwEmployeesDetails: '/spw/company/employees/:companyId/details/:employeeId',
  spwMissions: '/spw/missions/:companyId',
  spwMissionsDetails: '/spw/missions/:companyId/details/:missionId',
  spwMissionInvoices: '/spw/missions/:companyId/details/:missionId/invoices',
  spwMissionsDetailsManagement:
    '/spw/missions/:companyId/details/:missionId/management',
  spwInspectionDetails:
    '/spw/missions/:companyId/details/:missionId/inspection',
};

export default paths;
