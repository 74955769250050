/**
 * Dependencies.
 */
import React, { MouseEventHandler, Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

/**
 * Resources.
 */
import './dashboard.css';

/**
 * DashboardButtonProps interface defines the structure of props
 * expected by the DashboardButton component.
 */
interface DashboardButtonProps extends WithTranslation {
  title: string;
  logo: string;
  buttonAction: MouseEventHandler<HTMLButtonElement>;
}

/**
 * DashboardButton component displays a large orange button
 * below DashboardInformation components.
 */
class DashboardButton extends Component<DashboardButtonProps> {
  render() {
    const { title, logo, buttonAction } = this.props;

    /**
     * Extract the `t` function from `useTranslation` hook.
     */
    const { t } = this.props;

    return (
      <button className='dashboardButton' onClick={buttonAction}>
        <img src={logo} alt='logo' />
        <p>&nbsp;{t(title)}</p>
      </button>
    );
  }
}

export default withTranslation()(DashboardButton);
