/**
 * Depenencies
 */
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { withTranslation, WithTranslation } from 'react-i18next';

/**
 * Resources
 */
import '../index.css';
import Header from '../components/header/header';
import Footer from '../components/footer/footer';
import Content from '../components/content/content';
import PatchNotes from '../components/patchNotes/patchNotes';

/**
 *
 */
interface PatchNotesPageProps extends WithTranslation {}

/**
 *
 */
class PatchNotesPage extends Component<PatchNotesPageProps> {
  render() {
    /**
     * Extract the `t` function from `useTranslation` hook.
     */
    const { t } = this.props;

    return (
      <div className='pages'>
        <Helmet>
          <title>
            {t('headerStrings.moreButtonStrings.patchNotes')}
            {t('helmetString')}
          </title>
        </Helmet>
        <Header />
        <Content
          title={t('headerStrings.moreButtonStrings.patchNotes')}
          homepage={false}
          history={window.location.href.split('/').slice(0, -1).join('/')}
        />
        <PatchNotes />
        <Footer />
      </div>
    );
  }
}

export default withTranslation()(PatchNotesPage);
