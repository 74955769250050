// Dependencies
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { selectors } from 'store';

// Components
import NavbarDropdownLanguage from '../navbarDropdown/navbarDropdownLanguage';
import NavbarDropdown from '../navbarDropdown/navbarDropdown';

// Stylesheets
import './navbar.css';

/**
 *
 */
const Navbar: React.FC = ({ user }: any) => {
  // Parameter that must be used before ech translatable string to apply the good language version
  const { t } = useTranslation();

  let companyId: number;

  async function checkIfUserHasOneCompany(userId: number) {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API_URL}/api/v1/spw/userHasOneCompany`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ id: userId }),
        }
      );

      const result = await response.json();

      return result.companyId;
    } catch (error) {
      console.log('Erreur', error);
    }
  }

  const handleCompanyInformationClick = async () => {
    companyId = await checkIfUserHasOneCompany(user.id);
    window.location.href = `/spw/company/details/` + companyId;
  };

  const handleCompanyEmployeesClick = async () => {
    companyId = await checkIfUserHasOneCompany(user.id);
    window.location.href = `/spw/company/employees/` + companyId;
  };

  const handleCompanyInvoicesClick = async () => {
    companyId = await checkIfUserHasOneCompany(user.id);
    window.location.href = `/spw/company/invoices/` + companyId;
  };

  const handleClickCompaniesButton = () => {
    window.location.href = `/spw/companies`;
  };

  const handleClickPatchNotesButton = () => {
    window.location.href = `/spw/patchnotes`;
  };

  const handleBackToMyASDClick = () => {
    sessionStorage.clear();
    window.location.href = `/`;
  };

  const companyOptions = [
    {
      label: t('headerStrings.companyButtonStrings.information'),
      action: handleCompanyInformationClick,
    },
    {
      label: t('headerStrings.companyButtonStrings.employees'),
      action: handleCompanyEmployeesClick,
    },
    {
      label: t('headerStrings.companyButtonStrings.invoices'),
      action: handleCompanyInvoicesClick,
    },
  ];

  const moreOptions = [
    {
      label: t('headerStrings.moreButtonStrings.patchNotes'),
      action: handleClickPatchNotesButton,
    },
    {
      label: t('headerStrings.moreButtonStrings.backToMyASD'),
      action: handleBackToMyASDClick,
    },
  ];

  const [isAdminOrOperator, setIsAdminOrOperator] = useState<boolean>(false);

  useEffect(() => {
    setIsAdminOrOperator(user.operator || user.admin);
  }, [isAdminOrOperator]);

  return (
    <div className='navbarContainer'>
      <div className='navbarLeftContainer'>
        {isAdminOrOperator === false ? (
          <NavbarDropdown
            title={t('headerStrings.companyButtonStrings.company')}
            options={companyOptions}
            isRight={false}
          />
        ) : (
          <button className='moreButton' onClick={handleClickCompaniesButton}>
            {t('headerStrings.companyButtonStrings.companies')}
          </button>
        )}
      </div>
      <div className='navbarRightContainer'>
        <NavbarDropdownLanguage />
        {isAdminOrOperator ? (
          <NavbarDropdown
            title={t('headerStrings.moreButtonStrings.more')}
            options={moreOptions}
            isRight={true}
          />
        ) : (
          <button className='moreButton' onClick={handleBackToMyASDClick}>
            {t('headerStrings.moreButtonStrings.backToMyASD')}
          </button>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: selectors.auth.userSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Navbar);
