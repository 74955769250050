import * as declaration from "./declaration";
import * as repository from "./repository";
import * as kyanite from "./kyanite";
import * as collectionBox from "./collectionBox";
import * as vatDeclaration from "./vatDeclaration";
import * as oss from "./oss";

export const routes = [...declaration.routes, ...kyanite.routes, ...oss.routes, ...repository.routes, ...collectionBox.routes, ...vatDeclaration.routes];

export const paths: any = {
  ...declaration.paths,
  ...kyanite.paths,
  ...oss.paths,
  ...repository.paths,
  ...collectionBox.paths,
  ...vatDeclaration.paths
};
