// Dependencies
import React, { useEffect, useRef, useState } from 'react';

// Interfaces
import EmployeeProperties from '../../interfaces/employeeProperties';

// Stylesheets
import './employees.css';

/**
 * @name employee.tsx
 *
 * @path /components/employees/employee.tsx
 *
 * @description Defines cell's content for Employees component
 */
const Employee: React.FC<EmployeeProperties> = ({
  companyId,
  employeeId,
  firstName,
  lastName,
  gender,
  dateOfBirth,
  nationality,
}) => {
  // References
  const buttonRef = useRef<HTMLButtonElement>(null);
  const employeeEditPopupRef = useRef<HTMLDivElement>(null);
  const childRef = useRef<HTMLDivElement>(null);

  // Manage popup's visibility
  const [, setEmployeeEditPopupIsVisible] = useState<boolean>(false);

  // Define the action that must be performed when the user clicks outside of the component
  const handleOutsideClick = (event: MouseEvent) => {
    if (
      buttonRef.current &&
      employeeEditPopupRef.current &&
      childRef.current &&
      !buttonRef.current.contains(event.target as Node) &&
      !employeeEditPopupRef.current.contains(event.target as Node)
    ) {
      setEmployeeEditPopupIsVisible(false);
    }
  };

  // Configuration of page's current state when handleOutsideClick is performed
  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  return (
    <div className='employeeContainer'>
      {/* First name */}
      <div className='employeeFirstName'>
        <p>{firstName}</p>
      </div>

      {/* Last name */}
      <div className='employeeLastName'>
        <p>{lastName}</p>
      </div>

      {/* Civility */}
      <div className='employeeCivility'>
        <p>{gender}</p>
      </div>

      {/* Gender */}
      <div className='employeeDateOfBirth'>
        <p>{dateOfBirth}</p>
      </div>

      {/* Nationality */}
      <div className='employeeNationality'>
        <p>{nationality}</p>
      </div>

      {/* Details */}
      <div className='employeeDetailsButton'>
        <button
          className='openEmployeeDetails'
          onClick={() =>
            (window.location.href = `/spw/company/employees/${companyId}/details/${employeeId}`)
          }
        >
          <p>▶</p>
        </button>
      </div>
    </div>
  );
};

export default Employee;
