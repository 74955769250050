import React from 'react';
import { ImageLoginStyled } from './ImageLoginStyles';

const ImageCustom: React.FunctionComponent<ImageCustomProps> = ({
  src,
  alt,
  margin,
  height,
  width,
  maxWidth,
  maxHeight,
  onClick,
}) => {
  return (
    <ImageLoginStyled
      alt={alt}
      margin={margin}
      height={height}
      width={width}
      maxWidth={maxWidth}
      maxHeight={maxHeight}
      onClick={onClick}
    />
  );
};

interface ImageCustomProps {
  src?: string;
  alt?: string;
  margin?: string;
  height?: string;
  width?: string;
  maxWidth?: string;
  maxHeight?: string;
  onClick?: () => void;
}

export default ImageCustom;
